@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500;600;700&family=Poppins:wght@300;400;600&display=swap");
:root {
	--ff-primary: 'Mukta', sans-serif;
	--ff-secondary: 'Poppins', sans-serif
}

:root {
	--body-bg: #ffffff;
	--body-color: #555555;
	--menu-bg: #ffffff;
	--primary: #FF002E;
	--primary-gradient: linear-gradient(180deg, #FF7A00 -12.44%, #FF002E 100%);
	--primary-gradient2: linear-gradient(90deg, #FF002E 0%, #FF7A00 100%);
	--primary-light: #FFF2F5;
	--secondary: #000349;
	--secondary-alpha: 0, 3, 73;
	--secondary-gradient: linear-gradient(90deg, #000349 0%, #005B8E 100%);
	--secondary-gradient2: linear-gradient(179.09deg, #000349 -1.11%, #005B8E 99.25%);
	--success: #00B2FF;
	--success-alpha: 0, 178, 255;
	--success-gradient: linear-gradient(90deg, #00B2FF 0%, #00FFC2 100%);
	--success-gradient2: linear-gradient(to right, #00B2FF 0%, #00FFC2 80%);
	--heading: #121B25;
	--paragraph: #555555;
	--border: #dadada;
	--border-light: #bcbcbc;
	--border-light-alpha: 188, 188, 188;
	--menu-color: #0E0F1B;
	--white: #ffffff;
	--black: #000000;
	--black2: #121B25;
	--black-gradient: linear-gradient(#000000, #000000);
	--black-alpha: 0, 0, 0;
	--white-permanent: #ffffff;
	--white-alpha: 255, 255, 255;
	--gray: #c4c4c4;
	--gray-alpha: 196, 196, 196;
	--light-gray: #F9F9F9;
	--light-gray2: #FaFaFa;
	--light-gray-alpha: 249, 249, 249;
	--footer-bg: #ffffff;
	--copyright-color: #666666;
	--copyright-bg: #F9F9F9;
	--title-data-watermark: rgba(85, 85, 85, 0.15);
	--card-bg: #ffffff;
	--title-border: #eee;
	--theme-blue: #0E0061;
	--theme-blue-light: #5D44BE;
	--theme-sky: #00FFC2;
	--theme-blue-dark: #000349;
	--theme-blue2: #002196;
	--theme-blue3: #001C7E;
	--cripto-border: rgba(223, 255, 255, 0.7);
	--blur-white: rgba(255, 255, 255, 0.1);
	--white-water-color: rgba(255, 255, 255, 0.1);
	--bit-gradient: linear-gradient(0deg, rgba(0, 19, 129, 0.95) 0%, rgba(0, 81, 172, 0.95) 100%);
	--bit-gradient-rgba: linear-gradient(0deg, rgba(0, 19, 129, 0.7) 0%, rgba(0, 81, 172, 0.7) 100%);
	--bit-gradient2: linear-gradient(-90deg, rgba(0, 19, 129, 0.95) 0%, rgba(0, 81, 172, 0.95) 100%)
}

:root {
	--theme-color: #000349;
	--font-color: #333333;
	--w-light: #EEEEEE;
	--option-color: #777777;
	--disable-color: #666666;
	--disable-color-2: #BBBBBB;
	--fafafa: #FAFAFA;
	--star-color: #FBB500;
	--denger-2: #FF2D5F;
	--table-change-span-color: #12F6AB;
	--subscribe-button-font-color: #002196;
	--option-border: #CEE5EF;
	--disable-border: #C2C2C2;
	--split-icon-bg: rgba(255, 255, 255, 0.2);
	--split-icon-bg-2: #0A6EE3;
	--accordion-title-bg: #F9F9F9;
	--shadow-color: rgba(14, 95, 189, 0.07);
	--option-shadow: rgba(0, 0, 0, 0.05);
	--video-overlay: rgba(8, 71, 94, 0.5);
	--gradient: linear-gradient(90deg, #00B2FF 0%, #00FFC2 100%);
	--alert-box-3-overlay: linear-gradient(90deg, rgba(8, 71, 94, 0.63) 0%, rgba(8, 71, 94, 0) 100%);
	--alert-box-5-bg: linear-gradient(88.1deg, #9E00FF 0%, #00D092 100%);
	--subscribe-bg: #002196;
	--subscribe-from-bg: rgba(255, 255, 255, 0.1);
	--footer-overlay: linear-gradient(180deg, rgba(0, 19, 129, 0.95) 0%, rgba(0, 81, 172, 0.95) 100%);
	--btn-primary: #127BF6;
	--btn-secondary: #08475E;
	--success: #12F6AB;
	--warning: #FFB628;
	--danger: #FF2D5F;
	--disable: #EEEEEE;
	--alert-1-bg: #F1FFFA;
	--alert-2-bg: #FFFAFB;
	--alert-4-bg: #FDFFF2
}

body {
	font-family: var(--ff-primary);
	font-weight: 400;
	background: var(--body-bg);
	color: var(--body-color);
	font-size: 17px
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 15px;
	color: var(--heading);
	font-weight: 700;
	line-height: 1.3
}

p {
	margin-bottom: 20px;
	color: var(--paragraph);
	font-weight: 400;
	line-height: 1.9
}

a:active,
a:focus,
input,
select,
textarea {
	outline: none !important
}

input:not([type="submit"]),
textarea {
	background: var(--light-gray);
	border: 1px solid var(--border);
	height: 50px;
	width: 100%;
	padding: 10px 15px;
	transition: .4s
}

input:not([type="submit"]):focus,
textarea:focus {
	border-color: var(--primary)
}

textarea {
	height: 145px
}

img,
svg {
	max-width: 100%
}

a,
.container {
	text-decoration: none !important;
	transition: all .5s
}

.cbackground {
    background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
    background-image: url(../images/cripto/bit-coin/banner-bg.jpg);
}

a,
a:hover {
	color: var(--primary)
}

a:hover,
.btn,
button,
.btn:hover {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	transition: all 0.5s ease
}

ul {
	margin: 0;
	padding: 0;
	list-style: none
}

select {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none
}

section {
	overflow: hidden;
	position: relative
}

/* Custom Changes */
button.disconnect {
	border-radius: 50px;
}

footer.backgroundimgfooter {
	background: url('../images/footer-curve-bg-primary.png') no-repeat fixed center/cover;
}

.token-info-sec {
	background-color: #ffffff;
	/* border-radius: 50px; */
	/* width: 100%; */
}

div.walletaddress {
	text-align: center;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	padding: 5px;
	color: rgb(255,0,0);
	width: 80%;
	margin-left: 10%;
	background-color:#FFFFFF;
	overflow: hidden;
	/* background: rgb(255,0,0);
	background: linear-gradient(135deg, rgba(255,0,0,1) 0%, rgba(255,145,0,1) 100%); */
	border-radius: 50px;
}

div.walletaddress span {
	font-size: 1.2rem;
	font-weight: bold;
	padding: 5px;
	color:#003bb9;
}

div.progressbar {
	text-align: center;
	font-weight: bold;
	padding: 20px;
	margin-left: 5%;
	width: 90%;
	background: rgb(184,184,184);
	background: linear-gradient(90deg, rgba(184,184,184,0.2) 0%, rgba(184,184,184,0.2) 100%);
	border-radius: 50px;
}
center.statusupdate {
	text-align: center;
	font-weight: bold;
	color: #FFFFFF;
	margin-top: -10px;
}
div.homepageltable {
	display: table;
	justify-content: center;
	align-items: center;
	/* text-align: center; */
	width: 80%;
	margin: 7%;
	color: #0E0061;
	padding: 20px;
	/* margin-bottom: 10px; */
	border-radius: 20px;
	/* background: rgb(171,0,255); */
	/* background: linear-gradient(0deg, rgba(171,0,255,0.08) 0%, rgba(171,0,255,0.08) 100%); */
	-webkit-box-shadow: -1px 4px 12px 2px rgba(202,0,255,0.33); 
	box-shadow: -1px 4px 12px 2px rgba(202,0,255,0.33);
}
div.homepageltable tr {
	display: table-row;
	justify-content: center;
	align-items: center;
	/* text-align: center; */
}
div.homepageltable tr td {
	color: #0E0061;
	font-size: 1.2rem;
	padding: 5px;
}
td img.tokenicons {
	/* vertical-align: middle;
	justify-content: center;
	text-align: center;
	align-items: center; */
	width: 50px;
	/* border: 5px solid rgb(255, 107, 62); */
	border-radius: 50%;
	/* -webkit-box-shadow: -1px 4px 12px 2px rgba(202,0,255,0.33); 
	box-shadow: -1px 4px 12px 2px rgba(202,0,255,0.33); */
	-webkit-box-shadow: -1px 4px 12px 2px rgba(99,0,150,0.94); 
	box-shadow: -1px 4px 12px 2px rgba(99,0,150,0.94);
}
div.newsletter-widget div button {
	color: #ffffff;
	font-weight: bold;
	font-size: 1.2rem;
	min-width: 120px;
	margin: 10px 0 0 0;
	padding:10px;
	border-radius: 25px;
	background: rgb(255,64,0);
	background: linear-gradient(90deg, rgba(255,64,0,1) 0%, rgba(255,192,0,1) 100%);
}
div.newsletter-widget div div {
	color: #ffffff;
}
div.newsletter-widget div button:hover {
	transition: all 0.5s ease;
	background: rgb(0, 102, 255);
	background: linear-gradient(90deg, rgb(0, 91, 177) 0%, rgb(0, 91, 177) 100%);
}
div.accountinfobg {
	padding: 5%;
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: #ffffff;
}
table.cryptbalance {
	/* padding: 10px; */
	margin: 5%;
	width: 90%;
	border-radius: 15px;
	-webkit-box-shadow: -1px 4px 12px 2px rgba(10,110,227,0.2); 
	box-shadow: -1px 4px 12px 2px rgba(10,110,227,0.2);
}
table.cryptbalance tbody tr {
	padding: 30px;
}
table.cryptbalance tbody tr td.leftone {
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: bold;
	width: 30%;
}
table.cryptbalance tbody tr td.rightone {
	justify-content: left;
	align-items: left;
	text-align: left;
	width: 70%;
}
/* table.cryptbalance tbody tr {
	justify-content: left;
	align-items: left;
	text-align: left;
} */
div.find-item-box {
	margin: 5px;
	-webkit-box-shadow: -1px 4px 12px 2px rgba(202,0,255,0.33); 
	box-shadow: -1px 4px 12px 2px rgba(202,0,255,0.33);
}
tbody.spacebox {
	margin: 20px;
	border-radius: 15px;
}
div.homepageltable tr.trhomepage td{
	/* align-items: center; */
	/* justify-content: center; */
	text-align: center;
	margin-top: -25px;
	position: relative;
}
td.centeralign {
	text-align: center;
}
/* Custom Changes end */
#top {
	background: transparent;
	color: rgba(var(--black-alpha), 0.2);
	height: 40px;
	width: 40px;
	cursor: pointer;
	text-align: center;
	line-height: 1.7;
	border: 2px solid rgba(var(--black-alpha), 0.2);
	border-radius: 50%;
	font-size: 20px;
	font-weight: 700;
	transition: .3s;
	position: fixed;
	right: 20px;
	bottom: 30px;
	display: none;
	z-index: 99
}

#top:hover {
	color: var(--primary);
	border-color: var(--primary)
}

.gradient #top {
	background: var(--bit-gradient);
	color: var(--white);
	border: none;
	text-align: center;
	line-height: 40px;
	animation: moveY 1s infinite alternate
}

.gradient #top i {
	background: var(--success-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.blue-top #top {
	background: var(--theme-blue-light);
	color: var(--white);
	border: none;
	text-align: center;
	line-height: 40px;
	animation: moveY 1s infinite alternate
}

@keyframes moveY {
	0% {
		transform: translateY(-5px)
	}
	100% {
		transform: translateY(5px)
	}
}

.border-top,
.border-right,
.border-bottom,
.border-left {
	border-color: var(--border) !important
}

.border-top.border-white,
.border-right.border-white,
.border-bottom.border-white,
.border-left.border-white {
	border-color: var(--white) !important
}

iframe {
	width: 100%
}

.fw-light {
	font-weight: 300 !important
}

.fw-light * {
	font-weight: 300 !important
}

.fw-regular {
	font-weight: normal !important
}

.fw-regular * {
	font-weight: normal !important
}

.fw-medium {
	font-weight: 500 !important
}

.fw-medium * {
	font-weight: 500 !important
}

.fw-semibold {
	font-weight: 600 !important
}

.fw-semibold * {
	font-weight: 600 !important
}

.fw-bold {
	font-weight: 700 !important
}

.fw-bold * {
	font-weight: 700 !important
}

.fs-14 {
	font-size: 14px !important
}

.fs-15 {
	font-size: 15px !important
}

.fs-16 {
	font-size: 16px !important
}

.fs-18 {
	font-size: 18px !important
}

.fs-20 {
	font-size: 20px !important
}

.fs-22 {
	font-size: 22px !important
}

.fs-24 {
	font-size: 24px !important
}

.w-33 {
	width: 33.333%
}

.w-66 {
	width: 66.6666%
}

.w-20 {
	width: 20%
}

.flex-1 {
	flex: 1
}

.height-100vh {
	min-height: 100vh
}

.w100 {
	max-width: 100px;
	width: 100px
}

.w50 {
	width: 50px;
	max-width: 50px
}

.w200 {
	max-width: 200px;
	width: 100%
}

.w300 {
	max-width: 300px;
	width: 100%
}

.w400 {
	max-width: 400px;
	width: 100%
}

.w500 {
	max-width: 500px;
	width: 100%
}

.w600 {
	max-width: 600px;
	width: 100%
}

.w700 {
	max-width: 700px;
	width: 100%
}

.w800 {
	max-width: 800px;
	width: 100%
}

.w900 {
	max-width: 900px;
	width: 100%
}

.w1000 {
	max-width: 1000px;
	width: 100%
}

.w50 {
	max-width: 50px;
	width: 100%
}

.w150 {
	max-width: 150px;
	width: 100%
}

.w250 {
	max-width: 250px;
	width: 100%
}

.w350 {
	max-width: 350px;
	width: 100%
}

.w450 {
	max-width: 450px;
	width: 100%
}

.w550 {
	max-width: 550px;
	width: 100%
}

.w650 {
	max-width: 650px;
	width: 100%
}

.w750 {
	max-width: 750px;
	width: 100%
}

.w850 {
	max-width: 850px;
	width: 100%
}

.w950 {
	max-width: 950px;
	width: 100%
}

.h1000 {
	height: 1000px
}

.min-h1000 {
	min-height: 1000px
}

.h500 {
	height: 500px
}

.min-h500 {
	min-height: 500px
}

.h600 {
	height: 600px
}

.min-h600 {
	min-height: 600px
}

.h700 {
	height: 700px
}

.min-h700 {
	min-height: 700px
}

.h800 {
	height: 800px
}

.min-h800 {
	min-height: 800px
}

.h900 {
	height: 900px
}

.min-h900 {
	min-height: 900px
}

.h1000 {
	height: 1000px
}

.min-h1000 {
	min-height: 1000px
}

.border {
	border: 1px solid var(--border)
}

.border-top {
	border-top: 1px solid var(--border)
}

.border-right {
	border-right: 1px solid var(--border)
}

.border-bottom {
	border-bottom: 1px solid var(--border)
}

.border-left {
	border-left: 1px solid var(--border)
}

.border-none {
	border: 0 !important
}

.rounded-5 {
	border-radius: 5px !important
}

.rounded-10 {
	border-radius: 10px !important
}

.rounded-20 {
	border-radius: 20px !important
}

.rounded-30 {
	border-radius: 30px !important
}

.rounded-40 {
	border-radius: 40px !important
}

.rounded-50 {
	border-radius: 50px !important
}

.rounded-60 {
	border-radius: 60px !important
}

.rounded-70 {
	border-radius: 70px !important
}

.rounded-80 {
	border-radius: 80px !important
}

.rounded-90 {
	border-radius: 90px !important
}

.rounded-100 {
	border-radius: 100px !important
}

.text-primary {
	color: var(--primary) !important
}

.text-secondary {
	color: var(--secondary) !important
}

.text-heading {
	color: var(--heading) !important
}

.text-success-gradient,
.text-secondary-gradient,
.text-primary-gradient {
	color: transparent !important;
	background: var(--primary-gradient2);
	-moz-background-clip: text;
	-webkit-background-clip: text;
	background-clip: text;
	display: inline-block
}

.text-secondary-gradient {
	background: var(--secondary-gradient);
	-moz-background-clip: text;
	-webkit-background-clip: text;
	background-clip: text
}

.text-success-gradient {
	background: var(--success-gradient);
	-moz-background-clip: text;
	-webkit-background-clip: text;
	background-clip: text
}

.btn {
	position: relative;
	overflow: hidden;
	border-radius: 0px;
	padding: 9px 25px;
	z-index: 5;
	box-shadow: none !important
}

.btn i {
	position: relative;
	top: 3px;
	font-size: 22px
}

.btn-primary {
	background-color: transparent;
	border-color: transparent;
	color: var(--white-permanent) !important
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	background: transparent !important;
	border-color: var(--primary) !important;
	color: var(--primary) !important;
	box-shadow: none !important
}

.btn-primary::before {
	content: "";
	height: 100%;
	width: 100%;
	background: var(--primary-gradient2);
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	transition: .4s;
	transition: all 0.5s
}

.btn-primary:hover::before,
.btn-primary:focus::before {
	width: 0;
	right: -3px;
	left: auto
}

.btn-sky {
	background-color: transparent;
	border-color: transparent;
	color: var(--theme-blue2) !important
}

.btn-sky:hover,
.btn-sky:focus,
.btn-sky:active {
	background: transparent !important;
	border-color: var(--theme-sky) !important;
	color: var(--theme-sky) !important;
	box-shadow: none !important
}

.btn-sky::before {
	content: "";
	height: 100%;
	width: 100%;
	background: var(--success-gradient);
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	transition: .4s;
	transition: all 0.5s
}

.btn-sky:hover::before,
.btn-sky:focus::before {
	width: 0;
	right: -3px;
	left: auto
}

.btn-blue {
	background-color: transparent;
	border-color: transparent;
	color: var(--white-permanent) !important
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active {
	background: transparent !important;
	border-color: var(--theme-blue-light) !important;
	color: var(--white) !important;
	box-shadow: none !important
}

.btn-blue::before {
	content: "";
	height: 100%;
	width: 100%;
	background: var(--theme-blue-light);
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	transition: .4s;
	transition: all 0.5s
}

.btn-blue:hover::before,
.btn-blue:focus::before {
	width: 0;
	right: -3px;
	left: auto
}

.btn-blue.text-blue:hover,
.btn-blue.text-blue:focus,
.btn-blue.text-blue.active {
	color: var(--theme-blue-light) !important
}

.btn-dark {
	background-color: transparent;
	border-color: transparent;
	color: var(--white-permanent) !important
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
	background: transparent !important;
	border-color: var(--heading) !important;
	color: var(--heading) !important;
	box-shadow: none !important
}

.btn-dark::before {
	content: "";
	height: 100%;
	width: 100%;
	background: var(--heading);
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	transition: .4s;
	transition: all 0.5s
}

.btn-dark:hover::before,
.btn-dark:focus::before {
	width: 0;
	right: -3px;
	left: auto
}

input.btn-primary {
	background: var(--primary);
	color: var(--white-permanent) !important
}

.btn-secondary {
	background-color: transparent;
	border-color: transparent;
	color: var(--white-permanent) !important
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
	background: transparent !important;
	border-color: var(--secondary) !important;
	color: var(--secondary) !important;
	box-shadow: none !important
}

.btn-secondary::before {
	content: "";
	height: 100%;
	width: 100%;
	background: var(--secondary-gradient);
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	transition: .4s;
	transition: all 0.5s
}

.btn-secondary:hover::before,
.btn-secondary:focus::before {
	width: 0;
	right: -3px;
	left: auto
}

.btn-success {
	background: linear-gradient(to left, #00B2FF, #00FFC2, #00B2FF) no-repeat scroll left/200%;
	border: 0;
	box-shadow: none !important;
	outline: none !important;
	color: var(--heading)
}

.btn-success:hover {
	background-position: right;
	color: var(--heading)
}

.btn-outline-theme {
	border: 1px solid var(--theme-sky);
	border-radius: 4px;
	color: var(--white);
	overflow: hidden;
	position: relative;
	z-index: 1;
	transition: .4s ease-in
}

.btn-outline-theme:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: var(--success-gradient);
	z-index: -1;
	transform: translateY(-50px);
	opacity: 0;
	transition: .4s ease-in
}

.btn-outline-theme:hover {
	border-color: transparent;
	color: var(--theme-blue2)
}

.btn-outline-theme:hover:after {
	transform: translateY(0px);
	opacity: 1
}

.container-fluid {
	max-width: 1500px
}

.p-10 {
	padding: 10px
}

.py-10,
.pt-10 {
	padding-top: 10px
}

.py-10,
.pb-10 {
	padding-bottom: 10px
}

.p-20 {
	padding: 20px
}

.py-20,
.pt-20 {
	padding-top: 20px
}

.py-20,
.pb-20 {
	padding-bottom: 20px
}

.p-30 {
	padding: 30px
}

.py-30,
.pt-30 {
	padding-top: 30px
}

.py-30,
.pb-30 {
	padding-bottom: 30px
}

.p-40 {
	padding: 40px
}

.py-40,
.pt-40 {
	padding-top: 40px
}

.py-40,
.pb-40 {
	padding-bottom: 40px
}

.py-50,
.pt-50 {
	padding-top: 50px
}

.py-50,
.pb-50 {
	padding-bottom: 50px
}

.py-60,
.pt-60 {
	padding-top: 60px
}

.py-60,
.pb-60 {
	padding-bottom: 60px
}

.py-70,
.pt-70 {
	padding-top: 70px
}

.py-70,
.pb-70 {
	padding-bottom: 70px
}

.py-80,
.pt-80 {
	padding-top: 80px
}

.py-80,
.pb-80 {
	padding-bottom: 80px
}

.py-100,
.pt-100 {
	padding-top: 100px
}

.py-100,
.pb-100 {
	padding-bottom: 100px
}

.mt--10,
.my--10 {
	margin-top: -10px
}

.mb--10,
.my--10 {
	margin-bottom: -10px
}

.mt--20,
.my--20 {
	margin-top: -20px
}

.mb--20,
.my--20 {
	margin-bottom: -20px
}

.mt--30,
.my--30 {
	margin-top: -30px
}

.mb--30,
.my--30 {
	margin-bottom: -30px
}

.mt--40,
.my--40 {
	margin-top: -40px
}

.mb--40,
.my--40 {
	margin-bottom: -40px
}

.me--1 {
	margin-right: -1px
}

.ms--1 {
	margin-left: -1px
}

.sec-title {
	margin-bottom: 60px;
	overflow: hidden
}

.sec-title h1 {
	font-size: 48px
}

.sec-title h1[data-watermark] {
	padding: 30px 0
}

.sec-title h1,
.sec-title h2,
.sec-title h3,
.sec-title h4,
.sec-title h5,
.sec-title h6 {
	position: relative;
	overflow: hidden;
	font-weight: 700;
	z-index: 1;
	color: var(--secondary);
	margin-bottom: 0
}

.sec-title h1[data-watermark]::before,
.sec-title h2[data-watermark]::before,
.sec-title h3[data-watermark]::before,
.sec-title h4[data-watermark]::before,
.sec-title h5[data-watermark]::before,
.sec-title h6[data-watermark]::before {
	content: attr(data-watermark);
	position: absolute;
	left: 0;
	-webkit-text-stroke: 1px var(--title-data-watermark);
	color: transparent;
	font-size: 130px;
	line-height: .9;
	white-space: nowrap;
	top: 0;
	z-index: -1
}

.sec-title h1 span,
.sec-title h2 span,
.sec-title h3 span,
.sec-title h4 span,
.sec-title h5 span,
.sec-title h6 span {
	color: transparent;
	background: var(--primary-gradient2);
	-moz-background-clip: text;
	-webkit-background-clip: text;
	background-clip: text
}

.sec-title p {
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 0
}

.sec-title.text-white h1,
.sec-title.text-white h2,
.sec-title.text-white h3,
.sec-title.text-white h4,
.sec-title.text-white h5,
.sec-title.text-white h6,
.sec-title.text-white p {
	color: var(--white-permanent)
}

.sec-title.text-center h1::before,
.sec-title.text-center h2::before,
.sec-title.text-center h3::before,
.sec-title.text-center h4::before,
.sec-title.text-center h5::before,
.sec-title.text-center h6::before {
	left: 50%;
	transform: translate(-50%)
}

.sec-title-line h1,
.sec-title-line h2,
.sec-title-line h3,
.sec-title-line h4,
.sec-title-line-modern h1,
.sec-title-line-modern h2,
.sec-title-line-modern h3,
.sec-title-line-modern h4 {
	position: relative;
	display: inline-block;
	overflow: visible
}

.sec-title-line h1::before,
.sec-title-line h1::after,
.sec-title-line h2::before,
.sec-title-line h2::after,
.sec-title-line h3::before,
.sec-title-line h3::after,
.sec-title-line h4::before,
.sec-title-line h4::after,
.sec-title-line-modern h1::before,
.sec-title-line-modern h1::after,
.sec-title-line-modern h2::before,
.sec-title-line-modern h2::after,
.sec-title-line-modern h3::before,
.sec-title-line-modern h3::after,
.sec-title-line-modern h4::before,
.sec-title-line-modern h4::after {
	content: "";
	position: absolute;
	background: var(--primary-gradient2);
	height: 4px;
	width: 50px;
	top: calc(50% - 2px);
	right: calc(100% + 20px);
	transform: unset !important;
	left: auto !important;
	z-index: 5
}

.sec-title-line h1::after,
.sec-title-line h2::after,
.sec-title-line h3::after,
.sec-title-line h4::after,
.sec-title-line-modern h1::after,
.sec-title-line-modern h2::after,
.sec-title-line-modern h3::after,
.sec-title-line-modern h4::after {
	left: calc(100% + 20px) !important;
	right: auto
}

.sec-title-line-modern h1::before,
.sec-title-line-modern h1::after,
.sec-title-line-modern h2::before,
.sec-title-line-modern h2::after,
.sec-title-line-modern h3::before,
.sec-title-line-modern h3::after,
.sec-title-line-modern h4::before,
.sec-title-line-modern h4::after {
	width: 70px;
	background-image: url("data:image/svg+xml,%3Csvg width='70' height='4' viewBox='0 0 70 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='70' y='4' width='53.5297' height='3.99998' transform='rotate(180 70 4)' fill='url(%23paint0_linear)'%3E%3C/rect%3E%3Crect x='12.3516' y='4' width='4.11767' height='3.99998' transform='rotate(180 12.3516 4)' fill='url(%23paint1_linear)'%3E%3C/rect%3E%3Crect x='4.11719' y='4' width='4.11767' height='3.99998' transform='rotate(180 4.11719 4)' fill='url(%23paint2_linear)'%3E%3C/rect%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='70' y1='6.02816' x2='123.53' y2='6.02814' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FF002E'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%23FF7A00'%3E%3C/stop%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='12.3516' y1='6.02816' x2='16.4692' y2='6.02816' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FF002E'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%23FF7A00'%3E%3C/stop%3E%3C/linearGradient%3E%3ClinearGradient id='paint2_linear' x1='4.11719' y1='6.02816' x2='8.23485' y2='6.02816' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FF002E'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%23FF7A00'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")
}

.sec-title-line-modern h1::after,
.sec-title-line-modern h2::after,
.sec-title-line-modern h3::after,
.sec-title-line-modern h4::after {
	transform: rotate(180deg) !important
}

.sec-title-line-modern.line-success h1::before,
.sec-title-line-modern.line-success h1::after,
.sec-title-line-modern.line-success h2::before,
.sec-title-line-modern.line-success h2::after,
.sec-title-line-modern.line-success h3::before,
.sec-title-line-modern.line-success h3::after,
.sec-title-line-modern.line-success h4::before,
.sec-title-line-modern.line-success h4::after {
	background-image: url("data:image/svg+xml,%0A%3Csvg width='70' height='4' viewBox='0 0 70 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='70' y='4' width='53.5297' height='3.99998' transform='rotate(180 70 4)' fill='url(%23paint0_linear)'%3E%3C/rect%3E%3Crect x='12.3516' y='4' width='4.11767' height='3.99998' transform='rotate(180 12.3516 4)' fill='url(%23paint1_linear)'%3E%3C/rect%3E%3Crect x='4.11719' y='4' width='4.11767' height='3.99998' transform='rotate(180 4.11719 4)' fill='url(%23paint2_linear)'%3E%3C/rect%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='70' y1='6.02816' x2='123.53' y2='6.02816' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300B2FF'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%2300FFC2'%3E%3C/stop%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='12.3516' y1='6.02816' x2='16.4692' y2='6.02816' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300B2FF'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%2300FFC2'%3E%3C/stop%3E%3C/linearGradient%3E%3ClinearGradient id='paint2_linear' x1='4.11719' y1='6.02816' x2='8.23485' y2='6.02816' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300B2FF'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%2300FFC2'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")
}

.subtitle {
	display: block;
	color: var(--primary);
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 20px
}

.bg-primary {
	background: var(--primary) !important
}

.bg-secondary {
	background: var(--secondary) !important
}

.bg-success {
	background: var(--success) !important
}

.bg-gray,
.bg-light {
	background: var(--light-gray) !important
}

.bg-primary-gradient {
	background: var(--primary-gradient2) !important
}

.bg-secondary-gradient {
	background: var(--secondary-gradient) !important
}

.bg-success-gradient {
	background: var(--success-gradient) !important
}

.bg-overlay-primary-gradient,
.bg-overlay-secondary-gradient,
.bg-overlay-black-gradient,
.bg-overlay-primary,
.bg-overlay-secondary,
.bg-overlay-black {
	position: relative;
	z-index: 5
}

.bg-overlay-primary-gradient::before,
.bg-overlay-secondary-gradient::before,
.bg-overlay-black-gradient::before,
.bg-overlay-primary::before,
.bg-overlay-secondary::before,
.bg-overlay-black::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	opacity: .8
}

.bg-overlay-primary {
	background: var(--primary)
}

.bg-overlay-secondary {
	background: var(--secondary)
}

.bg-overlay-black {
	background: var(--black)
}

.bg-overlay-primary-gradient::before {
	background: var(--primary-gradient2)
}

.bg-overlay-secondary-gradient::before {
	background: var(--secondary-gradient)
}

.bg-overlay-black-gradient::before {
	background: var(--black-gradient)
}

.z-index-5 {
	z-index: 5;
	position: relative
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p {
	color: var(--white)
}

.box-shadow {
	box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05)
}

.list li {
	position: relative;
	padding-left: 20px;
	margin-bottom: 15px
}

.list li::before {
	content: "";
	position: absolute;
	height: 8px;
	width: 8px;
	border-radius: 50%;
	background: var(--primary);
	top: 7px;
	left: 0
}

.column-count-2 {
	column-count: 2
}

.column-count-3 {
	column-count: 3
}

.column-count-4 {
	column-count: 4
}

.column-gap-20 {
	column-gap: 20px
}

.column-gap-30 {
	column-gap: 30px
}

.column-gap-40 {
	column-gap: 40px
}

.column-gap-50 {
	column-gap: 50px
}

.inline-icon {
	font-weight: 500;
	font-size: 18px
}

.inline-icon i {
	color: transparent;
	background: var(--primary-gradient2);
	-moz-background-clip: text;
	-webkit-background-clip: text;
	background-clip: text;
	margin-right: 5px
}

.carousel-dots .owl-dots {
	text-align: center;
	margin-top: 10px
}

.carousel-dots .owl-dot {
	height: 10px;
	width: 10px;
	background: var(--gray) !important;
	border-radius: 50%;
	margin: 3px;
	outline: none !important
}

.carousel-dots .owl-dot.active {
	background: var(--primary) !important
}

b,
strong {
	font-weight: 700
}

button {
	background-color: transparent;
	border: none;
	border-radius: 0px
}

button:focus {
	outline: 0;
	box-shadow: none
}

.btn-xl {
	padding: 18px 35px !important
}

.btn-lg {
	padding: 12px 30px !important
}

.btn-md {
	padding: 10px 25px !important
}

.btn-sm {
	padding: 8px 20px !important
}

.btn-xs {
	padding: 5px 15px !important;
	font-size: 15px !important
}

.button-wrapper {
	max-width: 960px;
	margin: 0 auto
}

.single-button-area {
	padding-bottom: 20px
}

.single-button-area:last-child {
	padding-bottom: 0
}

.single-button-area h6 {
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
	margin-bottom: 25px
}

.regular-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap
}

.r-button {
	font-size: 16px;
	padding: 10px 25px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	line-height: 1;
	color: var(--white);
	background-color: var(--btn-primary);
	margin-right: 10px;
	transition: all .3s ease-in-out;
	border: 1px solid transparent;
	margin-bottom: 20px;
	border-radius: 0px
}

.r-promary {
	background-color: var(--btn-primary)
}

.r-promary:hover {
	border-color: var(--btn-primary);
	color: var(--btn-primary);
	background-color: transparent
}

.r-secondary {
	background-color: var(--btn-secondary)
}

.r-secondary:hover {
	border-color: var(--btn-secondary);
	color: var(--btn-secondary);
	background-color: transparent
}

.r-success {
	background-color: var(--success);
	color: var(--font-color)
}

.r-success:hover {
	border-color: var(--success);
	color: var(--success);
	background-color: transparent
}

.r-warning {
	background-color: var(--warning);
	color: var(--font-color)
}

.r-warning:hover {
	border-color: var(--warning);
	color: var(--warning);
	background-color: transparent
}

.r-danger {
	background-color: var(--danger)
}

.r-danger:hover {
	border-color: var(--danger);
	color: var(--danger);
	background-color: transparent
}

.r-disable {
	background-color: var(--disable);
	color: var(--disable-color)
}

.r-disable:hover {
	color: var(--disable-color)
}

.o-button {
	background-color: transparent
}

.o-promary {
	color: var(--btn-primary);
	border-color: var(--btn-primary)
}

.o-promary:hover {
	color: var(--white);
	background-color: var(--btn-primary)
}

.o-secondary {
	color: var(--btn-secondary);
	border-color: var(--btn-secondary)
}

.o-secondary:hover {
	color: var(--white);
	background-color: var(--btn-secondary)
}

.o-success {
	color: var(--success);
	border-color: var(--success)
}

.o-success:hover {
	color: var(--font-color);
	background-color: var(--success)
}

.o-warning {
	color: var(--warning);
	border-color: var(--warning)
}

.o-warning:hover {
	color: var(--font-color);
	background-color: var(--warning)
}

.o-danger {
	color: var(--danger);
	border-color: var(--danger)
}

.o-danger:hover {
	color: var(--white);
	background-color: var(--danger)
}

.o-disable {
	color: var(--disable-color-2);
	border-color: var(--disable-border)
}

.o-disable:hover {
	color: var(--disable-color);
	background-color: var(--disable);
	border-color: transparent
}

.split {
	width: 170px;
	position: relative;
	overflow: hidden
}

.split .cp-icon {
	position: absolute;
	justify-content: center;
	align-items: center;
	display: flex;
	display: flex;
	height: 100%;
	width: 45px
}

.split .cp-icon img,
.split .cp-icon svg {
	width: 18px;
	height: 18px
}

.split:hover {
	color: var(--white)
}

.split-1 {
	justify-content: start;
	padding-left: 15px;
	transition: all .3s ease-in-out
}

.split-1 .cp-icon {
	right: 0;
	background: var(--split-icon-bg);
	transition: all .3s ease-in-out
}

.split-1:hover {
	background: var(--split-icon-bg-2)
}

.split-1:hover .cp-icon {
	background: var(--btn-primary)
}

.split-2 {
	justify-content: flex-end;
	padding-right: 15px;
	transition: all .3s ease-in-out
}

.split-2 .cp-icon {
	left: 0;
	background: var(--split-icon-bg-2);
	border-radius: 5px;
	transition: all .3s ease-in-out
}

.split-2:hover {
	background: var(--split-icon-bg-2)
}

.split-2:hover .cp-icon {
	background: var(--btn-primary)
}

.split-3 .cp-icon {
	border-radius: 0
}

.split-4 {
	background: transparent;
	border-color: var(--btn-primary);
	color: var(--btn-primary)
}

.split-5 {
	background: transparent;
	border-color: var(--btn-primary);
	color: var(--btn-primary)
}

.split-5 .cp-icon {
	border-radius: 0
}

.semi-rounded {
	border-radius: 5px
}

.rounded {
	border-radius: 100px !important
}

@media screen and (max-width: 991px) {
	.regular-button {
		justify-content: flex-start
	}
}

@media screen and (max-width: 767px) {
	.single-button-area {
		padding-bottom: 10px
	}
	.single-button-area h6 {
		margin-bottom: 15px
	}
}

@media screen and (max-width: 575px) {
	.split {
		width: 150px
	}
}

.section-title {
	max-width: 690px;
	margin: 0 auto;
	margin-bottom: 40px
}

.section-title h3 {
	line-height: 1;
	padding-bottom: 15px;
	color: var(--theme-color);
	font-weight: 600;
	margin-bottom: 0px
}

.section-title p {
	padding-bottom: 20px;
	border-bottom: 1px solid var(--w-light);
	margin-bottom: 0px;
	line-height: 1.5
}

@media screen and (max-width: 575px) {
	.section-title h3 {
		font-size: 22px;
		padding-bottom: 10px
	}
	.section-title p {
		padding-bottom: 10px;
		font-size: 15px
	}
}

.typography-area {
	padding-top: 70px;
	padding-bottom: 50px
}

.typography-wrap {
	max-width: 1365px;
	margin-left: 150px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 0px 20px
}

.typography {
	background-color: var(--white);
	box-shadow: 0px 10px 40px var(--shadow-color);
	padding: 35px 50px;
	border-radius: 10px
}

.typo-title h1,
.typo-title h2,
.typo-title h3,
.typo-title h5,
.typo-title h6 {
	margin-bottom: 0px
}

.typo-title h1 span,
.typo-title h2 span,
.typo-title h3 span,
.typo-title h5 span,
.typo-title h6 span {
	font-weight: 600
}

.typo-title h1 {
	font-weight: 400;
	font-size: 46px;
	line-height: 72px;
	padding-bottom: 30px
}

.typo-title h2 {
	font-size: 36px;
	font-weight: 400;
	line-height: 54px;
	padding-bottom: 30px
}

.typo-title h3 {
	font-size: 30px;
	font-weight: 400;
	line-height: 45px;
	padding-bottom: 30px
}

.typo-title h5 {
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	padding-bottom: 30px
}

.typo-title h5 {
	font-size: 18px;
	font-weight: 400;
	line-height: 27px
}

.typo-descripition h6 {
	font-weight: 600;
	margin-bottom: 24px;
	line-height: 27px
}

.typo-descripition .single-descripition {
	max-width: 350px;
	padding-bottom: 25px
}

.typo-descripition .single-descripition:last-child {
	padding-bottom: 0
}

.typo-descripition .single-descripition h4 {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	padding-bottom: 10px;
	margin-bottom: 0px
}

.typo-descripition .single-descripition p {
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 0px
}

.typo-descripition .text-center {
	margin: 0 auto
}

.typo-descripition .text-end {
	margin-left: auto
}

@media screen and (max-width: 1350px) {
	.typography-wrap {
		margin-left: 70px
	}
}

@media screen and (max-width: 1199px) {
	.typography-wrap {
		margin-left: 0px
	}
	.typo-title h1 {
		font-size: 36px;
		line-height: 47px
	}
	.typo-title h2 {
		font-size: 30px;
		line-height: 40px
	}
	.typo-title h3 {
		font-size: 24px;
		line-height: 34px
	}
	.typo-title h5 {
		font-size: 16px;
		line-height: 27px
	}
	.typo-title h6 {
		font-size: 14px;
		line-height: 25px
	}
}

@media screen and (max-width: 991px) {
	.typography-wrap {
		grid-template-columns: 1fr;
		grid-gap: 20px 0px
	}
	.typography-area {
		padding-bottom: 40px
	}
}

@media screen and (max-width: 767px) {
	.typography-area {
		padding-bottom: 35px
	}
	.typo-title h1 {
		padding-bottom: 10px
	}
	.typo-title h2 {
		padding-bottom: 10px
	}
	.typo-title h3 {
		padding-bottom: 10px
	}
	.typo-title h5 {
		padding-bottom: 10px
	}
	.typo-title h6 {
		padding-bottom: 10px
	}
}

@media screen and (max-width: 575px) {
	.typography {
		padding: 20px 20px;
		border-radius: 5px
	}
	.typo-title h1 {
		font-size: 26px;
		line-height: 36px
	}
	.typo-title h2 {
		font-size: 22px;
		line-height: 32px
	}
	.typo-title h3 {
		font-size: 20px;
		line-height: 31px
	}
	.typo-descripition h6 {
		margin-bottom: 15px
	}
	.typo-descripition .single-descripition {
		padding-bottom: 15px
	}
}

.all-option-wrap {
	position: sticky;
	top: 0;
	z-index: 9999
}

.option-toogle {
	background: var(--primary-gradient2);
	width: 160px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--white);
	font-size: 18px;
	font-weight: 600;
	left: 0;
	top: 0;
	position: absolute;
	border-radius: 0px 50px 50px 0px;
	cursor: pointer;
	z-index: 99;
	transform: translateX(-165px);
	transition: all .3s ease-in-out
}

.all-option {
	padding: 40px 0px 40px 50px;
	background-color: var(--white);
	border: 1px solid var(--option-border);
	box-shadow: 0px 10px 30px var(--option-shadow);
	border-radius: 10px;
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0
}

.all-option a {
	display: block;
	padding-right: 80px;
	font-size: 18px;
	line-height: 46px;
	color: var(--option-color);
	transition: all .3s ease-in-out;
	position: relative
}

.all-option a::before {
	position: absolute;
	content: "";
	height: 5px;
	width: 0px;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 5px 0px 0px 5px;
	right: 0;
	background: var(--primary-gradient2);
	opacity: 0;
	transition: all .3s ease-in-out
}

.all-option a:hover:before {
	width: 40px;
	opacity: 1
}

.all-option a.active::before {
	width: 40px;
	opacity: 1
}

.all-option a:hover,
.all-option a.active {
	color: var(--primary)
}

@media screen and (max-width: 1550px) {
	.all-option {
		transform: translateX(0px);
		transition: all .3s ease-in-out;
		top: 100px;
		opacity: 1;
		visibility: visible;
		top: 60px !important;
		height: calc(100vh - 60px);
		overflow-y: auto
	}
	.all-option.active {
		transform: translateX(-270px);
		opacity: 0;
		visibility: hidden
	}
	.option-toogle {
		transform: translateX(0px)
	}
	.typography-wrap,
	.card-wrapper,
	.progress-bar-wrapper,
	.image-wrapper,
	.video-wrapper,
	.carousel-wrapper,
	.input-field-wrapper,
	.pegination-wrapper,
	.alert-wrapper,
	.nab-tab-wrapper,
	.short-counter-wrapper,
	.coin-exchange-wrapper,
	.testimonial-wrapper,
	.st-team-wrapper,
	.live-chart-wrapper {
		margin-left: 0px !important;
		margin: 0 auto !important
	}
}

.card-wrapper {
	margin-left: 170px;
	max-width: 1300px
}

.card-top-section {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px
}

.seingl-card {
	box-shadow: 0px 10px 40px var(--shadow-color);
	background-color: var(--white);
	padding: 20px;
	border-radius: 10px
}

.card-title {
	margin-bottom: 0
}

.card-title h6 {
	line-height: 27px;
	font-weight: 600;
	padding-bottom: 25px;
	margin-bottom: 0px
}

.card-content {
	padding-top: 20px
}

.card-content h4 {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	padding-bottom: 10px;
	margin-bottom: 0px
}

.card-content p {
	font-size: 14px;
	line-height: 21px;
	padding-bottom: 15px;
	margin-bottom: 0px;
	line-height: 1.5
}

.card-content a {
	margin: 0
}

.card-style-4 {
	grid-row: 2/span 2
}

.card-style-4 .card-content {
	padding-top: 0;
	padding-bottom: 30px
}

.card-style-5 .card-content {
	padding-top: 0;
	padding-bottom: 0px
}

.card-style-7 {
	grid-column: 2/span 2
}

.card-style-7 .card-content p {
	padding-bottom: 25px
}

.card-footer-items {
	display: flex;
	justify-content: space-between
}

.card-footer-items .minute-show span {
	font-size: 12px;
	line-height: 18px;
	color: var(--disable-color-2)
}

.card-footer-items .card-social a {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 1px solid var(--disable-color);
	color: var(--disable-color);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	margin-left: 20px;
	transition: all .3s ease-in-out
}

.card-footer-items .card-social a:hover {
	border-color: transparent;
	color: var(--white);
	background-color: var(--btn-primary)
}

.card-bottom {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	padding-top: 20px;
	max-width: 1100px;
	margin: 0 auto
}

.bottom-single-card {
	display: flex;
	align-items: center
}

.bottom-single-card .card-content {
	padding-left: 20px
}

.bottom-single-card .card-content p {
	padding: 0
}

.bottom-single-card .card-thumb img {
	max-width: 170px
}

.bottom-single-card-2 .card-content {
	padding-left: 0;
	padding-right: 20px
}

@media screen and (max-width: 1350px) {
	.card-wrapper {
		margin-left: 70px
	}
}

@media screen and (max-width: 1199px) {
	.card-wrapper {
		margin-left: 0px
	}
}

@media screen and (max-width: 991px) {
	.seingl-card {
		padding: 20px;
		border-radius: 5px
	}
	.card-top-section {
		grid-template-columns: 1fr 1fr
	}
	.card-style-2 .card-thumb {
		text-align: center
	}
	.card-style-3 {
		order: 7
	}
	.card-style-3 .card-thumb {
		text-align: right
	}
	.card-style-6 .card-title {
		text-align: left
	}
	.card-style-7 {
		grid-column: unset
	}
}

@media screen and (max-width: 767px) {
	.card-title h6 {
		line-height: 26px;
		padding-bottom: 12px;
		font-size: 17px
	}
	.card-footer-items {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center
	}
	.card-footer-items .card-social {
		margin-top: 10px
	}
	.card-footer-items .card-social a {
		margin: 0px 5px
	}
}

@media screen and (max-width: 575px) {
	.card-top-section {
		grid-template-columns: 1fr
	}
	.seingl-card {
		max-width: 400px;
		margin: 0 auto
	}
	.card-style-7 .card-content p {
		padding-bottom: 15px
	}
	.card-bottom {
		grid-template-columns: 1fr
	}
}

.accordions-wrapper {
	max-width: 1270px;
	margin-left: 200px
}

.accorion-wrap {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 40px
}

.accordion-title h6 {
	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
	margin-bottom: 25px
}

.accordion {
	background-color: var(--accordion-title-bg);
	padding: 30px;
	border: 1px solid transparent;
	transition: all .3s ease-in-out
}

.accordion.active {
	background-color: transparent;
	border-color: var(--w-light)
}

.accordion.active .accordion-head h6 {
	color: var(--font-color)
}

.accordion.active .accordion-head h6 i {
	color: var(--btn-primary);
	transform: rotate(180deg)
}

.accordion .accordion-head {
	cursor: pointer
}

.accordion .accordion-head h6 {
	font-size: 18px;
	font-weight: 500;
	line-height: 27px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0px
}

.accordion .accordion-head h6 i {
	color: var(--btn-primary);
	transition: all .3s ease-in-out;
	font-size: 20px
}

.accordion .accordion-body {
	display: none;
	padding: 0;
	padding-top: 10px
}

.accordion .accordion-body p {
	font-weight: 14px;
	line-height: 21px;
	margin-bottom: 0px
}

.accorderon-2 {
	background-color: var(--btn-primary)
}

.accorderon-2 .accordion-head h6 {
	color: var(--white)
}

.accorderon-2 .accordion-head h6 i {
	color: var(--white)
}

.accorderon-3.active .accordion-head h6 {
	color: var(--btn-primary)
}

.accorderon-3.active .accordion-head h6 i {
	background-color: var(--fafafa);
	color: var(--font-color)
}

.accorderon-3 .accordion-head h6 i {
	color: var(--white);
	height: 32px;
	width: 32px;
	background-color: var(--btn-primary);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center
}

.accorderon-4.active .accordion-head .cp-icon {
	background-color: var(--accordion-title-bg);
	border-color: var(--w-light)
}

.accorderon-4.active .accordion-head .cp-icon i {
	color: var(--btn-primary)
}

.accorderon-4 .accordion-head {
	position: relative
}

.accorderon-4 .accordion-head .cp-icon {
	width: 32px;
	height: 32px;
	background-color: var(--btn-primary);
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
	transition: all .3s ease-in-out;
	position: absolute;
	top: 0;
	right: 0px
}

.accorderon-4 .accordion-head .cp-icon i {
	color: var(--white)
}

@media screen and (max-width: 1350px) {
	.accordions-wrapper {
		margin-left: 100px
	}
}

@media screen and (max-width: 1199px) {
	.accordions-wrapper {
		margin-left: 50px
	}
}

@media screen and (max-width: 991px) {
	.accordions-wrapper {
		margin-left: 0px;
		margin: 0 auto
	}
	.accordion .accordion-head h6 {
		font-size: 16px
	}
}

@media screen and (max-width: 767px) {
	.accorion-wrap {
		grid-template-columns: 1fr;
		max-width: 500px;
		margin: 0 auto
	}
	.accordion {
		padding: 20px
	}
	.accordion-title h6 {
		font-size: 18px;
		margin-bottom: 15px
	}
}

@media screen and (max-width: 575px) {
	.accorion-wrap {
		grid-gap: 20px
	}
	.accordion-title h6 {
		font-size: 18px;
		line-height: 27px;
		margin-bottom: 15px
	}
	.accordion {
		padding: 10px
	}
	.accordion .accordion-head h6 {
		font-size: 15px
	}
	.accordion .accordion-head h6 i {
		font-size: 18px
	}
	.accorderon-3 .accordion-head h6 i {
		height: 25px;
		width: 25px;
		font-size: 16px
	}
	.accorderon-4 .accordion-head .cp-icon {
		width: 25px;
		height: 25px
	}
	.accorderon-4 .accordion-head .cp-icon i {
		font-size: 16px
	}
}

.image-wrapper {
	max-width: 1300px;
	margin-left: 170px
}

.image-box-1 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px
}

.single-image {
	background: var(--white);
	box-shadow: 0px 10px 40px var(--shadow-color);
	border-radius: 10px;
	padding: 30px 20px 25px;
	text-align: center
}

.single-image h6 {
	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
	padding-bottom: 30px;
	text-align: left;
	margin-bottom: 0px
}

.thumbnail-image {
	border: 5px solid #F0F0F0
}

.thumbnail-image img {
	width: 100%;
	height: 100%
}

.rounded-image {
	border-radius: 20px;
	overflow: hidden
}

.rounded-image img {
	width: 100%;
	height: 100%;
	object-fit: cover
}

.circle-image {
	border-radius: 50%;
	overflow: hidden
}

.circle-image img {
	height: 100%;
	width: 100%;
	object-fit: cover
}

.fullwidth-image img {
	width: 100%;
	height: 100%
}

.image-box-2 {
	padding-top: 40px
}

@media screen and (max-width: 1350px) {
	.image-wrapper {
		margin-left: 100px
	}
}

@media screen and (max-width: 1199px) {
	.image-wrapper {
		margin-left: 50px
	}
}

@media screen and (max-width: 991px) {
	.image-wrapper {
		margin-left: 0px
	}
}

@media screen and (max-width: 767px) {
	.image-box-1 {
		grid-template-columns: 1fr 1fr
	}
	.image-box-2 {
		padding-top: 30px
	}
}

@media screen and (max-width: 575px) {
	.image-box-1 {
		grid-template-columns: 1fr;
		max-width: 400px;
		margin: 0 auto
	}
	.single-image {
		padding: 20px
	}
	.single-image h6 {
		padding-bottom: 15px
	}
	.image-box-2 {
		padding-top: 20px
	}
}

.video-wrapper {
	max-width: 1200px;
	margin-left: 230px
}

.video-wrap {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px
}

.video-box {
	position: relative;
	overflow: hidden;
	border-radius: 10px;
	overflow: hidden
}

.video-box::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background-color: var(--video-overlay);
	top: 0;
	left: 0;
	border-radius: 10px
}

.video-box img {
	width: 100%
}

.video-box .play-button {
	position: absolute;
	content: "";
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%)
}

.video-box .play-button a {
	color: var(--white);
	font-size: 50px
}

.video-box-3 .play-button {
	position: absolute;
	content: "";
	width: 50px;
	height: 50px;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1
}

.video-box-3 .play-button::before,
.video-box-3 .play-button::after {
	position: absolute;
	content: "";
	background-color: var(--white);
	width: 100%;
	height: 100%;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
	border-radius: 50%;
	top: 0;
	left: 0;
	transform: scale(0.5);
	animation: play_button 1.5s linear infinite;
	opacity: 0;
	z-index: -1
}

.video-box-3 .play-button::after {
	animation-delay: .5s
}

.video-box-3 .play-button a {
	font-size: 50px;
	line-height: 50px;
	color: var(--white);
	position: relative;
	z-index: 9
}

@keyframes play_button {
	0% {
		transform: scale(0.5)
	}
	50% {
		opacity: .5
	}
	100% {
		transform: scale(2.5);
		opacity: 0
	}
}

@media screen and (max-width: 1350px) {
	.video-wrapper {
		margin-left: 100px
	}
}

@media screen and (max-width: 1199px) {
	.video-wrapper {
		margin-left: 50px
	}
}

@media screen and (max-width: 991px) {
	.video-wrapper {
		margin-left: 0px
	}
}

@media screen and (max-width: 767px) {
	.video-wrap {
		grid-template-columns: 1fr 1fr
	}
}

@media screen and (max-width: 575px) {
	.video-wrap {
		grid-template-columns: 1fr;
		grid-gap: 20px;
		max-width: 350px;
		margin: 0 auto
	}
}

.carousel-wrapper {
	max-width: 1325px;
	margin-left: 170px
}

.single-carousel {
	background-color: var(--white);
	box-shadow: 0px 10px 40px var(--shadow-color);
	border-radius: 10px;
	padding: 35px 25px 25px;
	margin-bottom: 20px
}

.single-carousel img {
	width: 100%
}

.carousel-title h6 {
	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
	padding-bottom: 15px;
	margin-bottom: 0
}

.carousel-active .items img {
	width: 100%
}

.carousel-active.carousel-3,
.carousel-active.carousel-4 {
	position: relative
}

.carousel-active.carousel-3::before,
.carousel-active.carousel-4::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-image: url("../images/short-code-img/carousel/carousel-3.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat
}

.carousel-active.carousel-3 .carousel-content,
.carousel-active.carousel-4 .carousel-content {
	padding: 120px 35px 70px;
	text-align: center
}

.carousel-active.carousel-3 .carousel-content h6,
.carousel-active.carousel-4 .carousel-content h6 {
	font-weight: 600;
	line-height: 27px;
	color: var(--white);
	padding-bottom: 10px;
	margin-bottom: 0
}

.carousel-active.carousel-3 .carousel-content p,
.carousel-active.carousel-4 .carousel-content p {
	font-size: 15px;
	line-height: 22px;
	color: var(--white);
	margin-bottom: 0;
	line-height: 1.5
}

.carousel-active.carousel-4::before {
	background-image: url("../images/short-code-img/carousel/carousel-4.png")
}

.carousel-active-2 .carousel-thumb img {
	width: 100%
}

.carousel-5 {
	margin-bottom: 0
}

.carousel-5 .content-bg {
	background-color: var(--fafafa)
}

.carousel-5 .owl-dots {
	display: none
}

.carousel-5 .carousel-content {
	padding: 10px 22px 10px 15px
}

.carousel-5 .carousel-content h6 {
	font-weight: 600;
	line-height: 27px;
	padding-bottom: 10px;
	margin-bottom: 0
}

.carousel-5 .carousel-content p {
	font-size: 15px;
	line-height: 22px;
	padding-bottom: 15px;
	margin-bottom: 0;
	line-height: 1.5
}

.carousel-5 .carousel-content a {
	margin: 0
}

.carousel-5 .owl-nav {
	position: absolute;
	bottom: 20px;
	left: 19%
}

.carousel-5 .owl-nav .owl-prev {
	display: inline-block;
	font-size: 20px;
	color: var(--white) !important;
	margin-right: 10px
}

.carousel-5 .owl-nav .owl-next {
	display: inline-block;
	font-size: 20px;
	color: var(--white) !important;
	margin-left: 10px
}

.carousel-6 {
	margin-bottom: 0
}

.carousel-6 .owl-dots {
	display: none
}

.carousel-active {
	position: relative
}

.carousel-active .owl-dots {
	position: absolute;
	bottom: 25px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	align-items: center
}

.carousel-1 .owl-dot {
	background: var(--white) !important;
	width: 35px;
	height: 3px;
	border-radius: 20px;
	margin: 0px 8px
}

.carousel-1 .owl-dot.active {
	height: 6px
}

.carousel-2 .owl-dot {
	border: 2px solid var(--white) !important;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin: 0px 8px
}

.carousel-2 .owl-dot.active {
	background-color: var(--white);
	height: 10px
}

@media screen and (max-width: 1350px) {
	.carousel-wrapper {
		margin-left: 100px
	}
}

@media screen and (max-width: 1199px) {
	.carousel-wrapper {
		margin-left: 50px
	}
	.carousel-5 {
		margin-bottom: 20px
	}
}

@media screen and (max-width: 991px) {
	.carousel-wrapper {
		margin-left: 0px
	}
}

@media screen and (max-width: 767px) {
	.carousel-5 .carousel-content {
		text-align: center
	}
	.carousel-5 .carousel-thumb img {
		max-height: 274px
	}
	.carousel-5 .owl-nav {
		position: absolute;
		bottom: 190px;
		left: 50%;
		transform: translateX(-50%)
	}
}

@media screen and (max-width: 575px) {
	.single-carousel {
		border-radius: 5px;
		padding: 10px 10px 15px;
		margin-bottom: 15px
	}
	.single-carousel .owl-dots {
		bottom: 15px
	}
	.carousel-3 .carousel-content {
		padding: 40px 10px
	}
	.carousel-3 .carousel-content h6 {
		line-height: 24px;
		padding-bottom: 5px;
		font-size: 16px
	}
	.carousel-5 .carousel-thumb img {
		max-height: 200px
	}
	.carousel-title h6 {
		font-size: 16px;
		line-height: 24px;
		padding-bottom: 10px
	}
}

.pegination-wrapper {
	max-width: 1400px;
	margin-left: 130px
}

.pegination-wrap {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px
}

.single-pegination {
	background: var(--white);
	box-shadow: 0px 10px 40px var(--shadow-color);
	border-radius: 10px;
	padding: 25px 35px;
	display: flex;
	align-items: center;
	justify-content: space-between
}

.single-pegination a {
	font-size: 20px;
	line-height: 1;
	font-weight: 500;
	color: var(--font-color);
	transition: all .3s ease-in-out;
	margin: 0px 5px
}

.single-pegination a:hover,
.single-pegination a.active {
	color: var(--btn-primary)
}

.pegination-2 a {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	margin: 0px 2px
}

.pegination-2 a:last-child,
.pegination-2 a:first-child {
	width: 0;
	height: 0
}

.pegination-2 a:last-child:hover,
.pegination-2 a:first-child:hover {
	background-color: none;
	color: var(--btn-primary)
}

.pegination-2 a:hover,
.pegination-2 a.active {
	background-color: var(--btn-primary);
	color: var(--white)
}

.pegination-3 a {
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	border: 1px solid var(--w-light);
	justify-content: center;
	background-color: transparent;
	margin: 0px 2px
}

.pegination-3 a:last-child,
.pegination-3 a:first-child {
	width: 0;
	height: 0
}

.pegination-3 a:last-child:hover,
.pegination-3 a:first-child:hover {
	background-color: none;
	color: var(--btn-primary)
}

.pegination-3 a:hover,
.pegination-3 a.active {
	background-color: var(--btn-primary);
	color: var(--white);
	border-color: transparent
}

.pegination-4 a {
	border: unset;
	border-radius: 5px
}

.pegination-4 a:last-child,
.pegination-4 a:first-child {
	width: 0;
	height: 0;
	color: var(--btn-primary)
}

.pegination-4 a:last-child:hover,
.pegination-4 a:first-child:hover {
	background-color: none;
	color: var(--btn-primary)
}

.pegination-4 a:hover,
.pegination-4 a.active {
	background-color: var(--btn-primary);
	color: var(--white)
}

.pegination-5 a {
	position: relative
}

.pegination-5 a:last-child,
.pegination-5 a:first-child {
	font-size: 14px;
	font-weight: 500
}

.pegination-5 a:last-child::before,
.pegination-5 a:first-child::before {
	display: none
}

.pegination-5 a::before {
	position: absolute;
	content: "";
	width: 0px;
	height: 3px;
	background-color: var(--btn-primary);
	bottom: -10px;
	left: 50%;
	transform: translateX(-50%);
	transition: all .3s ease-in-out
}

.pegination-5 a.active::before {
	width: 40px
}

.pegination-5 a:hover:before {
	width: 40px
}

.pegination-6 a:last-child,
.pegination-6 a:first-child {
	font-size: 14px;
	font-weight: 500;
	border: 1px solid var(--btn-primary);
	padding: 7px 10px;
	border-radius: 3px
}

.pegination-6 a:last-child:hover,
.pegination-6 a:first-child:hover {
	background-color: var(--btn-primary);
	color: var(--white)
}

@media screen and (max-width: 1350px) {
	.pegination-wrapper {
		margin-left: 100px
	}
}

@media screen and (max-width: 1199px) {
	.pegination-wrapper {
		margin-left: 50px
	}
}

@media screen and (max-width: 991px) {
	.pegination-wrapper {
		margin-left: 0px
	}
	.pegination-wrap {
		grid-template-columns: 1fr 1fr
	}
}

@media screen and (max-width: 767px) {
	.pegination-wrapper {
		margin-left: 0px
	}
	.pegination-wrap {
		grid-template-columns: 1fr;
		max-width: 400px;
		margin: 0 auto
	}
	.single-pegination a {
		font-size: 18px
	}
	.pegination-2 a {
		width: 40px;
		height: 40px
	}
	.pegination-3 a {
		width: 38px;
		height: 38px
	}
}

@media screen and (max-width: 575px) {
	.single-pegination {
		padding: 30px 20px
	}
	.pegination-3 a {
		width: 34px;
		height: 34px
	}
}

.nab-tab-wrapper {
	max-width: 1300px;
	margin-left: 130px
}

.nab-tab-wrap {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px
}

.nav-item {
	width: calc(100% / 3)
}

.nab-title h6 {
	font-weight: 600;
	line-height: 27px;
	padding-bottom: 30px;
	margin-bottom: 0
}

.nav-tab-content {
	padding-top: 30px
}

.nav-tab-content h6 {
	line-height: 27px;
	font-weight: 600;
	padding-bottom: 10px;
	margin-bottom: 0
}

.nav-tab-content p {
	font-size: 15px;
	line-height: 23px;
	margin-bottom: 0;
	line-height: 1.5
}

.single-nab-tab {
	box-shadow: 0px 10px 40px var(--shadow-color);
	background-color: var(--white);
	border-radius: 10px;
	padding: 35px 30px
}

.single-nab-tab .nav-tab .nav-link {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 40px;
	padding: 0;
	text-decoration: none;
	transition: all .3s ease-in-out;
	background-color: var(--w-light);
	border-radius: 0;
	font-size: 14px;
	font-weight: 500;
	color: var(--font-color);
	margin-right: 10px;
	font-family: var(--poppins)
}

.single-nab-tab .nav-tab .nav-link.active {
	background-color: var(--btn-primary);
	color: var(--white)
}

.nab-tab-2 .nav-tab {
	display: flex;
	justify-content: center
}

.nab-tab-2 .nav-tab .nav-link {
	margin: 0px 5px;
	background-color: transparent;
	position: relative
}

.nab-tab-2 .nav-tab .nav-link.active {
	background-color: transparent;
	color: var(--btn-primary)
}

.nab-tab-2 .nav-tab .nav-link.active::before {
	width: 100%
}

.nab-tab-2 .nav-tab .nav-link::before {
	position: absolute;
	content: "";
	width: 0px;
	height: 2px;
	background-color: var(--btn-primary);
	bottom: 0;
	left: 50%;
	transform: translateX(-50%)
}

.nab-tab-2 .nav-tab .nav-link:hover:before {
	width: 100%
}

.nab-tab-3 .nav-tab {
	display: flex;
	justify-content: center
}

.nab-tab-3 .nav-tab .nav-link {
	margin: 0px 5px;
	background-color: transparent
}

.nab-tab-4 .nav-tab {
	display: flex;
	justify-content: flex-end
}

.nab-tab-4 .nav-tab .nav-link {
	margin-left: 10px;
	background-color: transparent;
	width: 80px
}

.nab-tab-4 .nav-tab .nav-link.active {
	background-color: transparent;
	color: var(--btn-primary)
}

.nab-tab-5 .nav-tab {
	background-color: var(--btn-primary);
	border-radius: 10px 10px 0px 0px;
	padding: 16px 30px;
	display: flex;
	justify-content: space-between
}

.nab-tab-5 .nav-tab .nav-link {
	background-color: transparent;
	color: var(--white);
	position: relative;
	height: 100%;
	width: 100%
}

.nab-tab-5 .nav-tab .nav-link::before {
	position: absolute;
	content: "";
	clip-path: polygon(50% 13%, 0 100%, 100% 100%);
	background-color: var(--white);
	width: 35px;
	height: 14px;
	bottom: -17px;
	left: 50%;
	transform: translateX(-50%);
	transition: all .3s ease-in-out;
	opacity: 0
}

.nab-tab-5 .nav-tab .nav-link.active::before {
	opacity: 1
}

.nab-tab-6 .nav-tab {
	flex-direction: column;
	align-items: flex-start
}

.nab-tab-6 .nav-tab .nav-link {
	background-color: transparent;
	padding: 0;
	transition: all .3s ease-in-out;
	font-size: 14px;
	line-height: 1.5;
	font-weight: 500;
	margin-bottom: 15px;
	color: var(--font-color);
	text-align: flex-start;
	justify-content: flex-start
}

.nab-tab-6 .nav-tab .nav-link:last-child {
	margin-bottom: 0
}

.nab-tab-6 .nav-tab .nav-link.active {
	background-color: transparent !important;
	color: var(--btn-primary)
}

.nab-tab-6 .nav-tab .nav-item {
	width: 100%
}

.nab-tab-6 .nav-tab-content {
	padding-top: 0px;
	padding-left: 15px
}

@media screen and (max-width: 1350px) {
	.nab-tab-wrapper {
		margin-left: 100px
	}
}

@media screen and (max-width: 1199px) {
	.nab-tab-wrapper {
		margin-left: 50px
	}
	.single-nab-tab .nav-tab .nav-link {
		width: 70px;
		height: 32px
	}
	.nab-tab-5 .nav-tab .nav-link {
		height: 100%;
		width: 100%
	}
	.nab-tab-4 .nav-tab .nav-link {
		margin-left: 2px
	}
}

@media screen and (max-width: 991px) {
	.nab-tab-wrapper {
		margin-left: 0
	}
	.nab-tab-wrap {
		grid-template-columns: 1fr 1fr
	}
}

@media screen and (max-width: 767px) {
	.single-nab-tab {
		padding: 20px 15px
	}
	.nab-title h6 {
		padding-bottom: 15px
	}
	.nav-tab-content {
		padding-top: 15px
	}
	.nab-tab-5 .nav-tab {
		padding: 10px 20px
	}
}

@media screen and (max-width: 585px) {
	.nab-tab-wrap {
		grid-template-columns: 1fr;
		max-width: 400px;
		margin: 0 auto
	}
}

.alert-wrapper {
	max-width: 1140px;
	margin-left: 200px
}

.alart-wrap {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px
}

.single-alert-box {
	margin-bottom: 30px
}

.single-alert-box:last-child {
	margin-bottom: 0
}

.single-alert-box .alert-warning {
	color: var(--disable-color);
	background-color: var(--alert-1-bg);
	border-color: var(--success)
}

.single-alert-box .alert {
	position: relative;
	padding: 28px 40px;
	margin-bottom: 0rem;
	border-radius: 5px
}

.single-alert-box .alert-dismissible .btn-close {
	top: 10px;
	right: 10px;
	padding: 0px;
	color: var(--success);
	background: unset;
	font-size: 24px;
	line-height: 1;
	opacity: 1
}

.single-alert-box .alert-dismissible .btn-close i:after {
	opacity: 0
}

.alert-2 .alert-warning {
	background-color: var(--alert-2-bg);
	border-color: var(--danger)
}

.alert-2 .alert-dismissible .btn-close {
	color: var(--danger)
}

.alert-3 .alert-warning {
	background-color: transparent;
	border-color: transparent;
	background-image: url("../images/short-code-img/alert/alert-3.png");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 5px;
	position: relative;
	z-index: 3
}

.alert-3 .alert-warning::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background: var(--alert-box-3-overlay);
	top: 0;
	left: 0;
	border-radius: 5px
}

.alert-3 .alert-dismissible .btn-close {
	color: var(--white)
}

.alert-3 .alert-content h4 {
	color: var(--white);
	z-index: 3;
	position: relative;
	margin-bottom: 0
}

.alert-4 .alert-warning {
	background-color: var(--alert-4-bg);
	border-color: var(--warning)
}

.alert-4 .alert-dismissible .btn-close {
	color: var(--warning)
}

.alert-4 .alert {
	padding: 22px 30px
}

.alert-4 .alert-content h6 {
	color: var(--warning);
	margin-bottom: 0
}

.alert-5 .alert-warning {
	background-color: transparent;
	border-color: transparent;
	background: var(--alert-box-5-bg)
}

.alert-5 .alert-dismissible .btn-close {
	color: var(--white)
}

.alert-5 .alert-content h6 {
	color: var(--white)
}

.alert-5 .alert-content h4 {
	color: var(--white)
}

.alert-6 .alert-warning {
	background-color: transparent;
	border-color: transparent;
	background-image: url("../images/short-code-img/alert/alert-6.png");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 5px;
	position: relative;
	z-index: 3
}

.single-alert-box-2 {
	margin-bottom: 15px
}

.alert-title h6 {
	font-weight: 600;
	line-height: 27px;
	margin-bottom: 15px
}

.alert-content {
	position: relative;
	z-index: 3
}

.alert-content h6 {
	font-weight: 600;
	line-height: 27px;
	margin-bottom: 5px
}

.alert-content h4 {
	font-size: 15px;
	font-weight: 500;
	line-height: 22.5px;
	color: var(--disable-color)
}

@media screen and (max-width: 1350px) {
	.alert-wrapper {
		margin-left: 100px
	}
}

@media screen and (max-width: 1199px) {
	.alert-wrapper {
		margin-left: 50px
	}
}

@media screen and (max-width: 991px) {
	.alert-wrapper {
		margin-left: 0px
	}
}

@media screen and (max-width: 767px) {
	.single-alert-box {
		padding: 30px 20px
	}
	.single-alert-box {
		padding: 20px
	}
	.single-alert-box .alert {
		padding: 20px 25px
	}
	.alart-wrap {
		grid-template-columns: 1fr;
		max-width: 500px;
		margin: 0 auto
	}
}

@media screen and (max-width: 575px) {
	.alert-content h6 {
		font-size: 16px;
		line-height: 26px
	}
	.alert-content h4 {
		font-size: 14px;
		line-height: 21.5px;
		padding-right: 20px
	}
	.single-alert-box .alert {
		padding: 15px 10px
	}
}

.short-counter-wrapper {
	max-width: 690px;
	margin-left: 400px
}

.single-counter {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 20px;
	text-align: center;
	margin-bottom: 40px
}

.single-counter:last-child {
	margin-bottom: 0
}

.single-countdown-item {
	height: 150px;
	border: 1px solid var(--w-light);
	display: flex;
	flex-direction: column;
	justify-content: center
}

.single-countdown-item .ci-inner {
	font-size: 48px;
	font-weight: 700;
	color: var(--theme-color);
	line-height: 72px;
	padding-bottom: 10px
}

.single-countdown-item h4 {
	font-size: 20px;
	line-height: 30px;
	color: var(--option-color);
	margin-bottom: 0px
}

.counter-2 .single-countdown-item .ci-inner {
	position: relative
}

.counter-2 .single-countdown-item .ci-inner::before {
	position: absolute;
	content: "";
	width: 68px;
	height: 1px;
	background-color: var(--black);
	bottom: 5px;
	left: 50%;
	transform: translateX(-50%)
}

.counter-3 .single-countdown-item {
	border-radius: 10px;
	justify-content: flex-end;
	border: 1px solid var(--success)
}

.counter-3 .single-countdown-item .ci-inner {
	padding-bottom: 5px;
	background: var(--gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.counter-3 .single-countdown-item h4 {
	color: var(--white);
	background-color: var(--theme-color);
	font-size: 24px;
	line-height: 40px;
	padding: 5px;
	margin-bottom: 0px;
	border-radius: 0px 0px 10px 10px;
	border-top: 1px solid var(--extra-border)
}

.counter-4 .single-countdown-item {
	height: 100%;
	border: transparent
}

.counter-4 .single-countdown-item .ci-inner {
	max-width: 150px;
	height: 150px;
	border-radius: 50%;
	border: 1px solid var(--w-light);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0
}

.counter-4 .single-countdown-item h4 {
	padding-top: 10px;
	margin-bottom: 0px
}

.counter-5 .single-countdown-item {
	max-width: 150px;
	max-height: 150px;
	border: 1px solid var(--w-light);
	border-radius: 50%
}

.counter-5 .single-countdown-item .ci-inner {
	padding: 0
}

@media screen and (max-width: 1350px) {
	.short-counter-wrapper {
		margin-left: 100px
	}
}

@media screen and (max-width: 1199px) {
	.short-counter-wrapper {
		margin: 0 auto
	}
}

@media screen and (max-width: 767px) {
	.single-countdown-item {
		height: 120px
	}
	.single-countdown-item .ci-inner {
		font-size: 30px;
		line-height: 50px;
		padding-bottom: 0px
	}
	.single-countdown-item h4 {
		font-size: 16px;
		line-height: 26px
	}
	.single-counter {
		margin-bottom: 20px
	}
	.counter-3 .single-countdown-item h4 {
		font-size: 16px;
		line-height: 40px;
		padding: 0px
	}
	.counter-4 .single-countdown-item .ci-inner {
		width: 100px;
		height: 100px;
		margin: 0 auto
	}
	.counter-5 .single-countdown-item {
		width: 100px;
		height: 100px;
		margin: 0 auto
	}
}

@media screen and (max-width: 585px) {
	.single-counter {
		margin-bottom: 15px;
		grid-gap: 15px
	}
	.single-countdown-item {
		height: 80px
	}
	.single-countdown-item .ci-inner {
		font-size: 20px;
		line-height: 30px
	}
	.single-countdown-item h4 {
		font-size: 12px;
		line-height: 24px
	}
	.counter-2 .single-countdown-item .ci-inner::before {
		width: 40px;
		bottom: -1px
	}
	.counter-3 .countdown-item h4 {
		font-size: 12px;
		line-height: 28px
	}
	.counter-4 .single-countdown-item .ci-inner {
		height: 70px;
		width: 70px
	}
	.counter-4 .single-countdown-item h4 {
		padding-top: 0px
	}
	.counter-5 .single-countdown-item {
		height: 70px;
		width: 70px
	}
	.counter-5 .single-countdown-item h4 {
		line-height: 20px
	}
}

.st-team-wrapper {
	margin-left: 130px
}

.grid-3 {
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-gap: 30px;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 0 auto
}

@media all and (max-width: 1199px) {
	.st-team-wrapper {
		margin-left: 0px
	}
}

@media all and (max-width: 991px) {
	.team-member.ct-team-member .team-img {
		height: 200px;
		width: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px
	}
	.team-member.ct-team-member .team-img .team-img-inner {
		height: 100%;
		width: 100%
	}
	.ct-cmc-wrapper .cmc-single-content .cmcs-content-overlay {
		padding: 25px
	}
}

@media all and (max-width: 767px) {
	.grid-3 {
		grid-template-columns: 1fr 1fr
	}
}

@media all and (max-width: 575px) {
	.grid-3 {
		grid-template-columns: 1fr
	}
	.mxw-sm-2.member-card {
		padding-top: 90%;
		max-width: 350px;
		margin: 0 auto
	}
	.ct-cmc-wrapper .cmc-single-content {
		max-width: 350px;
		margin: 0 auto
	}
}

.short-code-wrapper {
	position: relative
}

.testimonial-wrapper {
	max-width: 1100px;
	margin-left: 250px
}

.single-testimonial {
	background-color: var(--white);
	border: 1px solid var(--w-light);
	border-radius: 10px;
	text-align: center;
	transition: all .3s ease-in-out;
	margin-bottom: 20px
}

.single-testimonial:last-child {
	margin-bottom: 0
}

.single-testimonial:hover {
	border: transparent;
	box-shadow: 0px 10px 30px var(--shadow-color)
}

.testimonial-profile {
	display: flex;
	align-items: center;
	justify-content: center
}

.testimonial-profile .profile-title {
	text-align: left;
	margin-left: 20px
}

.testimonial-profile .profile-title a {
	display: block;
	font-size: 24px;
	font-weight: 600;
	color: var(--font-color);
	line-height: 36px;
	text-transform: capitalize
}

.testimonial-profile .profile-title a span {
	display: block;
	font-size: 15px;
	line-height: 22px;
	font-weight: 400
}

.testimonial-profile .profile-title ul {
	margin-top: 13px
}

.testimonial-profile .profile-title ul li {
	display: inline-block;
	font-size: 20px;
	line-height: 1;
	color: var(--star-color);
	margin-right: 8px
}

.profile-content {
	padding-top: 20px
}

.profile-content p {
	font-size: 17px;
	line-height: 1.5;
	margin-bottom: 0
}

.testimonial-1 {
	padding: 70px 90px
}

.testimonial-2 {
	padding: 50px 60px 50px 130px;
	align-items: flex-start
}

.testimonial-2 .testimonial-profile {
	align-items: flex-start
}

.testimonial-2 .testimonial-profile .profile-image {
	position: relative
}

.testimonial-2 .testimonial-profile .profile-image img {
	max-width: 130px
}

.testimonial-2 .testimonial-profile .profile-image .quite {
	position: absolute;
	left: -70px;
	top: 50%;
	transform: translateY(-50%)
}

.testimonial-2 .testimonial-profile .profile-image .quite i {
	font-size: 35px;
	line-height: 1;
	color: var(--theme-color)
}

.testimonial-2 .testimonial-profile .profile-content {
	padding-top: 0;
	text-align: left;
	padding-left: 50px
}

.testimonial-2 .profile-title {
	text-align: right;
	margin-left: 0;
	margin-top: 20px
}

.testimonial-3 {
	padding: 40px 80px
}

.testimonial-3 .testimonial-profile {
	flex-direction: column
}

.testimonial-3 .profile-title {
	text-align: center;
	margin-left: 0;
	margin-top: 25px
}

.testimonial-3 .profile-title a {
	color: var(--btn-primary)
}

.testimonial-3 .profile-title a span {
	color: var(--body-color)
}

.testimonial-3 .quite {
	margin-top: 15px
}

.testimonial-3 .quite i {
	text-align: center;
	background: var(--gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 35px
}

.testimonial-4 .testimonial-reating {
	padding-top: 20px
}

.testimonial-4 .testimonial-reating ul li {
	display: inline-block;
	font-size: 20px;
	line-height: 1;
	color: var(--star-color);
	margin: 0px 4px
}

@media screen and (max-width: 1350px) {
	.testimonial-wrapper {
		margin-left: 100px
	}
}

@media screen and (max-width: 1199px) {
	.testimonial-wrapper {
		margin-left: 50px
	}
}

@media screen and (max-width: 991px) {
	.testimonial-wrapper {
		margin-left: 0px
	}
}

@media screen and (max-width: 767px) {
	.testimonial-1 {
		padding: 30px 20px
	}
	.testimonial-2 {
		padding: 30px 20px 30px 60px
	}
	.testimonial-2 .testimonial-profile .profile-image img {
		max-width: 100px
	}
	.testimonial-2 .testimonial-profile .profile-image .quite {
		left: -45px
	}
	.testimonial-2 .testimonial-profile .profile-image .quite i {
		font-size: 25px
	}
	.testimonial-2 .testimonial-profile .profile-content {
		padding-left: 20px
	}
	.testimonial-3 {
		padding: 30px 20px
	}
	.testimonial-3 .testimonial-profile .profile-image img {
		max-width: 100px
	}
	.testimonial-3 .profile-title {
		margin-top: 15px
	}
}

@media screen and (max-width: 575px) {
	.testimonial-1 {
		padding: 20px 10px
	}
	.testimonial-profile .profile-title a {
		font-size: 20px;
		line-height: 30px
	}
	.testimonial-profile .profile-title ul {
		margin-top: 8px
	}
	.testimonial-profile .profile-title ul li {
		display: inline-block;
		font-size: 18px;
		line-height: 1;
		color: var(--star-color);
		margin-right: 3px
	}
	.profile-content {
		padding-top: 15px
	}
	.profile-content p {
		font-size: 15px;
		line-height: 22px
	}
	.testimonial-2 {
		padding: 20px 10px 20px 10px
	}
	.testimonial-2 .testimonial-profile {
		flex-direction: column;
		justify-content: center;
		align-items: center
	}
	.testimonial-2 .testimonial-profile .profile-content {
		text-align: center;
		padding-left: 0;
		padding-top: 15px
	}
	.testimonial-2 .profile-title {
		text-align: center;
		margin-left: 0;
		margin-top: 15px
	}
	.testimonial-3 {
		padding: 20px 10px
	}
	.testimonial-3 .profile-title {
		margin-top: 10px
	}
	.testimonial-3 .quite {
		margin-top: 10px
	}
	.testimonial-3 .quite i {
		font-size: 25px
	}
	.testimonial-4 .testimonial-reating {
		padding-top: 10px
	}
	.testimonial-4 .testimonial-reating ul li {
		font-size: 18px;
		margin: 0px 3px
	}
}

.progress-bar-wrapper {
	max-width: 1150px;
	margin-left: 200px
}

.progress-bar-wrap {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px
}

.progress-title h6 {
	line-height: 27px;
	font-weight: 500;
	padding-bottom: 15px;
	margin-bottom: 0px
}

.single-progress {
	background-color: var(--white);
	box-shadow: 0px 10px 40px var(--shadow-color);
	border-radius: 10px;
	padding: 40px
}

.single-bar {
	display: flex;
	align-items: center;
	margin-bottom: 10px
}

.single-bar:last-child {
	margin-bottom: 0
}

.single-bar .progress {
	width: calc(100% - 15px);
	background-color: var(--w-light);
	border-radius: 0;
	height: 8px;
	margin-right: 15px
}

.single-bar .percent span {
	font-size: 14px;
	color: var(--font-color);
	font-weight: 500;
	line-height: 1
}

.single-bar.progress-2 .progress {
	height: 4px
}

.single-bar.progress-3 {
	position: relative;
	margin-bottom: 15px
}

.single-bar.progress-3:last-child {
	margin-bottom: 0
}

.single-bar.progress-3 .progress {
	height: 20px;
	border-radius: 80px;
	overflow: visible
}

.single-bar.progress-3 .progress .progress-bar {
	border-radius: 80px;
	overflow: visible
}

.single-bar.progress-3 .progress .progress-bar .span-number {
	position: absolute;
	right: 55%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-weight: 500;
	color: var(--white)
}

.single-bar.progress-3 .progress .progress-bar .span-number-2 {
	right: 37%;
	color: var(--font-color)
}

.single-bar.progress-3 .progress .progress-bar .span-number-3 {
	right: 17%
}

.single-bar.progress-3 .progress .progress-bar .span-number-4 {
	color: var(--font-color)
}

.single-bar.progress-4 {
	margin-bottom: 25px
}

.single-bar.progress-4:last-child {
	margin-bottom: 0
}

.single-bar.progress-4 .progress {
	height: 8px;
	border-radius: 18px
}

.single-bar.progress-4 .progress .progress-bar {
	border-radius: 18px
}

.single-bar.progress-4 .progress .progress-bar .span-rounded {
	position: absolute;
	top: 50%;
	height: 38px;
	width: 38px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 50%;
	transform: translateY(-50%);
	background: var(--denger-2);
	font-weight: 500;
	color: var(--white)
}

.single-bar.progress-4 .progress .progress-bar .span-rounded-2 {
	right: 32%;
	background: var(--warning);
	color: var(--font-color)
}

.single-bar.progress-4 .progress .progress-bar .span-rounded-3 {
	right: 17%;
	background: var(--btn-primary)
}

.single-bar.progress-4 .progress .progress-bar .span-rounded-4 {
	background: var(--success);
	color: var(--font-color)
}

.bg-danger {
	background-color: var(--denger-2) !important
}

.bg-warning {
	background-color: var(--warning)
}

.bg-primary {
	background-color: var(--btn-primary)
}

.bg-sucsess {
	background-color: var(--success)
}

@media screen and (max-width: 1350px) {
	.progress-bar-wrapper {
		margin-left: 100px
	}
}

@media screen and (max-width: 1199px) {
	.progress-bar-wrapper {
		margin-left: 50px
	}
	.single-bar.progress-4 .progress .progress-bar .span-rounded-3 {
		right: 10%
	}
}

@media screen and (max-width: 991px) {
	.progress-bar-wrapper {
		margin-left: 0px
	}
}

@media screen and (max-width: 767px) {
	.progress-bar-wrap {
		grid-template-columns: 1fr;
		grid-gap: 10px
	}
	.single-progress {
		border-radius: 5px;
		padding: 40px;
		max-width: 550px;
		margin: 0 auto;
		width: 100%
	}
}

@media screen and (max-width: 585px) {
	.single-progress {
		padding: 20px
	}
	.progress-title h6 {
		padding-bottom: 10px
	}
	.single-bar.progress-3 .progress {
		height: 16px
	}
	.single-bar.progress-4 .progress {
		height: 8px
	}
}

.coin-exchange-wrapper {
	max-width: 860px;
	margin-left: 400px
}

.exchange-currency-left label,
.exchange-currency-right label {
	font-size: 16px;
	color: var(--theme-color);
	font-weight: 500;
	margin-bottom: 20px;
	display: block
}

.exchange-wrap {
	background-color: var(--white);
	box-shadow: 0px 10px 40px var(--shadow-color);
	border-radius: 10px;
	padding: 70px
}

.exchange-currency {
	display: flex;
	width: 100%;
	align-items: center
}

.exchange-currency .list {
	height: 300px;
	overflow-y: auto
}

.exchange-box .currency {
	display: flex;
	position: relative
}

.exchange-box .currency input {
	height: 60px;
	width: 100%;
	background-color: var(--fafafa);
	border: 1px solid var(--w-light);
	border-radius: 5px;
	padding: 20px;
	outline: none
}

.exchange-box .currency .select-country {
	background-color: var(--warning);
	border: transparent;
	border-radius: 5px;
	position: absolute;
	width: 95px;
	height: 100%;
	padding: 16px;
	right: 0;
	top: 0;
	font-size: 18px;
	font-weight: 500;
	color: var(--theme-color);
	display: flex;
	align-items: center;
	justify-content: space-between
}

.exchange-box .currency .select-country::after {
	border-bottom: none;
	border-right: none;
	content: '\eab2';
	font-family: IcoFont;
	height: 15px;
	margin-top: unset;
	pointer-events: none;
	position: absolute;
	right: 20px;
	top: 50%;
	transform-origin: unset;
	transform: translateY(-50%) rotateX(0deg);
	width: 15px;
	display: flex;
	align-items: center;
	justify-content: center
}

.exchange-box .currency .select-country.open::after {
	transform: translateY(-50%) rotateX(180deg)
}

.exchange-currency-left {
	width: 45%
}

.exchange-currency-middle {
	width: 10%;
	padding-top: 40px
}

.exchange-currency-right {
	width: 45%
}

.exchange-currency-right .exchange-box .currency .select-country {
	background-color: var(--success)
}

.change-button {
	text-align: center;
	margin-top: 40px
}

.change-button .rate {
	font-size: 18px;
	color: var(--theme-color);
	margin-bottom: 10px
}

.change-button button {
	width: 220px;
	height: 52px;
	margin: 0
}

.bye-sale-wrappper {
	padding-top: 70px;
	max-width: 750px;
	margin: 0 auto
}

.bye-sale-content h4 {
	font-size: 24px;
	font-weight: 600;
	color: var(--theme-color);
	line-height: 33px;
	padding-bottom: 20px;
	margin-bottom: 0
}

.bye-sale-content p {
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 0;
	line-height: 1.5
}

.bye-sale-wrap {
	margin-top: 40px;
	background-color: var(--fafafa);
	border: 1px solid var(--w-light);
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px
}

.bye-sale-left {
	display: flex;
	align-items: center;
	margin-right: 20px
}

.bye-sale-left .single-bye-sale input {
	border: 1px solid var(--w-light);
	padding: 15px 20px;
	border-radius: 5px;
	width: 100%;
	height: 52px;
	font-size: 18px;
	font-weight: 500;
	color: var(--theme-color);
	outline: none
}

.bye-sale-left .single-bye-sale input::-moz-placeholder {
	font-size: 18px;
	font-weight: 500;
	color: var(--theme-color);
	opacity: 1
}

.bye-sale-left .single-bye-sale input::placeholder {
	font-size: 18px;
	font-weight: 500;
	color: var(--theme-color);
	opacity: 1
}

.bye-sale-left .single-bye-sale-box-1 {
	max-width: 90px
}

.bye-sale-left .single-bye-sale-box-2 span {
	font-size: 18px;
	font-weight: 500;
	color: var(--theme-color);
	margin: 0px 20px;
	display: inline-block
}

.bye-sale-left .single-bye-sale-box-3 {
	max-width: 150px
}

.bye-sale-left .select-bye-sale {
	margin-left: 20px;
	max-width: 100px;
	width: 100%
}

.bye-sale-left .select-bye-sale .nice-select {
	border-radius: 5px;
	border: solid 1px var(--w-light);
	font-size: 18px;
	font-weight: 500;
	color: var(--theme-color);
	height: 52px;
	line-height: 1;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 20px
}

.bye-sale-left .select-bye-sale .nice-select::after {
	border-bottom: none;
	border-right: none;
	content: '\eab2';
	font-family: IcoFont;
	color: var(--theme-color);
	height: 15px;
	margin-top: unset;
	pointer-events: none;
	position: absolute;
	right: 30px;
	top: 50%;
	transform: translateY(-50%) rotateX(0deg);
	font-size: 22px;
	display: flex;
	align-items: center
}

.bye-sale-left .select-bye-sale .nice-select .list li {
	margin-block-end: 0px
}

.bye-sale-left .select-bye-sale .nice-select .list li:before {
	display: none
}

.btc-to-usd {
	max-width: 250px;
	width: 100%
}

.btc-to-usd .nice-select {
	border-radius: 5px;
	border: solid 1px var(--w-light);
	font-size: 18px;
	font-weight: 500;
	background-color: var(--btn-primary);
	color: var(--white);
	height: 52px;
	line-height: 1;
	width: 100%;
	display: flex;
	align-items: center
}

.btc-to-usd .nice-select::after {
	border-bottom: none;
	border-right: none;
	content: '\eab2';
	font-family: IcoFont;
	color: var(--white);
	height: 20px;
	margin-top: unset;
	pointer-events: none;
	position: absolute;
	right: 30px;
	top: 50%;
	transform: translateY(-50%) rotateX(0deg);
	font-size: 22px
}

.btc-to-usd .nice-select.open::after {
	transform: translateY(-85%) rotateX(180deg)
}

.btc-to-usd .nice-select .list {
	background-color: var(--white) !important;
	width: 100%;
	position: absolute;
	color: var(--font-color);
	margin-bottom: 0px
}

.btc-to-usd .nice-select .list li {
	margin-bottom: 0px
}

.btc-to-usd .nice-select .list li:before {
	display: none
}

@media screen and (max-width: 1350px) {
	.coin-exchange-wrapper {
		margin-left: 100px
	}
}

@media screen and (max-width: 1199px) {
	.coin-exchange-wrapper {
		margin-left: 50px
	}
}

@media screen and (max-width: 991px) {
	.coin-exchange-wrapper {
		margin-left: 0px
	}
}

@media screen and (max-width: 767px) {
	.exchange-wrap {
		border-radius: 5px;
		padding: 20px
	}
	.bye-sale-wrappper {
		padding-top: 40px
	}
	.exchange-currency-middle {
		width: 10%;
		padding-top: 40px
	}
	.exchange-currency-middle .btn {
		padding: 10px
	}
	.bye-sale-wrap {
		flex-direction: column
	}
	.btc-to-usd {
		margin-top: 20px
	}
}

@media screen and (max-width: 575px) {
	.exchange-currency {
		flex-direction: column
	}
	.exchange-box .currency input {
		height: 50px
	}
	.exchange-currency-left {
		width: 100%
	}
	.exchange-currency-right {
		width: 100%
	}
	.exchange-currency-middle {
		width: 100%;
		padding: 10px 0;
		text-align: center
	}
	.exchange-currency-left label,
	.exchange-currency-right label {
		font-size: 14px;
		margin-bottom: 10px
	}
	.bye-sale-wrappper {
		padding-top: 30px
	}
	.bye-sale-wrap {
		padding: 20px 10px
	}
	.bye-sale-content h4 {
		font-size: 20px;
		line-height: 30px;
		padding-bottom: 10px
	}
	.bye-sale-content p {
		font-size: 15px
	}
	.bye-sale-left {
		margin-right: 0
	}
	.bye-sale-left .single-bye-sale input {
		padding: 5px 10px;
		width: 100%;
		height: 40px;
		font-size: 14px
	}
	.bye-sale-left .single-bye-sale input::-moz-placeholder {
		font-size: 16px
	}
	.bye-sale-left .single-bye-sale input::placeholder {
		font-size: 16px
	}
	.bye-sale-left .single-bye-sale-box-2 span {
		margin: 0px 10px
	}
	.bye-sale-left .select-bye-sale {
		max-width: 80px;
		margin-left: 10px
	}
	.bye-sale-left .select-bye-sale .nice-select {
		font-size: 16px;
		height: 40px;
		padding: 10px
	}
	.btc-to-usd .nice-select {
		font-size: 16px
	}
	.btc-to-usd .nice-select::after {
		height: 18px;
		font-size: 20px
	}
}

.input-field-wrapper {
	max-width: 1300px;
	margin-left: 130px
}

.input-field-wrap {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px
}

.single-input-field {
	box-shadow: 0px 10px 40px var(--shadow-color);
	border-radius: 10px;
	background-color: var(--white);
	padding: 40px 35px
}

.input-title h6 {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	padding-bottom: 30px;
	margin-bottom: 0
}

.single-input-box {
	position: relative;
	margin-bottom: 20px
}

.single-input-box:last-child {
	margin-bottom: 0
}

.single-input-box input {
	background-color: var(--fafafa);
	border: 1px solid var(--w-light);
	border-radius: 100px;
	width: 100%;
	height: 60px;
	padding: 20px;
	outline: none;
	transition: all .3s ease-in-out
}

.single-input-box input::-moz-placeholder {
	color: var(--body-color);
	font-size: 14px;
	opacity: 1
}

.single-input-box input::placeholder {
	color: var(--body-color);
	font-size: 14px;
	opacity: 1
}

.single-input-box input:focus {
	border-color: var(--btn-primary)
}

.single-input-box i {
	position: absolute;
	content: "";
	top: 50%;
	transform: translateY(-50%);
	font-size: 18px;
	line-height: 1;
	color: var(--disable-color)
}

.single-input-box.round-shape input {
	border-radius: 5px
}

.single-input-box.box-1 input {
	padding-right: 60px
}

.single-input-box.box-1 i {
	right: 35px
}

.single-input-box.box-2 input {
	padding-left: 50px
}

.single-input-box.box-2 i {
	left: 22px
}

.single-input-box.box-3 {
	display: flex;
	align-items: center
}

.single-input-box.box-3 label {
	font-size: 16px;
	color: var(--disable-color);
	margin-right: 20px;
	line-height: 1
}

.single-input-box.box-3 input {
	padding-right: 60px
}

.single-input-box.box-3 i {
	right: 35px
}

.single-input-box.box-4 i {
	color: var(--btn-primary)
}

.input-with-label {
	margin-bottom: 20px
}

.input-with-label:last-child {
	margin-bottom: 0
}

.input-with-label label {
	font-size: 16px;
	color: var(--disable-color);
	line-height: 1;
	margin-bottom: 20px;
	display: block
}

.input-with-label .single-input-box input {
	border-radius: 5px
}

.input-with-label .box-1 i {
	right: 20px
}

.input-with-label .box-2 input {
	padding-left: 20px;
	padding-right: 60px
}

.input-with-label .box-2 i {
	left: unset;
	right: 20px;
	color: var(--btn-primary)
}

.input-with-label .box-3 i {
	right: 20px
}

.input-with-label .box-3 label {
	margin-bottom: 0
}

.single-select-box .nice-select {
	background-color: var(--btn-primary);
	border-radius: 5px;
	border: 0;
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	color: var(--white);
	height: 60px;
	padding-left: 30px;
	width: calc(100% + 2px);
	left: -1px;
	display: flex;
	align-items: center;
	transition: 0s;
	position: relative;
	margin-bottom: 20px
}

.single-select-box .nice-select::after {
	border-bottom: none;
	border-right: none;
	content: '\eab2';
	font-family: IcoFont;
	display: block;
	height: 15px;
	margin-top: unset;
	pointer-events: none;
	position: absolute;
	right: 25px;
	top: 50%;
	transform-origin: unset;
	transform: translateY(-50%) rotateX(0deg);
	width: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px
}

.single-select-box .nice-select.open::after {
	transform: translateY(-50%) rotateX(180deg)
}

.single-select-box .nice-select .list {
	background-color: var(--white) !important;
	width: 100%;
	position: absolute;
	color: var(--font-color)
}

.select-field-wrap.box-2 .nice-select {
	background-color: transparent !important;
	border: 1px solid var(--btn-primary);
	color: var(--font-color);
	width: calc(100% + 2px);
	left: -1px
}

.select-field-wrap.box-3 .nice-select {
	width: 100%;
	left: 0
}

.select-field-wrap.box-3 .nice-select::after {
	content: '\ea67';
	right: 0px;
	top: 50%;
	height: 100%;
	width: 60px;
	background-color: #0066DD;
	border-radius: 5px
}

.select-field-wrap.box-3 .nice-select.open::after {
	transform: translateY(-50%) rotateX(0)
}

.select-field-wrap.box-4 .nice-select {
	width: 100%;
	left: 0;
	margin-bottom: 0;
	background-color: var(--fafafa);
	color: var(--font-color)
}

.select-field-wrap.box-4 .nice-select::after {
	content: '\ea67';
	right: 0px;
	top: 50%;
	height: 100%;
	width: 60px;
	background-color: #0066DD;
	border-radius: 5px;
	color: var(--white)
}

.select-field-wrap.box-4 .nice-select.open::after {
	transform: translateY(-50%) rotateX(0)
}

.input-field .single-input-box input {
	border-radius: 5px
}

.input-field .box-3 i {
	color: var(--btn-primary)
}

.custom-input-box {
	margin-bottom: 30px;
	position: relative
}

.custom-input-box .main-btn {
	background-color: var(--w-light);
	height: 52px;
	border-radius: 5px;
	padding-left: 50px;
	font-size: 14px;
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	font-weight: 500;
	line-height: 1
}

.custom-input-box .upload-button {
	position: absolute;
	top: 0;
	right: 0;
	background-color: var(--btn-primary);
	width: 170px;
	height: 100%;
	border-radius: 5px;
	color: var(--white);
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	font-weight: 500;
	font-size: 14px
}

.custom-input-box .upload-button input {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0
}

.custom-input-box .upload-button i {
	width: 24px;
	height: 24px;
	border-radius: 5px;
	border: 2px solid var(--white);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px
}

.coustom-input-file .box-2 .custom-input-box .upload-button {
	width: 62px
}

.coustom-input-file .box-3 .custom-input-box {
	margin-bottom: 0
}

.coustom-input-file .box-3 .custom-input-box .main-btn {
	padding-left: 15px;
	background-color: var(--white);
	border: 1px solid var(--btn-primary)
}

.coustom-input-file .box-3 .custom-input-box .upload-button {
	width: 100px
}

.textarea-field .single-textarea-box {
	margin-bottom: 15px
}

.textarea-field .single-textarea-box textarea {
	width: 100%;
	height: 100px;
	background-color: var(--white);
	border: 1px solid var(--w-light);
	border-radius: 5px;
	padding: 20px;
	resize: none;
	outline: none;
	font-size: 15px;
	transition: all .3s ease-in-out
}

.textarea-field .single-textarea-box textarea::-moz-placeholder {
	color: var(--body-color);
	font-size: 14px;
	opacity: 1
}

.textarea-field .single-textarea-box textarea::placeholder {
	color: var(--body-color);
	font-size: 14px;
	opacity: 1
}

.textarea-field .single-textarea-box textarea:focus {
	border-color: var(--btn-primary)
}

.textarea-field .box-2 {
	margin-bottom: 0
}

.textarea-field .box-2 textarea {
	background-color: var(--fafafa)
}

@media screen and (max-width: 1350px) {
	.input-field-wrapper {
		margin-left: 100px
	}
	.custom-input-box .main-btn {
		padding-left: 20px
	}
}

@media screen and (max-width: 1199px) {
	.input-field-wrapper {
		margin-left: 50px
	}
	.input-field-wrap {
		grid-template-columns: 1fr 1fr
	}
	.custom-input-box .upload-button {
		width: 140px
	}
}

@media screen and (max-width: 991px) {
	.input-field-wrapper {
		margin-left: 0px
	}
}

@media screen and (max-width: 767px) {
	.input-field-wrap {
		grid-template-columns: 1fr;
		max-width: 450px;
		margin: 0 auto
	}
}

@media screen and (max-width: 575px) {
	.single-input-field {
		padding: 15px 15px
	}
	.input-title h6 {
		padding-bottom: 15px
	}
	.rounded-input-filed .box-3 label {
		font-size: 15px;
		margin-right: 15px
	}
	.custom-input-box {
		margin-bottom: 15px
	}
	.input-field-with-label .single-input-box label {
		margin-bottom: 10px
	}
	.input-field-with-label .box-2 i {
		left: 90%
	}
	.single-input-box {
		margin-bottom: 15px
	}
	.single-input-box input {
		height: 50px
	}
	.single-input-box i {
		font-size: 17px
	}
	.rounded-input-filed .box-1 i {
		right: 20px
	}
	.rounded-input-filed .box-3 i {
		right: 20px
	}
	.single-select-box .nice-select {
		height: 50px;
		margin-bottom: 15px;
		font-size: 14px
	}
	.textarea-field .single-textarea-box {
		margin-bottom: 10px
	}
}

.live-chart-wrapper {
	max-width: 1220px;
	margin-left: 230px
}

.table-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px
}

.table-title h6 {
	font-weight: 600;
	font-size: 18px;
	line-height: 1;
	color: var(--font-color);
	margin-bottom: 0
}

.table-title .title-select .nice-select {
	background-color: var(--btn-primary);
	border-radius: 5px;
	border: none;
	font-family: var(--poppins);
	font-size: 18px;
	font-weight: 600;
	height: 60px;
	line-height: 1px;
	padding-left: 18px;
	padding-right: 30px;
	width: 230px;
	display: flex;
	align-items: center;
	color: var(--white);
	opacity: 1
}

.table-title .title-select .nice-select::after {
	border-bottom: none;
	border-right: none;
	content: '\ea99';
	font-family: IcoFont;
	display: block;
	height: 15px;
	margin-top: unset;
	pointer-events: none;
	position: absolute;
	right: 30px;
	top: 50%;
	transform-origin: unset;
	transform: translateY(-50%) rotateX(0deg);
	width: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	font-weight: 300
}

.table-title .title-select .nice-select.open::after {
	transform: translateY(-50%) rotateX(180deg)
}

.table-title .title-select .nice-select .list {
	background-color: var(--white) !important;
	width: 100%;
	position: absolute;
	color: var(--font-color)
}

.table-title .title-select .nice-select .list li {
	margin-block-end: 0px
}

.table-title .title-select .nice-select .list li:before {
	display: none
}

.live-coin-chart-table {
	border-bottom: none !important
}

.live-coin-chart-table .table-header tr th {
	color: var(--font-color);
	background-color: var(--fafafa);
	padding: 20px 20px;
	font-size: 18px;
	font-weight: 600;
	border-bottom: none
}

.live-coin-chart-table .table-header tr th::before,
.live-coin-chart-table .table-header tr th::after {
	display: none
}

.live-coin-chart-table .table-body tr td {
	padding: 20px 18px;
	border: 1px solid var(--w-light)
}

.live-coin-chart-table .table-body tr td h6 {
	font-size: 16px;
	font-weight: 500;
	color: var(--disable-color);
	margin-bottom: 0
}

.live-coin-chart-table .table-body tr td .table-currenct {
	display: flex;
	align-items: center
}

.live-coin-chart-table .table-body tr td .table-currenct h6 {
	padding-left: 10px;
	margin-bottom: 0
}

.live-coin-chart-table .table-body tr td .change h6 {
	margin-bottom: 0
}

.live-coin-chart-table .table-body tr td .change h6 span {
	color: var(--table-change-span-color)
}

.live-coin-chart-table .table-body tr td .change h6 .span-red {
	color: var(--denger-2)
}

@media screen and (max-width: 1350px) {
	.live-chart-wrapper {
		margin-left: 50px
	}
}

@media screen and (max-width: 1199px) {
	.live-chart-wrapper {
		margin-left: 50px
	}
	.live-coin-chart-table .table-body tr .child ul.dtr-details {
		display: block
	}
	.live-coin-chart-table .table-body tr td .table-currenct {
		padding-left: 15px
	}
	.live-coin-chart-table .table-body tr td.dtr-control::before {
		left: 15px !important;
		height: 20px !important;
		width: 20px !important;
		margin-top: 0px !important;
		border: 0px !important;
		line-height: 20px !important;
		background-color: var(--btn-primary) !important;
		transform: translateY(-50%) !important
	}
}

@media screen and (max-width: 991px) {
	.live-chart-wrapper {
		margin-left: 0px
	}
}

@media screen and (max-width: 767px) {
	.table-title .title-select .nice-select {
		font-size: 16px;
		height: 50px;
		width: 180px
	}
	.table-title .title-select .nice-select::after {
		font-size: 20px;
		right: 20px
	}
}

@media screen and (max-width: 575px) {
	.table-title {
		flex-direction: column
	}
	.table-title .title-select {
		margin-top: 15px
	}
}

.subscribe-area {
	background-color: var(--subscribe-bg);
	margin-top: 50px
}

.subscribe-wrapper {
	max-width: 970px;
	margin: 0 auto;
	padding: 40px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between
}

.subscribe-content {
	width: calc(40% + 80px)
}

.subscribe-content h4 {
	color: var(--white);
	font-size: 24px;
	font-weight: 600;
	line-height: 36px;
	padding-bottom: 6px;
	margin-bottom: 0
}

.subscribe-content p {
	font-size: 16px;
	color: var(--white);
	margin-bottom: 0;
	line-height: 1.5
}

.subscribe-from {
	display: flex;
	width: 55%
}

.subscribe-from .input {
	position: relative
}

.subscribe-from .input input {
	width: 360px;
	background: var(--subscribe-from-bg);
	border: 1px solid var(--success);
	border-radius: 5px;
	height: 60px;
	padding: 20px 50px 20px 20px;
	outline: none;
	color: var(--white);
	font-size: 16px
}

.subscribe-from .input input::-moz-placeholder {
	font-size: 15px;
	line-height: 1;
	color: var(--white);
	opacity: 1
}

.subscribe-from .input input::placeholder {
	font-size: 15px;
	line-height: 1;
	color: var(--white);
	opacity: 1
}

.subscribe-from .input i {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 20px;
	font-size: 24px;
	background: var(--gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.subscribe-from button {
	height: 60px;
	width: 140px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--subscribe-button-font-color);
	font-size: 17px;
	font-weight: 500;
	background: var(--gradient);
	border-radius: 5px;
	margin-left: 10px
}

@media screen and (max-width: 991px) {
	.subscribe-area {
		margin-top: 35px
	}
	.subscribe-wrapper {
		justify-content: center;
		flex-direction: column;
		text-align: center
	}
	.subscribe-from {
		width: 100%;
		justify-content: center;
		margin-top: 20px
	}
	.subscribe-content {
		width: 100%
	}
}

@media screen and (max-width: 575px) {
	.subscribe-area {
		margin-top: 25px
	}
	.subscribe-wrapper {
		padding: 20px 10px
	}
	.subscribe-from {
		flex-direction: column;
		max-width: 400px
	}
	.subscribe-from .input input {
		width: 100%;
		height: 55px;
		font-size: 15px
	}
	.subscribe-from button {
		height: 55px;
		margin: 15px auto 0
	}
}

.short-spacing {
	padding: 50px 0px
}

.footer-area {
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat
}

.footer-area::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: var(--footer-overlay);
	opacity: 0.9
}

.footer-shape {
	position: absolute;
	bottom: 0;
	right: 0;
	max-width: 500px
}

.footer-wrapper {
	padding: 70px 10px;
	text-align: center;
	position: relative;
	z-index: 9
}

.footer-content {
	max-width: 490px;
	margin: 0 auto
}

.footer-content p {
	color: var(--white);
	font-size: 16px;
	line-height: 24px;
	padding: 15px 0px 25px 0px;
	margin-bottom: 0
}

.footer-content .footer-social a {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 0px 5px;
	border: 1px solid var(--success);
	color: var(--white);
	font-size: 14px;
	position: relative;
	transition: all .3s ease-in-out
}

.footer-content .footer-social a::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: var(--gradient);
	opacity: 0;
	border-radius: 50%;
	z-index: -1;
	transition: all .3s ease-in-out
}

.footer-content .footer-social a:hover:before {
	opacity: 1
}

.footer-content .footer-social a:hover {
	border: transparent
}

.footer-content .see-location {
	color: var(--white);
	line-height: 24px;
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: center
}

.footer-content .see-location i {
	font-size: 18px;
	padding-left: 10px
}

.footer-menu {
	padding-top: 50px;
	max-width: 810px;
	margin: 0 auto
}

.footer-menu ul li {
	display: inline-block
}

.footer-menu ul li a {
	display: block;
	color: var(--white);
	margin: 0px 15px;
	font-size: 17px;
	font-weight: 500;
	transition: all .3s ease-in-out
}

.footer-menu ul li a:hover {
	color: var(--success)
}

.copy-right-area {
	background: var(--footer-overlay);
	padding: 20px 0px
}

.copyright-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center
}

.copyright-wrapper p {
	color: var(--white);
	font-size: 16px;
	line-height: 26px;
	margin-bottom: 0
}

@media screen and (max-width: 991px) {
	.short-spacing {
		padding: 35px 0px
	}
}

@media screen and (max-width: 767px) {
	.footer-wrapper {
		padding: 50px 0px
	}
	.footer-menu ul li a {
		margin: 0px 10px 10px
	}
	.copyright-wrapper {
		justify-content: center;
		flex-wrap: wrap
	}
	.copyright-wrapper p {
		margin: 0px 10px 20px
	}
	.copyright-wrapper p:last-child {
		margin-bottom: 0
	}
}

@media screen and (max-width: 575px) {
	.short-spacing {
		padding: 25px 0px
	}
	.footer-wrapper {
		padding: 30px 0px
	}
	.footer-content p {
		padding: 10px 0px 15px 0px
	}
	.footer-content .see-location {
		margin-top: 20px
	}
	.footer-menu {
		padding-top: 30px
	}
	.footer-menu ul li a {
		font-size: 16px
	}
	.copyright-wrapper {
		text-align: center
	}
	.copyright-wrapper p {
		margin: 0px 10px 10px
	}
}

.innerpage-header-sec {
	background: url("../images/header-curve-bg.svg") no-repeat scroll left bottom/cover;
	padding-bottom: 200px
}

.header-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap
}

.header-top p {
	margin-bottom: 0
}

.header-top p i {
	font-size: 20px;
	line-height: 1;
	color: var(--primary)
}

.header-top>* {
	padding: 5px 0
}

.header-top.text-white .lang-text {
	color: var(--white-permanent)
}

.header-top.text-white .lang-text::before {
	border-color: var(--white-permanent)
}

.header-top.text-white .lang-item input:checked+span {
	color: var(--success)
}

.header-top.text-white .lang-item span {
	color: var(--white-permanent)
}

.header-top.text-white p {
	color: var(--white-permanent)
}

.h-lang {
	display: flex;
	align-items: center
}

.lang-item {
	margin-right: 10px
}

.lang-item input {
	display: none
}

.lang-item input:checked+span {
	color: var(--primary)
}

.lang-item span {
	display: inline-block;
	cursor: pointer;
	padding: 2px
}

.lang-item:last-child {
	margin-right: 0
}

.header-one .ch-header {
	background: var(--white-permanent);
	padding-right: 20px;
	padding-left: 20px
}

.lang-text {
	position: relative;
	padding-right: 10px;
	margin-right: 10px
}

.lang-text::before {
	content: "";
	position: absolute;
	right: 0;
	top: 4px;
	height: 16px;
	border-right: 1px solid var(--paragraph)
}

.lang-item input:checked+span {
	color: var(--primary)
}

.lang-item span {
	color: var(--paragraph)
}

.h-menu-wrap .h-menu {
	display: flex;
	flex-wrap: wrap
}

.h-menu-wrap .h-menu li {
	margin-right: 19px;
	position: relative
}

.h-menu-wrap .h-menu li.has-child a {
	position: relative
}

.h-menu-wrap .h-menu li.has-child i {
	position: absolute;
	top: calc(50% + 25px);
	left: 50%;
	color: var(--menu-color);
	transform: translateX(-50%);
	opacity: 0;
	transition: .4s ease-in
}

.h-menu-wrap .h-menu li.has-child ul {
	position: absolute;
	z-index: 555;
	width: 200px;
	background: var(--white);
	top: 100%;
	left: 0;
	padding: 5px 0;
	box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05);
	transform: translateY(30px);
	transition: all .3s ease-in;
	opacity: 0;
	visibility: hidden
}

.h-menu-wrap .h-menu li.has-child ul li {
	margin-right: 0
}

.h-menu-wrap .h-menu li.has-child ul li a {
	padding: 8px 15px;
	display: block;
	color: var(--menu-color);
	border-bottom: 1px dashed var(--menu-color)
}

.h-menu-wrap .h-menu li.has-child ul li ul {
	left: 100%;
	top: 0
}

.h-menu-wrap .h-menu li.has-child ul li:last-child a {
	border-bottom: 0
}

.h-menu-wrap .h-menu li.has-child ul li:hover>a {
	color: var(--primary);
	border-color: var(--menu-color)
}

.h-menu-wrap .h-menu li.has-child ul li:hover i {
	color: var(--primary)
}

.h-menu-wrap .h-menu li.has-child ul li i {
	right: 7px;
	transform: rotate(90deg) translate(0);
	top: 12px;
	color: var(--menu-color);
	line-height: 1;
	left: unset
}

.h-menu-wrap .h-menu li.has-megamenu {
	position: initial
}

.h-menu-wrap .h-menu li.has-megamenu i {
	position: absolute;
	top: calc(50% + 25px);
	left: 50%;
	color: var(--menu-color);
	transform: translateX(-50%);
	opacity: 0;
	transition: .4s ease-in
}

.h-menu-wrap .h-menu li.has-megamenu>i {
	display: none
}

.h-menu-wrap .h-menu li:last-child {
	margin-right: 0
}

.h-menu-wrap .h-menu li a {
	color: var(--menu-color);
	padding: 5px;
	display: block;
	position: relative
}

.h-menu-wrap .h-menu li a:hover,
.h-menu-wrap .h-menu li a.active {
	color: var(--primary)
}

.h-menu-wrap .h-menu li a.active {
	font-weight: 600
}

.h-menu-wrap .h-menu li:hover>ul {
	transform: translateY(0);
	visibility: visible;
	opacity: 1
}

.h-menu-wrap .h-menu li:hover .megamenu {
	opacity: 1;
	visibility: visible
}

.h-menu-wrap .h-menu li.new,
.h-menu-wrap .h-menu li.s-code {
	position: relative
}

.h-menu-wrap .h-menu li.new:after,
.h-menu-wrap .h-menu li.s-code:after {
	position: absolute;
	content: "New";
	top: 10px;
	right: 3px;
	padding: 2px 6px;
	font-size: 12px;
	color: var(--white);
	background: var(--primary-gradient);
	line-height: 1
}

.h-menu-wrap .h-menu li.s-code:after {
	position: absolute;
	right: 0
}

.h-menu-wrap .h-menu>li>a {
	padding-top: 30px;
	padding-bottom: 30px
}

.h-menu-wrap .h-menu>li:hover>a {
	background: transparent;
	color: var(--primary)
}

.h-menu-wrap .h-menu>li:hover i {
	color: var(--primary);
	top: calc(50% + 15px);
	opacity: 1
}

.h-menu-wrap .h-menu .megamenu {
	width: 100%;
	position: absolute;
	top: 100%;
	left: 0;
	background: var(--light-gray);
	padding: 50px;
	transition: .4s;
	opacity: 0;
	visibility: hidden;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px
}

.h-menu-wrap .h-menu .megamenu .mm-row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -20px;
	margin-right: -20px
}

.h-menu-wrap .h-menu .megamenu .mm-col {
	padding: 20px;
	width: 25%;
	position: relative
}

.h-menu-wrap .h-menu .megamenu .mm-col-inner {
	max-width: 220px;
	margin-left: auto;
	margin-right: auto
}

.h-menu-wrap .h-menu .megamenu .mm-widget-title {
	position: relative;
	margin-bottom: 20px;
	padding-bottom: 15px
}

.h-menu-wrap .h-menu .megamenu .mm-widget-title::before {
	content: "";
	position: absolute;
	border-bottom: 2px solid var(--primary);
	width: 20px;
	bottom: 0
}

.h-menu-wrap .h-menu .megamenu .mm-menu li {
	margin: 0 0 12px
}

.h-menu-wrap .h-menu .megamenu .mm-menu li a {
	padding: 0 0 12px;
	border-bottom: 1px dashed var(--menu-color)
}

.h-menu-wrap .h-menu .megamenu .mm-menu li:last-child {
	margin-bottom: 0
}

.h-menu-wrap .h-menu .megamenu .mm-menu li:last-child a {
	border-bottom: 0;
	padding-bottom: 0
}

.rounded-10 .megamenu {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px
}

.header-light .h-menu-wrap .h-menu li a {
	color: var(--white-permanent)
}

.header-light .h-menu-wrap .h-menu>li:hover>a {
	color: var(--primary)
}

.header-light .h-menu-wrap .h-menu .mm-menu li a {
	color: var(--menu-color)
}

.header-light .h-menu-wrap .h-menu .mm-menu li a:hover {
	color: var(--primary)
}

.header-light .menu-trigger span {
	background-color: var(--white-permanent)
}

.ch-header-sec {
	position: relative;
	top: 0;
	z-index: 555
}

.ch-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative
}

.ch-header-special {
	align-items: unset
}

.ch-header-special .h-logo {
	display: flex;
	align-items: center;
	padding: 10px 30px 10px 17px;
	clip-path: polygon(0 0, 100% 0%, calc(100% - 30px) 100%, 0% 100%);
	background: var(--light-gray);
	max-width: 230px
}

.header-menu {
	display: flex;
	flex-wrap: wrap
}

.header-menu>li>a::before {
	content: "\eab2";
	opacity: 0;
	position: absolute;
	font-family: IcoFont;
	bottom: 5px;
	left: 50%;
	transform: translateX(-50%);
	line-height: 1;
	transition: all .3s ease-in
}

.header-menu>li.active>a::before {
	opacity: 1;
	bottom: -5px
}

.header-menu li {
	margin-right: 10px
}

.header-menu li:last-child {
	margin-right: 0
}

.header-menu li a {
	padding: 10px;
	display: block;
	color: var(--menu-color);
	position: relative
}

.header-menu li a:hover {
	color: var(--primary)
}

.header-menu li.active a {
	color: var(--primary)
}

.header-right {
	display: flex;
	align-items: center
}

.header-menu-wrap {
	margin-right: 40px
}

.h-search {
	margin-right: 25px;
	font-size: 20px;
	color: var(--menu-color)
}

.h-search i {
	cursor: pointer
}

@media all and (max-width: 1199px) {
	body {
		transition: .3s;
		left: 0;
		position: relative
	}
	body.active-mobile-menu {
		position: relative;
		left: 250px;
		overflow-x: hidden !important;
		transition: .3s
	}
}

.mobile-menu-wrap {
	position: fixed;
	padding: 15px;
	background: var(--light-gray);
	height: 100vh;
	width: 280px;
	left: -280px;
	top: 0;
	z-index: 999;
	transition: .3s;
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden
}

.mobile-menu-wrap.active-menu {
	left: 0;
	transition: .3s
}

.menu-close {
	text-align: right;
	font-size: 26px;
	line-height: 1
}

.menu-trigger {
	cursor: pointer
}

.menu-trigger span {
	display: block;
	height: 2px;
	width: 25px;
	background-color: var(--menu-color);
	margin: 5px 0;
	transition: 0.25s ease-in
}

.menu-trigger.active {
	transition: 0.25s ease-in;
	cursor: pointer
}

.menu-trigger.active span:first-child {
	transform: rotate(-45deg) translate(-4px, 6px);
	transition: 0.3s ease-in
}

.menu-trigger.active span:nth-child(2) {
	opacity: 0
}

.menu-trigger.active span:last-child {
	transform: rotate(45deg) translate(-4px, -6px);
	transition: 0.3s ease-in
}

.mobile-menu.menu-activated {
	right: 0
}

.mobile-menu .h-menu>li {
	margin-right: 0
}

.mobile-menu .h-menu li {
	margin-bottom: 0;
	position: relative
}

.mobile-menu .h-menu li i {
	position: absolute;
	right: -10px;
	top: 3px;
	padding: 10px;
	cursor: pointer
}

.mobile-menu .h-menu li>i {
	transform: rotate(180deg)
}

.mobile-menu .h-menu li a {
	padding: 10px 10px 10px 0;
	display: inline-block;
	color: var(--menu-color)
}

.mobile-menu .h-menu li a:hover {
	color: var(--primary)
}

.mobile-menu .h-menu li ul {
	display: none;
	padding-left: 10px
}

.mobile-menu .h-menu li ul.mm-menu {
	display: block
}

.mobile-menu .h-menu li.active>i {
	transform: rotate(0)
}

.mobile-menu .h-menu li.has-megamenu i {
	display: none
}

.mobile-menu .h-menu li.has-megamenu>i {
	display: block
}

.mobile-menu .h-menu li.new,
.mobile-menu .h-menu li.s-code {
	position: relative
}

.mobile-menu .h-menu li.new:after,
.mobile-menu .h-menu li.s-code:after {
	position: absolute;
	content: "New";
	top: 10px;
	right: 3px;
	padding: 2px 6px;
	font-size: 12px;
	color: var(--white);
	background: var(--primary-gradient);
	line-height: 1
}

.mobile-menu .h-menu li.s-code:after {
	position: absolute;
	right: 0
}

.mobile-menu .megamenu {
	display: none
}

.mobile-menu .mm-menu {
	margin-bottom: 10px
}

.mobile-menu .mm-menu li a {
	padding: 6px 0
}

.mobile-menu .mm-widget-title {
	font-size: 18px;
	margin-bottom: 6px;
	text-transform: uppercase
}

.livesearch-sec {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	padding: 50px 15px;
	width: 100%
}

.livesearch-wrap {
	position: relative
}

.livesearch-wrap input {
	background: var(--light-gray);
	width: 100%;
	border: 0;
	padding: 12px 20px 12px 55px;
	height: 60px
}

.livesearch-wrap input::placeholder {
	color: var(--heading);
	opacity: 1
}

.livesearch-wrap button[type="submit"] {
	background: transparent;
	border: 0;
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
	padding: 10px 10px 10px 18px;
	color: var(--heading)
}

.livesearch-wrap button[type="submit"] svg {
	color: var(--heading);
	stroke: var(--heading)
}

.modal-content {
	background: var(--body-bg)
}

.searchmodal-close {
	padding-top: 40px;
	padding-right: 30px;
	text-align: right;
	display: flex;
	justify-content: flex-end;
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
	width: 100%
}

.searchmodal-close i {
	height: 50px;
	width: 50px;
	border: 1px solid var(--paragraph);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 30px;
	color: var(--heading)
}

.founded-articles {
	margin-top: 40px
}

.founded-articles-title {
	margin-bottom: 25px
}

.founded-articles-title span {
	display: inline-block;
	padding: 5px 10px;
	background: var(--primary);
	color: var(--white-permanent);
	margin-right: 7px
}

.fa-item {
	position: relative;
	display: block;
	padding: 20px;
	transition: .3s
}

.fa-item h4 {
	margin-bottom: 10px
}

.fa-item h4 span {
	font-size: 16px;
	color: var(--paragraph);
	font-weight: 500;
	margin-left: 45px;
	position: relative;
	transition: .3s
}

.fa-item h4 span::before {
	position: absolute;
	content: "";
	left: -40px;
	top: 8px;
	width: 30px;
	height: 2px;
	border-radius: unset;
	transform: translateY(50%);
	background-color: var(--primary)
}

.fa-item h4 a {
	color: var(--heading)
}

.fa-item p {
	color: var(--paragraph)
}

.fa-item:hover {
	background: var(--light-gray)
}

.fa-item:hover h4 span {
	color: var(--heading)
}

.ch-preloader-sec {
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--black);
	display: flex;
	align-items: center;
	justify-content: center
}

#ch-preloader {
	width: 70px;
	position: relative;
	cursor: pointer;
	height: 60px
}

#ch-preloader p {
	color: #fff;
	position: absolute;
	left: -3px;
	top: 100%;
	font-family: Arial;
	text-align: center;
	font-size: 10px
}

#ch-preloader .mask {
	position: absolute;
	border-radius: 2px;
	overflow: hidden;
	perspective: 1000;
	backface-visibility: hidden
}

#ch-preloader .plane {
	background: #ffffff;
	width: 400%;
	height: 100%;
	position: absolute;
	transform: translate3d(0px, 0, 0);
	z-index: 100;
	perspective: 1000;
	backface-visibility: hidden
}

#ch-preloader .animation {
	transition: all 0.3s ease
}

#chp-top {
	width: 53px;
	height: 20px;
	left: 20px;
	transform: skew(-15deg, 0);
	z-index: 100
}

#chp-top .plane {
	z-index: 2000;
	animation: trans1 1.3s ease-in infinite 0s backwards
}

#chp-middle {
	width: 33px;
	height: 20px;
	left: 20px;
	top: 15px;
	transform: skew(-15deg, 40deg)
}

#chp-middle .plane {
	transform: translate3d(0px, 0, 0);
	background: #bbbbbb;
	animation: trans2 1.3s linear infinite 0.3s backwards
}

#chp-bottom {
	width: 53px;
	height: 20px;
	top: 30px;
	transform: skew(-15deg, 0)
}

#chp-bottom .plane {
	z-index: 2000;
	animation: trans3 1.3s ease-out infinite 0.7s backwards
}

@keyframes trans1 {
	from {
		transform: translate3d(53px, 0, 0)
	}
	to {
		transform: translate3d(-250px, 0, 0)
	}
}

@keyframes trans2 {
	from {
		transform: translate3d(-160px, 0, 0)
	}
	to {
		transform: translate3d(53px, 0, 0)
	}
}

@keyframes trans3 {
	from {
		transform: translate3d(53px, 0, 0)
	}
	to {
		transform: translate3d(-220px, 0, 0)
	}
}

@keyframes animColor {
	from {
		background: red
	}
	25% {
		background: yellow
	}
	50% {
		background: green
	}
	75% {
		background: brown
	}
	to {
		background: blue
	}
}

.copyright-sec,
.footer-sec {
	position: relative
}

.copyright-sec.bg-primary,
.footer-sec.bg-primary {
	background: var(--primary-gradient2) !important
}

.copyright-sec.bg-secondary,
.footer-sec.bg-secondary {
	background: var(--secondary-gradient) !important
}

.copyright-sec.bg-success,
.footer-sec.bg-success {
	background: var(--success) !important
}

.copyright-sec.bg-gray,
.footer-sec.bg-gray {
	background: var(--light-gray) !important
}

.copyright-sec.bg-light,
.copyright-sec.bg-white,
.footer-sec.bg-light,
.footer-sec.bg-white {
	background: var(--light-gray) !important
}

.copyright-sec.bg-light p,
.copyright-sec.bg-white p,
.footer-sec.bg-light p,
.footer-sec.bg-white p {
	color: var(--paragraph)
}

.copyright-sec.bg-light .footer-widget-title,
.copyright-sec.bg-white .footer-widget-title,
.footer-sec.bg-light .footer-widget-title,
.footer-sec.bg-white .footer-widget-title {
	color: var(--heading)
}

.copyright-sec.bg-light .footer-widget-menu li a,
.copyright-sec.bg-white .footer-widget-menu li a,
.footer-sec.bg-light .footer-widget-menu li a,
.footer-sec.bg-white .footer-widget-menu li a {
	color: var(--heading)
}

.copyright-sec.bg-light .footer-widget-menu li a:hover,
.copyright-sec.bg-white .footer-widget-menu li a:hover,
.footer-sec.bg-light .footer-widget-menu li a:hover,
.footer-sec.bg-white .footer-widget-menu li a:hover {
	color: var(--primary)
}

.copyright-sec.bg-light .footer-widget .social-icons a,
.copyright-sec.bg-white .footer-widget .social-icons a,
.footer-sec.bg-light .footer-widget .social-icons a,
.footer-sec.bg-white .footer-widget .social-icons a {
	border-color: var(--paragraph);
	color: var(--paragraph)
}

.copyright-sec.bg-light .footer-widget .social-icons a:hover,
.copyright-sec.bg-white .footer-widget .social-icons a:hover,
.footer-sec.bg-light .footer-widget .social-icons a:hover,
.footer-sec.bg-white .footer-widget .social-icons a:hover {
	border-color: var(--primary);
	color: var(--white-permanent)
}

.copyright-sec.bg-light .footer-widget .social-icons a:hover::before,
.copyright-sec.bg-white .footer-widget .social-icons a:hover::before,
.footer-sec.bg-light .footer-widget .social-icons a:hover::before,
.footer-sec.bg-white .footer-widget .social-icons a:hover::before {
	background-color: var(--primary)
}

.copyright-sec.bg-light .copyright-inner .cr-single-menu,
.copyright-sec.bg-white .copyright-inner .cr-single-menu,
.footer-sec.bg-light .copyright-inner .cr-single-menu,
.footer-sec.bg-white .copyright-inner .cr-single-menu {
	color: var(--paragraph)
}

.copyright-sec.bg-light .copyright-inner .cr-single-menu:hover,
.copyright-sec.bg-white .copyright-inner .cr-single-menu:hover,
.footer-sec.bg-light .copyright-inner .cr-single-menu:hover,
.footer-sec.bg-white .copyright-inner .cr-single-menu:hover {
	color: var(--primary)
}

.copyright-sec.bg-white,
.footer-sec.bg-white {
	background-color: var(--white) !important
}

.copyright-sec.footer-curve-bg-light,
.footer-sec.footer-curve-bg-light {
	background: url("../images/footer-curve-bg.png") no-repeat scroll left top/cover !important;
	padding-top: 200px
}

.copyright-sec.footer-curve-bg-primary,
.footer-sec.footer-curve-bg-primary {
	background: url("../images/footer-curve-bg-primary.png") no-repeat scroll left top/cover !important;
	padding-top: 200px
}

.copyright-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 0
}

.copyright-inner>* {
	padding: 4px 0
}

.copyright-inner p {
	margin: 0;
	color: var(--white-permanent)
}

.copyright-inner .cr-single-menu {
	display: inline-block;
	font-size: 18px;
	color: var(--white-permanent)
}

.footer-logo,
.h-logo {
	color: var(--heading);
	font-size: 26px;
	font-weight: 700;
	display: inline-block;
	line-height: 1;
	max-width: 210px
}

.footer-logo svg,
.h-logo svg {
	color: var(--heading)
}

.footer-widget {
	position: relative
}

.footer-widget .social-icons a {
	border-color: var(--white-permanent);
	color: var(--white-permanent)
}

.footer-widget .social-icons a:hover {
	color: var(--white-permanent);
	border-color: var(--primary)
}

.footer-widget .social-icons a:hover::before {
	background: var(--primary)
}

.footer-widget p {
	color: var(--white-permanent);
	font-size: 17px
}

.footer-widget .footer-widget-title {
	color: var(--white-permanent);
	font-weight: 600
}

.eapps-instagram-feed {
	margin-bottom: -50px !important
}

.eapps-link {
	clip-path: inset(0 0 100% 0)
}

.newsletter-widget {
	position: relative;
	border-radius: 4px;
	overflow: hidden
}

.newsletter-widget input[type="email"] {
	height: 50px;
	border: 1px solid var(--primary);
	padding: 5px 110px 5px 20px;
	width: 100%;
	border-radius: 4px;
	background: var(--white)
}

.newsletter-widget input[type="submit"],
.newsletter-widget button[type="submit"] {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 20px;
	background-color: var(--primary);
	color: var(--white-permanent);
	border: 0;
	font-size: 18px;
	min-width: 100px
}

.footer-widget-menu li {
	margin-bottom: 15px
}

.footer-widget-menu li:last-child {
	margin-bottom: 0
}

.footer-widget-menu li a {
	color: var(--white-permanent);
	font-size: 18px;
	display: inline-block;
	font-weight: 400
}

.footer-widget-menu li a:hover {
	opacity: .7
}

.footer-widget-menu.column-2 {
	column-count: 2
}

.footer-widget-menu.column-3 {
	column-count: 3
}

.footer-widget-menu.column-4 {
	column-count: 4
}

.footer-widget-menu.menu-inline li {
	margin-right: 20px;
	display: inline-block
}

.footer-widget-menu.menu-inline li:last-child {
	margin-right: 0
}

.footer-bitcoin-img-one {
	position: absolute;
	bottom: -20px;
	left: 2%;
	max-width: 170px
}

.footer-bitcoin-img-two {
	position: absolute;
	bottom: 0px;
	right: 0;
	max-width: 300px
}

.ch-banner-sec {
	background: url("../images/bg-1.svg") no-repeat scroll bottom/cover;
	padding-bottom: 200px
}

.hero-banner-sec {
	position: relative
}

.hero-banner-sec::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 70%;
	max-width: 700px;
	right: 0;
	bottom: 0;
	background: url("../images/circuit-diagram.svg") no-repeat scroll right bottom/contain
}

.hero-banner-text {
	position: relative
}

.hero-banner-text h2 {
	font-size: 45px;
	color: var(--white-permanent);
	margin-bottom: 0
}

.hero-banner-text h1 {
	font-size: 60px;
	font-weight: 800;
	color: var(--white-permanent)
}

.hero-banner-text h1 span {
	font-size: 45px;
	font-weight: 500
}

.hero-banner-text p {
	font-size: 20px;
	font-weight: 500;
	color: var(--white-permanent)
}

.hero-banner-text .btn {
	font-size: 18px;
	padding: 10px 30px
}

.token-info-table.table-borderless tr {
	/* border-bottom: 10px solid var(--white) */
}

.token-info-table.table-borderless td {
	/* background: var(--light-gray) */
}

.token-info-table.table-borderless td:first-child {
	padding-left: 15px
}

.token-info-table td {
	/* border-color: var(--border); */
	/* font-size: 18px;
	/* color: var(--paragraph); */
	padding: 12px 15px */
}

.token-info-table td:first-child {
	font-weight: 600;
	color: var(--heading)
}

.countdown-sec {
	position: relative;
	padding-top: 400px;
	padding-bottom: 100px;
	background: url("../images/curve-bg.png") no-repeat scroll center/cover
}

.countdown-item .ci-inner {
	height: 175px;
	width: 175px;
	border-radius: 50%;
	margin-left: auto;
	margin-right: auto;
	border: 1px dashed var(--white-permanent);
	padding: 20px
}

.countdown-item .ci-value {
	border: 1px solid var(--white-permanent);
	background: rgba(var(--white-alpha), 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	border-radius: 50%;
	font-weight: 700;
	color: var(--white-permanent);
	font-size: 60px;
	line-height: 1;
	text-align: center
}

.countdown-item h2,
.countdown-item h3,
.countdown-item h4,
.countdown-item h5 {
	text-align: center;
	color: var(--white-permanent);
	margin-top: 14px;
	margin-bottom: 0
}

.countdown-lagency .countdown-item .ci-inner {
	height: auto;
	width: auto;
	border: 1px solid var(--success);
	border-radius: 10px;
	padding: 0
}

.countdown-lagency .countdown-item .ci-value {
	border: 0;
	background: transparent;
	border-bottom: 1px solid var(--success);
	border-radius: 0;
	padding: 20px 5px;
	background: var(--success-gradient);
	-moz-background-clip: text;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent
}

.countdown-lagency .countdown-item h1,
.countdown-lagency .countdown-item h2,
.countdown-lagency .countdown-item h3,
.countdown-lagency .countdown-item h4,
.countdown-lagency .countdown-item h5,
.countdown-lagency .countdown-item h6 {
	margin: 0;
	padding: 5px
}

.call-to-action {
	background: var(--white-permanent);
	padding: 30px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07)
}

.call-to-action p {
	margin-bottom: 0
}

.call-to-action .cta-left {
	flex: 1;
	margin-right: 15px
}

.roadmap-wrap {
	position: relative
}

.roadmap-wrap .roadmap-img {
	position: absolute;
	width: 35%;
	max-width: 400px;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%)
}

.roadmap {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between
}

.roadmap li {
	width: 50%;
	display: flex;
	margin-bottom: 50px
}

.roadmap li:nth-child(3),
.roadmap li:nth-child(5) {
	justify-content: flex-end
}

.roadmap li:nth-child(1),
.roadmap li:nth-child(6) {
	width: 100%;
	justify-content: center
}

.roadmap li:first-child,
.roadmap li:last-child {
	margin-bottom: 0
}

.roadmap li:last-child {
	margin-top: -50px
}

.roadmap-item {
	background: var(--white);
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
	padding: 25px;
	border-radius: 20px;
	max-width: 320px;
	margin-bottom: 30px;
	position: relative
}

.roadmap-item .date {
	color: var(--paragraph);
	font-size: 17px;
	display: block;
	margin-bottom: 8px
}

.roadmap-item h2,
.roadmap-item h3,
.roadmap-item h4,
.roadmap-item h5 {
	margin-bottom: 10px
}

.roadmap-item p:last-child {
	margin-bottom: 0
}

.counter-sec {
	background: url("../images/curve-bg2.png") no-repeat scroll center/cover;
	position: relative;
	padding-top: 100px;
	padding-bottom: 200px
}

.time-left {
	position: relative
}

.time-left::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 50%;
	max-width: 320px;
	background: url("../images/net.svg") no-repeat scroll left top/contain;
	left: 0;
	top: 0
}

.time-left::after {
	content: "";
	position: absolute;
	height: 100%;
	width: 90%;
	max-width: 350px;
	background: url("../images/circuit-diagram.svg") no-repeat scroll right bottom/contain;
	right: 0;
	bottom: 0
}

.counter-wrap:not(.counter-secondary-wrap) {
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
	border-radius: 95px;
	padding: 30px 10px;
	background: var(--white)
}

.counter-wrap div[class^="col"] {
	position: relative
}

.counter-wrap div[class^="col"]::before {
	content: "";
	position: absolute;
	height: 60px;
	border-right: 1px solid var(--border);
	top: 50%;
	transform: translateY(-50%);
	right: 0
}

.counter-wrap div[class^="col"]:last-child::before {
	content: none
}

.counter-item h1,
.counter-item h2,
.counter-item h3,
.counter-item h4 {
	color: transparent;
	background: var(--primary-gradient2);
	-moz-background-clip: text;
	-webkit-background-clip: text;
	background-clip: text;
	font-weight: 700;
	display: inline-block;
	margin-bottom: 10px
}

.counter-item p {
	font-weight: 500;
	font-size: 22px;
	color: var(--heading);
	margin-bottom: 0
}

.counter-secondary-wrap .counter-item {
	background: var(--white-permanent);
	border-radius: 10px;
	padding: 20px
}

.counter-secondary-wrap .counter-item h1,
.counter-secondary-wrap .counter-item h2,
.counter-secondary-wrap .counter-item h3,
.counter-secondary-wrap .counter-item h4,
.counter-secondary-wrap .counter-item h5 {
	background: var(--secondary-gradient);
	-moz-background-clip: text;
	-webkit-background-clip: text;
	background-clip: text
}

.counter-secondary-wrap .counter-item p {
	color: var(--secondary)
}

.home2-team-sec {
	position: relative
}

.home2-team-sec::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 1;
	background: url("../images/circuit.svg") no-repeat scroll bottom/contain;
	bottom: 0
}

.team-member {
	position: relative
}

.team-member:hover .social-icons a {
	opacity: 1;
	transform: translateY(0)
}

.team-member:hover .team-img {
	border-color: transparent
}

.team-member:hover .team-img::before {
	transform: scale(1);
	opacity: 1
}

.team-member .team-img {
	padding: 25px;
	border-radius: 50%;
	height: 250px;
	width: 250px;
	margin-left: auto;
	margin-right: auto;
	border: 2px solid var(--paragraph);
	transition: border .6s ease-out;
	margin-bottom: 25px;
	position: relative
}

.team-member .team-img::before {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	border-radius: 50%;
	border: 2px solid var(--primary);
	left: 0;
	top: 0;
	transform: scale(0.8);
	transition: .4s ease-in;
	opacity: 0
}

.team-member .team-img-inner {
	border-radius: 50%;
	overflow: hidden;
	height: 196px;
	width: 196px
}

.team-member .team-img-inner img {
	width: 100%;
	height: 100%
}

.team-member h2,
.team-member h3,
.team-member h4 {
	margin-bottom: 5px
}

.team-member h2 a,
.team-member h3 a,
.team-member h4 a {
	color: var(--heading)
}

.team-member h2 a:hover,
.team-member h3 a:hover,
.team-member h4 a:hover {
	color: transparent;
	background: var(--primary-gradient2);
	-moz-background-clip: text;
	-webkit-background-clip: text;
	background-clip: text
}

.team-member .social-icons {
	position: absolute;
	bottom: 30%;
	display: block;
	text-align: center;
	width: 100%;
	left: 0
}

.team-member .social-icons a {
	border-color: var(--paragraph);
	color: var(--white);
	transform: translateY(20px);
	opacity: 0;
	transition: .3s;
	position: relative
}

.team-member .social-icons a:nth-child(2) {
	transition-delay: .1s
}

.team-member .social-icons a:nth-child(3) {
	transition-delay: .2s
}

.team-member .social-icons a::before {
	background: var(--paragraph);
	height: 100%
}

.team-member .social-icons a:hover {
	border-color: transparent
}

.team-member .social-icons a:hover::before {
	background: var(--primary-gradient2)
}

.member-card {
	position: relative;
	padding-top: 110%;
	overflow: hidden
}

.member-card::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background: linear-gradient(180deg, transparent 0%, rgba(var(--secondary-alpha), 0.7) 100%);
	z-index: 3
}

.member-card:hover .social-icons {
	opacity: 1
}

.member-card:hover .social-icons a {
	transform: translateX(0);
	opacity: 1
}

.member-card:hover .social-icons a:nth-child(2) {
	transition-delay: .2s
}

.member-card:hover .social-icons a:nth-child(3) {
	transition-delay: .3s
}

.member-card:hover .social-icons a:nth-child(4) {
	transition-delay: .6s
}

.member-card:hover .mc-img {
	transform: scale(1.1)
}

.member-card .mc-img {
	position: absolute;
	height: 100%;
	width: 100%;
	object-fit: cover;
	top: 0;
	left: 0;
	z-index: 2;
	transition: .5s ease-in
}

.member-card .mc-text {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 4;
	padding: 24px
}

.member-card h3,
.member-card h4,
.member-card h5 {
	color: var(--white-permanent);
	margin-bottom: 5px
}

.member-card h3 a,
.member-card h4 a,
.member-card h5 a {
	color: var(--white-permanent)
}

.member-card h3 a:hover,
.member-card h4 a:hover,
.member-card h5 a:hover {
	color: transparent;
	background: var(--primary-gradient2);
	background-clip: text;
	-moz-background-clip: text;
	-webkit-background-clip: text
}

.member-card p {
	margin-bottom: 0;
	color: var(--white-permanent)
}

.member-card .social-icons {
	position: absolute;
	top: 0;
	z-index: 5;
	width: 60px;
	background: linear-gradient(var(--primary) 0%, transparent 100%);
	padding: 30px 12px;
	right: 0;
	transition: .4s;
	opacity: 0
}

.member-card .social-icons a {
	font-size: 20px;
	margin-right: 0;
	margin-bottom: 5px;
	color: var(--white-permanent);
	border-color: transparent;
	transform: translateX(15px);
	position: relative;
	opacity: 0;
	transition: .2s
}

.member-card .social-icons a:hover {
	color: var(--primary)
}

.member-card .social-icons a:hover::before {
	background: var(--white-permanent)
}

.video-cilp-wrapper {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	position: relative;
	margin-bottom: -70px
}

.video-clip {
	position: relative;
	padding-top: 55%;
	border-radius: 20px;
	overflow: hidden
}

.video-clip .video-poster {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
	z-index: -1
}

.video-clip .play-icon {
	position: absolute;
	z-index: 6;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	cursor: pointer;
	max-width: 55px
}

.home1-block-wrap {
	padding-top: 120px;
	padding-bottom: 20px
}

.article-lists {
	padding-left: 100px;
	position: relative
}

.article-lists .article-item {
	counter-increment: articlenumber
}

.article-lists .article-item::before {
	content: counters(articlenumber, ".", decimal-leading-zero);
	position: absolute;
	right: calc(100% + 20px);
	color: var(--black);
	font-size: 70px;
	font-weight: 800;
	opacity: .05;
	line-height: 1;
	top: 50%;
	transform: translateY(-50%)
}

.article-item {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	padding: 20px;
	border-radius: 10px;
	margin-bottom: 30px;
	transition: all .4s ease-in
}

.article-item .readmore {
	font-size: 18px;
	color: var(--paragraph)
}

.article-item .ai-img {
	width: 30%;
	max-width: 270px;
	margin-right: 25px;
	position: relative;
	display: block
}

.article-item .ai-img .ai-img-inner {
	position: relative;
	display: block;
	padding-top: 90%;
	border-radius: 10px;
	overflow: hidden
}

.article-item .ai-img img {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	object-fit: cover;
	transition: .4s ease-in
}

.article-item .ai-meta span {
	position: relative;
	display: inline-block;
	font-size: 16px;
	color: var(--paragraph);
	padding-right: 40px;
	margin-bottom: 8px;
	transition: all .3s
}

.article-item .ai-meta span::before {
	position: absolute;
	content: "";
	height: 8px;
	width: 8px;
	border-radius: 50%;
	background: var(--paragraph);
	display: inline-block;
	right: 12px;
	top: 7px;
	transition: all .3s
}

.article-item .ai-meta span:last-child {
	padding-right: 0
}

.article-item .ai-meta span:last-child::before {
	content: none
}

.article-item .ai-text {
	flex: 1;
	align-self: center
}

.article-item .ai-title a {
	color: var(--paragraph)
}

.article-item .ai-title a:hover {
	color: var(--heading)
}

.article-item p {
	margin-bottom: 14px
}

.article-item:hover {
	box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05)
}

.article-item:hover:not(.bg-gray) .ai-meta span {
	color: Var(--primary)
}

.article-item:hover:not(.bg-gray) .ai-meta span::before {
	background: var(--primary)
}

.article-item:hover .readmore {
	color: transparent;
	background: var(--primary-gradient2);
	-moz-background-clip: text;
	-webkit-background-clip: text;
	background-clip: text
}

.article-item:hover .ai-img img {
	transform: scale(1.06) rotate(3deg)
}

.article-item.bg-gray {
	padding: 0
}

.article-item.bg-gray .readmore {
	font-size: 16px;
	color: var(--primary)
}

.article-item.bg-gray .ai-img {
	margin-right: 0
}

.article-item.bg-gray .ai-img .ai-img-inner {
	height: 100%
}

.article-item.bg-gray .ai-text {
	padding: 20px
}

.article-item.bg-gray .ai-meta span::before {
	content: none
}

.article-item.bg-gray:hover {
	background: var(--white) !important
}

.timeline-sec {
	position: relative
}

.timeline-sec::before {
	content: "";
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	position: absolute;
	background: url("../images/water-shape.png") no-repeat scroll left top/contain
}

.timeline-img {
	position: absolute;
	right: 0;
	top: 45%;
	transform: translateY(-50%);
	width: 45%;
	z-index: 3
}

.timeline {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 700px;
	position: relative;
	z-index: 5
}

.timeline::before {
	content: "";
	position: absolute;
	left: 50%;
	height: 100%;
	border-right: 2px solid var(--success)
}

.timeline.timeline-light::before {
	border-color: var(--primary)
}

.timeline-light .timeline-item h3,
.timeline-light .timeline-item h4,
.timeline-light .timeline-item h5 {
	margin-bottom: 0;
	color: var(--heading)
}

.timeline-light .timeline-item h3 a,
.timeline-light .timeline-item h4 a,
.timeline-light .timeline-item h5 a {
	color: var(--heading)
}

.timeline-light .timeline-item h3 a:hover,
.timeline-light .timeline-item h4 a:hover,
.timeline-light .timeline-item h5 a:hover {
	color: var(--primary)
}

.timeline-light .timeline-item .tli-head::before {
	border-color: var(--primary)
}

.timeline-light .timeline-item .tli-head::after {
	border-color: var(--primary);
	background: var(--white-permanent)
}

.timeline-light .timeline-item:nth-child(2n+1) .tli-head::after {
	border-color: var(--primary);
	background: var(--white-permanent)
}

.timeline-light .timeline-item .date {
	color: var(--paragraph)
}

.timeline-light .timeline-item .date::before {
	color: rgba(var(--black-alpha), 0.04)
}

.timeline-light .timeline-item p {
	color: var(--paragraph)
}

.timeline-item {
	width: calc(50% - 50px);
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	position: relative;
	counter-increment: timelinecounter
}

.timeline-item:nth-child(2n+1) {
	text-align: right
}

.timeline-item:nth-child(2n+1) .tli-head::before {
	left: auto;
	right: -50px
}

.timeline-item:nth-child(2n+1) .tli-head::after {
	content: "";
	position: absolute;
	height: 32px;
	width: 32px;
	background: var(--secondary);
	border-radius: 50%;
	border: 3px solid var(--success);
	right: -66px;
	bottom: -16px;
	z-index: 5
}

.timeline-item .tli-head {
	padding-bottom: 20px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	flex-grow: 1;
	position: relative
}

.timeline-item .tli-head::before {
	content: "";
	position: absolute;
	width: 100%;
	border-bottom: 2px solid var(--success);
	left: -50px;
	bottom: 0
}

.timeline-item .date {
	color: var(--light-gray);
	display: block;
	position: relative;
	margin-bottom: 7px
}

.timeline-item .date::before {
	content: counters(timelinecounter, "", decimal-leading-zero);
	color: rgba(var(--white-alpha), 0.05);
	font-size: 80px;
	display: block;
	font-weight: 800;
	line-height: 1;
	margin-bottom: 10px
}

.timeline-item h3,
.timeline-item h4,
.timeline-item h5 {
	margin-bottom: 0;
	color: var(--success)
}

.timeline-item h3 a,
.timeline-item h4 a,
.timeline-item h5 a {
	color: var(--success)
}

.timeline-item h3 a:hover,
.timeline-item h4 a:hover,
.timeline-item h5 a:hover {
	color: var(--white-permanent)
}

.timeline-item p {
	color: var(--white-permanent)
}

.timeline-item p:last-child {
	margin-bottom: 0
}

.icon-block {
	transition: .3s;
	background: var(--light-gray);
	padding: 30px;
	border-radius: 10px
}

.icon-block:hover {
	background: var(--white);
	box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05)
}

.icon-block p:last-child {
	margin-bottom: 0
}

.social-icons a {
	display: inline-block;
	height: 34px;
	width: 34px;
	border: 1px solid var(--primary);
	text-align: center;
	line-height: 34px;
	border-radius: 50%;
	transition: .1s;
	position: relative;
	overflow: hidden;
	margin-right: 10px
}

.social-icons a:last-child {
	margin-right: 0px
}

.social-icons a i {
	position: relative;
	transition: .4s
}

.social-icons a::before {
	position: absolute;
	content: "";
	height: 0%;
	width: 100%;
	overflow: hidden;
	top: 0;
	left: 0;
	transition: .5s
}

.social-icons a:hover {
	color: var(--white)
}

.social-icons a:hover i {
	transform: rotate(360deg)
}

.social-icons a:hover::before {
	background: var(--primary);
	color: var(--white);
	height: 100%;
	top: auto;
	bottom: 0
}

.slide-text h1 {
	font-weight: 800;
	font-size: 70px;
	line-height: 1
}

.slide-text h1,
.slide-text h2,
.slide-text h3,
.slide-text h4,
.slide-text h5 {
	margin-bottom: 20px
}

.slide-text p {
	font-size: 20px;
	line-height: 1.6;
	max-width: 500px
}

.slide-text .btn {
	font-size: 18px
}

.hero-slide-bg {
	position: absolute;
	max-height: 100%;
	right: 0;
	max-width: 60%;
	bottom: 0;
	z-index: -1
}

.cloud-1 {
	max-width: 70px;
	top: -80px;
	position: absolute;
	left: 10%
}

.cloud-2 {
	max-width: 150px;
	position: absolute;
	top: -80px;
	left: 48%
}

.cloud-3 {
	max-width: 70px;
	top: 25%;
	position: absolute;
	right: 5%
}

.hero-slider-sec {
	position: relative
}

.hero-slider-sec .owl-item .slide-text>* {
	transform: translateY(50px);
	clip-path: inset(0 0 100% 0);
	transition: all .5s
}

.hero-slider-sec .owl-item .slide-text>*:nth-child(1) {
	transition-delay: .3s
}

.hero-slider-sec .owl-item .slide-text>*:nth-child(2) {
	transition-delay: .7s
}

.hero-slider-sec .owl-item .slide-text>*:nth-child(3) {
	transition-delay: 1.1s
}

.hero-slider-sec .owl-item .slide-text>*:nth-child(4) {
	transition-delay: 1.4s
}

.hero-slider-sec .owl-item .hero-slide-img {
	transition: 1s;
	transform: scale(0.2);
	opacity: 0;
	text-align: right
}

.hero-slider-sec .owl-item.active .slide-text>* {
	transform: translateY(0px);
	clip-path: inset(0 0 0 0)
}

.hero-slider-sec .owl-item.active .hero-slide-img {
	transform: scale(1);
	opacity: 1
}

.hero-slider-sec .owl-nav {
	position: absolute;
	width: 100%;
	max-width: 230px;
	display: flex;
	justify-content: space-between;
	bottom: 0
}

.hero-slider-sec .owl-nav button {
	outline: none !important;
	font-size: 23px !important;
	line-height: 1
}

.hero-slider-sec .owl-nav button.disabled {
	opacity: .5;
	pointer-events: none
}

.hero-slider-sec .owl-dots {
	outline: none;
	width: 170px;
	margin-left: 30px;
	position: relative;
	top: -15px
}

.hero-slider-sec .owl-dot {
	counter-increment: heroslidecounter;
	outline: none !important;
	position: relative;
	display: inline-block
}

.hero-slider-sec .owl-dot span {
	display: block;
	background: var(--border);
	height: 4px;
	position: relative
}

.hero-slider-sec .owl-dot:first-child span {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px
}

.hero-slider-sec .owl-dot:last-child span {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px
}

.hero-slider-sec .owl-dot::before {
	content: counters(heroslidecounter, "", decimal-leading-zero);
	transition: .3s;
	opacity: 0;
	font-weight: 600;
	font-size: 18px
}

.hero-slider-sec .owl-dot.active::before {
	opacity: 1;
	color: var(--primary)
}

.hero-slider-sec .owl-dot.active span::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background: var(--primary);
	border-radius: 10px
}

@keyframes top-to-bottom {
	0% {
		transform: scaleY(0);
		transform-origin: left top
	}
	50% {
		transform: scaleY(1);
		transform-origin: left top
	}
	51% {
		transform-origin: left bottom
	}
	100% {
		transform: scaleY(0);
		transform-origin: left bottom
	}
}

.work-flow-video {
	background: var(--secondary-gradient);
	border-radius: 10px;
	padding: 80px 8%;
	max-width: 720px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden
}

.work-flow-video::after {
	content: "";
	height: 200px;
	max-width: 230px;
	width: 50%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background: url("../images/bitcoin6.png") no-repeat scroll center/contain;
	left: 63.5%;
	opacity: .4;
	z-index: 4
}

.work-flow-video .play-icon {
	z-index: 5;
	position: relative;
	display: inline-block
}

.work-flow-video .wfv-link {
	color: var(--white-permanent);
	font-size: 18px
}

.work-flow-video .wfv-link i {
	color: var(--primary);
	font-size: 22px;
	line-height: 1
}

button.mfp-close {
	outline: none !important
}

.paginations a {
	display: inline-block;
	height: 45px;
	width: 45px;
	border: 1px solid var(--border);
	color: var(--paragraph);
	font-size: 20px;
	font-weight: 500;
	text-align: center;
	line-height: 45px;
	border-radius: 5px;
	margin-right: 10px;
	position: relative;
	overflow: hidden;
	z-index: 5
}

.paginations a:hover,
.paginations a.active {
	border-color: transparent;
	color: var(--white-permanent)
}

.paginations a:hover::before,
.paginations a.active::before {
	clip-path: circle(141.2% at 0 100%)
}

.paginations a.active {
	pointer-events: none
}

.paginations a::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	z-index: -1;
	transition: .5s;
	background: var(--primary-gradient2);
	clip-path: circle(0% at 0 100%)
}

.breadcrumbs a {
	font-weight: 600;
	color: var(--border);
	margin-right: 35px;
	position: relative;
	display: inline-block;
	font-size: 18px
}

.breadcrumbs a:last-child {
	margin-right: 0
}

.breadcrumbs a:last-child::before {
	content: none
}

.breadcrumbs a::before {
	content: "/";
	position: absolute;
	right: -27px;
	top: -1px;
	font-size: 19px;
	font-weight: 300;
	color: var(--border)
}

.breadcrumbs a:hover {
	color: var(--heading)
}

.breadcrumbs a.current {
	pointer-events: none;
	color: var(--primary)
}

.article-meta span {
	position: relative;
	display: inline-block;
	font-size: 16px;
	color: var(--paragraph);
	padding-right: 40px;
	margin-bottom: 8px;
	transition: all .3s
}

.article-meta span::before {
	position: absolute;
	content: "";
	height: 8px;
	width: 8px;
	border-radius: 50%;
	background: var(--paragraph);
	display: inline-block;
	right: 12px;
	top: 7px;
	transition: all .3s
}

.article-meta span:last-child {
	padding-right: 0
}

.article-meta span:last-child::before {
	content: none
}

.blog-details {
	background: var(--light-gray);
	padding: 7% 7% 0 7%
}

.comment-sec {
	padding: 0 7%
}

.comment {
	padding: 40px calc(6% + 23px) 0px
}

.comment .btn {
	border-radius: 0;
	font-weight: 600
}

.blog-details-inner {
	padding: 40px;
	margin-bottom: 40px;
	padding-top: 0;
	position: relative;
	z-index: 5
}

.blog-details-inner::before {
	content: "";
	background: rgba(var(--success-alpha), 0.02);
	position: absolute;
	height: calc(100% - 150px);
	width: 100%;
	left: 0;
	bottom: 0;
	border-radius: 10px;
	z-index: 0
}

.blog-details-head {
	padding-left: calc(6% - 40px);
	padding-right: calc(6% - 40px)
}

.blog-details-thumbnail {
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	margin-bottom: 40px
}

.blog-details-thumbnail img {
	width: 100%
}

.page-content {
	position: relative;
	padding-left: calc(6% - 40px);
	padding-right: calc(6% - 40px)
}

.page-content h1,
.page-content h2,
.page-content h3,
.page-content h4,
.page-content h5,
.page-content h6 {
	font-weight: 700;
	margin-bottom: 20px
}

.page-content em {
	font-size: 20px;
	margin-bottom: 25px;
	color: #361e2b;
	font-style: italic;
	display: block
}

.page-content a {
	color: var(--secondary)
}

.page-content iframe {
	margin-bottom: 25px
}

.page-content p {
	line-height: 1.7;
	margin-bottom: 25px
}

.page-content ul {
	margin-bottom: 30px
}

.page-content ul li {
	position: relative;
	margin-bottom: 10px;
	color: var(--heading);
	padding-left: 25px
}

.page-content ul li::before {
	position: absolute;
	left: 0;
	top: 8px;
	content: "";
	background: var(--primary);
	width: 9px;
	height: 9px;
	border-radius: 50%
}

.page-content ol {
	padding-left: 18px;
	margin-bottom: 25px
}

.page-content ol li {
	color: var(--heading);
	margin: 13px 0
}

.page-content .gallery {
	margin-top: 50px;
	margin-bottom: 50px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr
}

.page-content .gallery img {
	grid-row: span 1
}

.page-content .gallery img:first-child {
	grid-row: span 2
}

.page-content blockquote {
	text-align: center;
	color: var(--heading);
	font-size: 20px;
	font-family: var(--ff-heading);
	position: relative;
	margin-bottom: 30px;
	padding-top: 60px;
	max-width: 650px;
	margin-left: auto;
	margin-right: auto
}

.page-content blockquote::before {
	content: "❝";
	position: absolute;
	left: 50%;
	top: 15px;
	font-size: 70px;
	line-height: 1;
	transform: translateX(-50%);
	background: var(--success-gradient);
	color: transparent;
	-moz-background-clip: text;
	-webkit-background-clip: text;
	background-clip: text
}

img.align-left {
	float: left;
	margin-right: 30px;
	margin-bottom: 20px
}

img.align-right {
	float: right;
	margin-left: 25px;
	margin-bottom: 20px
}

.drop-cap::first-letter {
	font-size: 45px;
	color: var(--red);
	line-height: 1
}

.inline-checkbox {
	display: block;
	position: relative
}

.inline-checkbox input {
	display: none
}

.inline-checkbox input:checked+span::before {
	content: "\eed6"
}

.inline-checkbox span {
	position: relative;
	padding-left: 30px;
	display: block;
	color: var(--paragraph)
}

.inline-checkbox span::before {
	content: "";
	position: absolute;
	left: 0;
	top: 2px;
	height: 20px;
	width: 20px;
	border: 1px solid var(--primary);
	font-family: IcoFont;
	font-size: 23px;
	line-height: 0.8;
	color: var(--primary);
	text-indent: -2px
}

.post-navigation {
	padding: 40px 6%;
	border-top: 1px solid var(--border);
	border-bottom: 1px solid var(--border)
}

.post-navigation .ai-title a {
	color: var(--heading)
}

.post-navigation .article-item {
	margin-bottom: 0
}

.navigation-text {
	display: inline-block;
	color: var(--paragraph);
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 15px
}

.navigation-text i {
	font-size: 24px;
	line-height: 1;
	position: relative;
	display: inline-block;
	top: 2px
}

.blog-details-share {
	padding-left: 6%
}

.comments-sec {
	padding-left: 6%;
	padding-right: 6%
}

.profile {
	display: flex;
	align-items: center
}

.profile .p-img {
	height: 44px;
	width: 44px;
	overflow: hidden;
	border-radius: 50%;
	margin-right: 15px
}

.profile .p-img img {
	height: 100%;
	width: 100%;
	object-fit: cover
}

.profile h1,
.profile h2,
.profile h3,
.profile h4,
.profile h5 {
	margin-bottom: 0
}

.profile .p-name {
	flex: 1
}

.comment-item {
	background: var(--light-gray);
	padding: 20px
}

.comment-item .profile {
	margin-bottom: 20px
}

.comment-item p {
	line-height: 1.6;
	margin-bottom: 10px
}

.comment-item p:last-child {
	margin-bottom: 0
}

.faq-item {
	border-bottom: 1px solid var(--border);
	padding-bottom: 25px;
	margin-bottom: 25px;
	padding-left: 120px;
	position: relative;
	counter-increment: faqlist
}

.faq-item::before {
	content: counters(faqlist, "", decimal-leading-zero);
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	font-weight: 800;
	font-size: 90px;
	color: rgba(var(--black-alpha), 0.03)
}

.faq-item p {
	line-height: 1.7
}

.faq-item p:last-child {
	margin-bottom: 0
}

.faq-item:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: 0
}

.accordion-item {
	background: var(--light-gray);
	margin-bottom: 30px
}

.accordion-item .accordion-content {
	padding: 0 60px 18px 30px
}

.accordion-item .accordion-content p:last-child {
	margin-bottom: 0
}

.accordion-item .accordion-title {
	padding: 18px 60px 18px 30px;
	position: relative;
	margin-bottom: 0;
	cursor: pointer
}

.accordion-item .accordion-title::before {
	content: "\efc2";
	font-family: IcoFont;
	position: absolute;
	right: 18px;
	cursor: pointer;
	font-size: 18px
}

.accordion-item.active {
	box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05);
	background: var(--white)
}

.accordion-item.active .accordion-title::before {
	content: "\ef9a"
}

.accordion-item.active .accordion-title span {
	display: inline-block;
	color: transparent;
	background: var(--primary-gradient2);
	-moz-background-clip: text;
	-webkit-background-clip: text;
	background-clip: text
}

#particles {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0
}

.signin-page-sec {
	position: relative;
	z-index: 5;
	background: var(--secondary-gradient2)
}

.signin-page-sec::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	bottom: 0;
	left: 0;
	background: url("../images/water-shape2.png") no-repeat scroll left bottom/cover;
	z-index: -1
}

.signin-page-sec .header-top p {
	color: var(--white-permanent)
}

.signin-page-sec .header-top .lang-text {
	color: var(--white-permanent)
}

.signin-page-sec .header-top .lang-text::before {
	border-color: var(--white-permanent)
}

.signin-page-sec .header-top .lang-item input:checked+span {
	color: var(--primary)
}

.signin-page-sec .header-top .lang-item span {
	color: var(--white-permanent)
}

.signin-page-sec ::-webkit-input-placeholder {
	color: var(--white-permanent)
}

.signin-page-sec ::-moz-input-placeholder {
	color: var(--white-permanent)
}

.signin-block {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	z-index: 5
}

.signin-block input {
	background: rgba(var(--white-alpha), 0.2);
	border-radius: 7px;
	color: var(--white-permanent)
}

.signin-block input:focus {
	border-color: var(--white-permanent)
}

.signin-block p {
	color: var(--white-permanent);
	font-size: 18px
}

.input-icon-group-right {
	position: relative
}

.input-icon-group-right input {
	padding-right: 55px
}

.input-icon-group-right .input-icon {
	position: absolute;
	height: 100%;
	right: 0;
	top: 0;
	font-size: 22px;
	padding: 2px 15px 2px 5px;
	display: flex;
	align-items: center;
	color: var(--white-permanent)
}

.error-block h1 {
	font-weight: 800;
	font-size: 48px
}

.error-block p {
	font-size: 20px
}

.error-block .error-title {
	font-size: 200px;
	line-height: 1
}

@media all and (max-width: 1300px) {
	.countdown-sec {
		padding-top: 200px
	}
}

@media all and (max-width: 1199px) {
	.footer-bitcoin-img-one,
	.footer-bitcoin-img-two {
		max-width: 14%
	}
	.slide-text h1 {
		font-size: 50px
	}
	.innerpage-header-sec {
		padding-bottom: 125px
	}
	.counter-wrap:not(.counter-secondary-wrap) {
		border-radius: 15px
	}
	.demo-banner-text h1 {
		font-size: 60px
	}
	.demo-banner-text h2 {
		font-size: 38px
	}
}

@media all and (max-width: 991px) {
	.home1-block-wrap {
		padding-top: 50px;
		padding-bottom: 20px
	}
	.hero-banner-text h2 {
		font-size: 35px
	}
	.hero-banner-text h1 {
		font-size: 50px
	}
	.hero-banner-text p {
		font-size: 18px
	}
	.hero-banner-text .btn {
		font-size: 17px
	}
	.counter-wrap {
		border-radius: 10px
	}
	.counter-wrap .col-sm-6:nth-child(2)::before {
		content: none
	}
	.video-cilp-wrapper {
		margin-bottom: 50px
	}
	.column-count-3,
	.column-count-4 {
		column-count: 2
	}
	.countdown-sec {
		background: var(--primary-gradient2);
		padding: 60px 0
	}
	.roadmap-wrap .roadmap-img {
		position: unset;
		width: auto;
		transform: unset;
		margin-left: auto;
		margin-right: auto;
		display: block;
		margin-bottom: 30px;
		max-width: 100%
	}
	.roadmap li {
		margin: 0
	}
	.roadmap li:first-child {
		width: 50%
	}
	.roadmap li:last-child {
		width: 50%;
		margin: 0
	}
	.roadmap li:nth-child(2n+1) {
		justify-content: unset !important
	}
	.roadmap li:nth-child(2n) {
		justify-content: flex-end !important
	}
	.copyright-sec.footer-curve-bg-light,
	.footer-sec.footer-curve-bg-light {
		background: var(--light-gray) !important;
		padding-top: 30px
	}
	.article-lists {
		padding-left: 80px
	}
	.article-lists .article-item::before {
		font-size: 56px
	}
	.article-item .ai-img {
		width: 40%
	}
	.article-item .ai-img .ai-img-inner {
		padding-top: 120%
	}
}

@media all and (max-width: 767px) {
	.timeline-sec::before {
		content: none
	}
	.copyright-inner {
		flex-direction: column;
		text-align: center
	}
	.copyright-inner p {
		order: 3
	}
	.call-to-action {
		text-align: center;
		display: block
	}
	.call-to-action .cta-left {
		width: 100%;
		flex: unset;
		margin-right: 0
	}
	.call-to-action .cta-right {
		margin-top: 20px
	}
	.roadmap li {
		width: 100%
	}
	.roadmap li:first-child,
	.roadmap li:last-child {
		width: 100%
	}
	.roadmap-item {
		max-width: 400px
	}
	.article-item .ai-img {
		width: 100%;
		margin-right: 0;
		margin-bottom: 20px;
		max-width: 100%
	}
	.article-item .ai-img .ai-img-inner {
		padding-top: 60%
	}
	.sec-title {
		margin-bottom: 40px
	}
	.sec-title h1 {
		font-size: 45px
	}
	.sec-title h1[data-watermark] {
		padding: 15px 0
	}
	.sec-title h1[data-watermark]::before,
	.sec-title h2[data-watermark]::before,
	.sec-title h3[data-watermark]::before,
	.sec-title h4[data-watermark]::before,
	.sec-title h5[data-watermark]::before,
	.sec-title h6[data-watermark]::before {
		font-size: 100px
	}
	.column-count-2,
	.column-count-3,
	.column-count-4 {
		column-count: 1
	}
	.error-block .error-title {
		font-size: 150px
	}
}

@media all and (max-width: 575px) {
	.py-80,
	.pb-80 {
		padding-bottom: 40px
	}
	.py-80,
	.pt-80 {
		padding-top: 40px
	}
	.py-100,
	.pb-100 {
		padding-bottom: 50px
	}
	.py-100,
	.pt-100 {
		padding-top: 50px
	}
	.footer-widget-menu.column-4 {
		column-count: 3
	}
	.counter-wrap div[class^="col"]::before {
		content: none
	}
	.timeline::before {
		left: 12px
	}
	.timeline-item {
		width: 100%;
		padding-left: 30px
	}
	.timeline-item:nth-child(2n+1) {
		text-align: left
	}
	.timeline-item .tli-head::before {
		left: auto !important;
		right: 15px !important
	}
	.timeline-item .tli-head::after {
		content: "";
		position: absolute;
		height: 25px !important;
		width: 25px !important;
		background: var(--secondary);
		border-radius: 50%;
		border: 3px solid var(--success);
		right: auto !important;
		bottom: -13px !important;
		z-index: 5;
		left: -29px
	}
	.timeline-item time::before {
		color: rgba(var(--white-alpha), 0.12)
	}
	.article-lists {
		padding-left: 0
	}
	.article-lists .article-item::before {
		content: none
	}
	.work-flow-video .play-icon {
		max-width: 45px
	}
	.blog-details-inner {
		padding: 20px
	}
	.faq-item {
		padding-left: 70px
	}
	.faq-item::before {
		font-size: 50px
	}
	.accordion-item .accordion-title {
		padding: 18px 45px 18px 20px
	}
	.accordion-item .accordion-title::before {
		font-size: 15px
	}
	.demo-block {
		min-height: auto
	}
	.demo-block-responsive {
		padding: 15px
	}
}

@media all and (max-width: 400px) {
	.footer-widget-menu.column-3,
	.footer-widget-menu.column-4 {
		column-count: 2
	}
	.hero-banner-text h2 {
		font-size: 28px
	}
	.hero-banner-text h1 {
		font-size: 37px
	}
	.hero-banner-text h1 span {
		font-size: 37px
	}
}

.cripto-banner-sec {
	background-color: var(--theme-blue)
}

.cripto-banner-sec .h-logo svg path:nth-child(1) {
	fill: var(--white)
}

.crazy-header .h-menu>li>a {
	color: var(--white)
}

.crazy-header .menu-trigger span {
	background-color: var(--white)
}

.crazy-header .mobile-menu-wrap {
	background-color: var(--theme-blue)
}

.crazy-header .menu-close {
	color: var(--white)
}

.crazy-header .mobile-menu .h-menu li i {
	color: var(--white)
}

.crazy-header .mobile-menu .h-menu .has-child a {
	color: var(--white)
}

.crazy-header .mobile-menu .mm-widget-title {
	color: var(--white)
}

.crazy-header .mobile-menu .mm-menu li a {
	color: var(--white)
}

.selectpicker .nice-select {
	border-radius: 5px;
	background: var(--primary-gradient2);
	border: none;
	color: var(--white);
	height: 35px;
	line-height: 35px
}

.selectpicker .nice-select:after {
	border-color: var(--white)
}

.selectpicker .nice-select .list {
	left: unset;
	right: 0
}

.selectpicker .nice-select .list li {
	color: var(--heading);
	margin-bottom: 0px
}

.selectpicker .nice-select .list li::before {
	display: none
}

@media all and (max-width: 1199px) {
	.crazy-header .selectpicker {
		display: none
	}
}

.tread-title-area {
	padding: 8px 0px;
	background-color: var(--theme-blue-light)
}

.tread-title-area .tread-slider-wrapper p {
	margin-bottom: 0px;
	color: var(--white);
	font-size: 16px;
	font-weight: 500;
	text-transform: capitalize;
	font-family: var(--ff-secondary)
}

.cripto-banner {
	position: relative;
	padding: 190px 0px 230px;
	z-index: 1;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover
}

.cripto-banner-text {
	max-width: 715px
}

.cripto-banner-text h3 {
	font-family: var(--ff-secondary);
	font-size: 30px;
	font-weight: bold;
	color: var(--white);
	margin-bottom: 5px
}

.cripto-banner-text h3 span {
	font-weight: normal;
	display: inline-block;
	margin: 0px 5px
}

.cripto-banner-text h2 {
	font-family: var(--ff-secondary);
	font-size: 64px;
	font-weight: normal;
	color: var(--white);
	margin-bottom: 15px
}

.cripto-banner-text h2 strong {
	font-weight: bold;
	display: inline-block;
	margin: 0px 5px
}

.cripto-banner-text p {
	color: var(--white);
	font-size: 18px;
	line-height: 1.5;
	margin-bottom: 30px
}

.cripto-banner-text .btn {
	border-radius: 5px;
	padding: 15px 25px
}

.cripto-banner-anime {
	position: absolute;
	right: 0;
	bottom: 150px;
	z-index: -1
}

@keyframes zoom {
	0% {
		clip-path: circle(40% at 0 0)
	}
	100% {
		clip-path: circle(200% at 0 0)
	}
}

.cripto-banner-counter {
	padding: 45px 50px;
	background: var(--blur-white);
	border: 1px solid var(--cripto-border);
	box-sizing: border-box;
	backdrop-filter: blur(15px);
	border-radius: 10px;
	font-family: var(--ff-secondary)
}

.cripto-banner-counter h3 {
	font-size: 30px;
	font-weight: 600;
	color: var(--white);
	line-height: 1.5
}

.cripto-banner-counter p {
	font-size: 20px;
	font-weight: normal;
	margin-bottom: 0px;
	color: var(--white);
	line-height: 1.5
}

.cripto-banner-counter .btn {
	margin-bottom: 40px;
	padding: 15px 25px;
	border-radius: 5px
}

.cripto-banner-counter p span {
	font-size: 14px;
	font-weight: 500;
	line-height: 1.5
}

.cripto-banner-counter ul {
	display: flex;
	align-items: center
}

.cripto-banner-counter ul li {
	padding: 10px
}

.cripto-banner-counter ul li:first-child {
	padding-left: 0px
}

.cripto-counter {
	margin-top: 40px;
	margin-bottom: 20px
}

.cripto-counter .countdown-clock {
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-gap: 30px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin: 0 auto
}

.cripto-counter .ci-inner {
	text-align: center;
	width: 70px
}

.cripto-counter .ci-inner>div {
	width: 100%;
	height: 70px;
	background-color: var(--white);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	color: var(--theme-blue-light);
	margin-bottom: 10px;
	font-weight: 600
}

.cripto-counter .ci-inner h4 {
	font-size: 16px;
	color: var(--white);
	font-weight: normal
}

@media all and (max-width: 1550px) {
	.cripto-banner {
		padding: 100px 0px 150px
	}
}

@media all and (max-width: 1350px) {
	.cripto-banner-text h3 {
		font-size: 25px
	}
	.cripto-banner-text h2 {
		font-size: 50px
	}
	.cripto-counter .countdown-clock {
		grid-gap: 15px
	}
}

@media all and (max-width: 991px) {
	.cripto-banner-counter {
		margin-top: 50px;
		max-width: 600px
	}
	.cripto-counter .countdown-clock {
		grid-gap: 30px;
		max-width: 400px;
		margin: unset
	}
}

@media all and (max-width: 575px) {
	.cripto-banner {
		padding: 50px 0px 100px
	}
	.cripto-banner-text h3 {
		font-size: 20px
	}
	.cripto-banner-text h2 {
		font-size: 40px
	}
	.cripto-banner-counter {
		padding: 35px 15px
	}
	.cripto-banner-counter h3 {
		font-size: 22px;
		margin-bottom: 5px
	}
	.cripto-banner-counter p {
		font-size: 16px
	}
	.cripto-banner-counter .cripto-counter {
		margin-top: 25px;
		margin-bottom: 10px
	}
	.cripto-banner-counter .cripto-counter .countdown-clock {
		grid-gap: 10px
	}
	.cripto-banner-counter .btn {
		margin-bottom: 25px
	}
}

@media all and (max-width: 380px) {
	.cripto-banner-counter .cripto-counter .countdown-clock {
		max-width: 100%;
		grid-gap: 10px;
		grid-template-columns: 1fr 1fr
	}
}

.cripto-about-area {
	padding: 70px 0px 100px
}

.cripto-about-wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap
}

.cripto-about-content {
	flex: 1;
	margin-left: 60px
}

.cripto-about-content p {
	font-size: 17px;
	line-height: 1.5;
	margin-bottom: 20px;
	max-width: 700px
}

.cripto-about-content ul {
	max-width: 400px;
	margin-bottom: 30px
}

.cripto-about-content ul li {
	position: relative;
	font-size: 15px;
	line-height: 1.5;
	font-family: var(--ff-secondary);
	margin-bottom: 10px;
	padding-left: 20px;
	font-weight: 400
}

.cripto-about-content ul li:after {
	position: absolute;
	content: "";
	top: 7px;
	left: 0;
	height: 8px;
	width: 8px;
	background-color: var(--theme-blue-light);
	border-radius: 50%
}

.cripto-about-content .btn {
	border-radius: 5px
}

.cripto-heading h2 {
	font-family: var(--ff-secondary);
	font-size: 30px;
	font-weight: 600;
	position: relative;
	padding: 35px 0px;
	display: inline-block;
	text-transform: capitalize
}

.cripto-heading h2 span {
	display: inline-block;
	padding: 0px 5px;
	color: var(--theme-blue-light)
}

.cripto-heading h2:after {
	content: attr(data-watermark);
	position: absolute;
	left: calc(100% - 100px);
	-webkit-text-stroke: 1px var(--title-border);
	color: transparent;
	font-size: 130px;
	line-height: .9;
	white-space: nowrap;
	text-transform: uppercase;
	top: 50%;
	z-index: -1;
	transform: translateY(-50%)
}

@media all and (max-width: 1350px) {
	.cripto-heading h2:after {
		left: 0
	}
	.cripto-about-area {
		padding: 70px 0px
	}
}

@media all and (max-width: 1199px) {
	.cripto-about-img {
		max-width: 45%
	}
	.cripto-heading h2:after {
		font-size: 90px
	}
}

@media all and (max-width: 991px) {
	.cripto-about-content {
		margin-left: 35px
	}
}

@media all and (max-width: 767px) {
	.cripto-about-wrapper {
		flex-direction: column
	}
	.cripto-about-img {
		max-width: 500px;
		order: 2
	}
	.cripto-about-content {
		margin-left: 0px;
		order: 1;
		margin-bottom: 50px
	}
}

.cripto-works-area {
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 70px 0px;
	color: var(--white);
	overflow: hidden;
	z-index: 1
}

.cripto-works-anime {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	z-index: -1
}

.cripto-works-anime svg {
	height: 100%
}

.cripto-works-wrapper {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap
}

.cripto-works-content {
	flex: 1;
	margin-right: 170px
}

.cripto-works-list {
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-gap: 80px;
	grid-template-columns: 1fr 1fr;
	margin: 0 auto
}

.cripto-works-heading {
	margin-bottom: 30px
}

.cripto-works-heading h2 {
	font-size: 30px;
	font-weight: 600;
	color: var(--white);
	font-family: var(--ff-secondary)
}

.cripto-works-heading p {
	color: var(--white);
	margin-bottom: 0px;
	line-height: 1.5
}

.cripto-works-single-list {
	position: relative;
	z-index: 1;
	padding-top: 70px
}

.cripto-works-single-list:after {
	position: absolute;
	content: attr(data-number);
	font-family: var(--ff-secondary);
	font-weight: 900;
	font-size: 150px;
	line-height: 1;
	color: rgba(0, 178, 255, 0.05);
	top: 0;
	right: 0;
	z-index: -1
}

.cripto-works-single-list span {
	color: var(--white);
	font-size: 16px;
	display: block;
	margin-bottom: 10px
}

.cripto-works-single-list h3 {
	font-size: 20px;
	font-weight: 600;
	color: var(--white);
	font-family: var(--ff-secondary);
	color: var(--white)
}

.cripto-works-single-list p {
	margin-bottom: 0px;
	color: var(--light-gray);
	line-height: 1.5
}

.cripto-works-img {
	margin-right: 180px
}

@media all and (max-width: 1350px) {
	.cripto-works-content {
		margin-right: 50px
	}
	.cripto-works-img {
		margin-right: 80px
	}
}

@media all and (max-width: 1199px) {
	.cripto-works-list {
		grid-gap: 50px
	}
	.cripto-works-img {
		margin-right: 0px;
		width: 50%;
		margin: 0 auto
	}
}

@media all and (max-width: 991px) {
	.cripto-works-content {
		margin-right: 0px;
		flex: unset;
		margin-bottom: 50px
	}
	.cripto-works-img {
		margin-right: 0px;
		width: 430px;
		margin: 0 auto
	}
	.cripto-works-anime {
		display: none
	}
	.cripto-works-heading {
		max-width: 500px
	}
	.cripto-works-list {
		grid-gap: 100px;
		grid-row-gap: 35px
	}
}

@media all and (max-width: 767px) {
	.cripto-works-list {
		grid-gap: 50px;
		grid-row-gap: 35px
	}
	.cripto-works-single-list:after {
		font-size: 90px
	}
	.cripto-works-single-list {
		padding-top: 30px
	}
}

@media all and (max-width: 575px) {
	.cripto-works-list {
		grid-template-columns: 1fr
	}
}

.cripto-token-area {
	padding: 60px 0px 100px
}

.cripto-token-wrapper {
	margin-bottom: 70px
}

.cripto-token-wrapper .sec-title {
	max-width: 700px;
	margin: 0 auto;
	padding-bottom: 40px
}

.cripto-token-wrapper .sec-title h1 {
	font-size: 30px;
	padding: 50px 0px
}

.cripto-token-wrapper .sec-title h1 span {
	color: var(--theme-blue-light)
}

.cripto-token-wrapper .sec-title h1:before {
	font-size: 150px
}

.cripto-token-wrapper .sec-title p {
	font-size: 16px
}

.cripto-token-card-wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap
}

.cripto-token-card-wrapper .cripto-single-token {
	padding: 30px;
	margin-bottom: 30px;
	border: 1px solid var(--title-border);
	margin-right: 20px;
	font-family: var(--ff-secondary)
}

.cripto-token-card-wrapper .cripto-single-token h4 {
	font-size: 18px;
	font-weight: 600;
	color: var(--theme-blue-light);
	margin-bottom: 10px
}

.cripto-token-card-wrapper .cripto-single-token p {
	font-size: 16px;
	color: var(--heading);
	margin-bottom: 0px
}

.cripto-token-wrapper .btn {
	display: flex;
	margin: 0 auto;
	border-radius: 5px;
	padding: 15px 20px
}

.cripto-token-wrapper .btn i {
	padding-left: 15px
}

.cripto-sale-heading {
	text-align: center;
	max-width: 500px;
	margin: 0 auto;
	padding-bottom: 40px
}

.cripto-sale-heading h2 {
	font-family: var(--ff-primary);
	font-size: 24px;
	font-weight: 600;
	color: var(--theme-blue-dark);
	margin-bottom: 10px
}

.cripto-sale-heading p {
	font-size: 16px;
	margin-bottom: 0;
	line-height: 1.5
}

.cripto-sale-content {
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-gap: 30px;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 0 auto
}

.cripto-sale-card {
	background: var(--light-gray);
	border: 1px solid var(--title-border);
	border-radius: 10px;
	overflow: hidden;
	transition: .4s ease-in
}

.cripto-sale-card .cripto-sale-title {
	transition: .4s ease-in;
	padding: 20px;
	border-radius: 0px 0px 10px 10px;
	background-color: var(--theme-blue-dark);
	text-align: center
}

.cripto-sale-card .cripto-sale-title h3 {
	font-size: 18px;
	font-family: var(--ff-secondary);
	color: var(--white);
	margin-bottom: 0px
}

.cripto-sale-card.active,
.cripto-sale-card:hover {
	background-color: transparent;
	border-color: var(--theme-blue-light)
}

.cripto-sale-card.active .cripto-sale-title,
.cripto-sale-card:hover .cripto-sale-title {
	background-color: var(--theme-blue-light)
}

.cripto-sale-card:hover {
	transform: translateY(-10px)
}

.cripto-sale-card-content {
	padding: 50px;
	font-family: var(--ff-secondary)
}

.cripto-sale-card-content h4 {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;
	color: var(--theme-blue-light)
}

.cripto-sale-card-content h5 {
	font-size: 17px;
	font-weight: 500;
	margin-bottom: 15px
}

.cripto-sale-card-content p {
	font-size: 16px;
	line-height: 1.4;
	margin-bottom: 0px;
	font-weight: 300
}

@media all and (max-width: 1199px) {
	.cripto-sale-card-content {
		padding: 25px 35px
	}
}

@media all and (max-width: 991px) {
	.cripto-sale-content {
		grid-template-columns: 1fr 1fr
	}
}

@media all and (max-width: 767px) {
	.cripto-sale-content {
		grid-template-columns: 1fr;
		max-width: 500px
	}
	.cripto-token-area {
		padding: 80px 0px
	}
}

.cripto-roadmap-area {
	padding: 60px 0px 220px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color: var(--copyright-bg)
}

.cripto-roadmap-wrapper .sec-title {
	max-width: 800px;
	margin: 0 auto;
	padding-bottom: 60px
}

.cripto-roadmap-wrapper .sec-title h1 {
	font-size: 30px;
	color: var(--white);
	padding: 40px 0px;
	font-family: var(--ff-secondary)
}

.cripto-roadmap-wrapper .sec-title h1:before {
	-webkit-text-stroke: 1px rgba(238, 238, 238, 0.4);
	font-size: 145px;
	text-transform: uppercase
}

.cripto-roadmap-wrapper .sec-title p {
	color: var(--white);
	font-size: 16px;
	line-height: 1.5;
	padding-top: 20px;
	font-family: var(--ff-secondary);
	margin: 0px 35px
}

.cripto-roadmap-content {
	margin-top: 175px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 120px;
	counter-reset: counter
}

.cripto-roadmap-content li {
	width: calc(100% / 3 - 150px);
	box-sizing: border-box;
	display: inline-block;
	position: relative;
	height: auto;
	box-sizing: border-box;
	font-family: var(--ff-secondary);
	z-index: 1;
	transition: .4s ease-in
}

.cripto-roadmap-content li:nth-child(even) {
	margin-top: -320px;
	margin-left: -100px;
	margin-right: -100px
}

.cripto-roadmap-content li:nth-child(even):hover {
	transform: translateY(20px)
}

.cripto-roadmap-content li:nth-child(odd):hover {
	transform: translateY(-20px)
}

.cripto-roadmap-content li:before {
	position: absolute;
	counter-increment: counter;
	content: "0" counter(counter);
	top: -35px;
	left: 50%;
	transform: translateX(-50%);
	height: 70px;
	width: 70px;
	background-color: #0D005B;
	border: 2px solid #5D44BE;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	font-size: 20px;
	font-weight: 500;
	color: var(--white);
	transition: .4s ease-in-out
}

.cripto-roadmap-content li:hover:before {
	background-color: #5D44BE;
	border-color: #00B2FF
}

.cripto-roadmap-content li h5 {
	font-size: 15px;
	line-height: 1.4;
	margin-bottom: 5px;
	color: var(--white);
	font-weight: 500
}

.cripto-roadmap-content li h3 {
	font-size: 18px;
	font-weight: 600;
	color: var(--white);
	padding-bottom: 15px;
	margin-bottom: 15px;
	position: relative
}

.cripto-roadmap-content li h3:after {
	position: absolute;
	content: "";
	bottom: 0;
	left: 50%;
	height: 1px;
	width: 80px;
	background-color: var(--white);
	transform: translateX(-50%)
}

.cripto-roadmap-content li p {
	font-size: 16px;
	line-height: 1.5;
	color: var(--white);
	margin-bottom: 0px
}

.hex {
	-webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
	-moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
	padding: 50px 60px 35px;
	text-align: center;
	background: #00B2FF
}

.hex:after {
	position: absolute;
	content: "";
	background-color: var(--theme-blue-light);
	top: 2px;
	left: 2px;
	width: calc(100% - 4px);
	height: calc(100% - 4px);
	-webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
	-moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
	z-index: -1
}

.hexo-design-area {
	position: relative;
	padding-bottom: 50px
}

.hexo-design-area .hexo-image-area .hexo-1 {
	position: absolute;
	bottom: 0;
	left: 300px
}

.hexo-design-area .hexo-image-area .hexo-2 {
	position: absolute;
	bottom: 50px;
	left: 50%;
	height: 100px
}

.hexo-design-area .hexo-image-area .hexo-3 {
	position: absolute;
	top: -150px;
	right: 300px;
	height: 70px
}

@media all and (max-width: 1350px) {
	.cripto-roadmap-wrapper .sec-title {
		padding-bottom: 90px
	}
	.cripto-roadmap-content {
		flex-wrap: wrap;
		padding-right: 0px;
		justify-content: flex-start;
		max-width: 1145px;
		margin: 0 auto
	}
	.cripto-roadmap-content li {
		width: 300px
	}
	.cripto-roadmap-content li:nth-child(even) {
		margin-top: 0px;
		margin-left: 0px;
		margin-right: 0px
	}
	.cripto-roadmap-content li:nth-child(2) {
		margin-top: 0px;
		margin-left: 120px;
		margin-right: 120px
	}
	.cripto-roadmap-content li:nth-child(4) {
		margin-top: -80px;
		margin-left: 210px;
		margin-right: 0px
	}
	.cripto-roadmap-content li:nth-child(5) {
		margin-top: -100px;
		margin-left: 120px;
		margin-right: 0px
	}
	.cripto-roadmap-content li:nth-child(6) {
		margin-top: -80px;
		margin-left: 425px;
		margin-right: 0px
	}
	.hexo-design-area {
		padding-bottom: 0px
	}
	.hexo-design-area .hexo-image-area .hexo-3 {
		top: 0px;
		right: 26%
	}
	.hexo-design-area .hexo-image-area .hexo-2 {
		bottom: unset;
		top: 0px;
		right: unset;
		left: 26%
	}
	.hexo-design-area .hexo-image-area .hexo-1 {
		bottom: 180px;
		left: 5%
	}
}

@media all and (max-width: 1199px) {
	.cripto-roadmap-content {
		max-width: 930px
	}
	.cripto-roadmap-content li:nth-child(3) {
		margin-top: -80px;
		margin-left: 210px
	}
	.cripto-roadmap-content li:nth-child(4) {
		margin-top: -80px;
		margin-left: 120px
	}
	.cripto-roadmap-content li:nth-child(5) {
		margin-top: -80px;
		margin-left: 0px
	}
	.cripto-roadmap-content li:nth-child(6) {
		margin-top: -80px;
		margin-left: 120px
	}
	.hexo-design-area .hexo-image-area .hexo-2 {
		left: 33%
	}
	.hexo-design-area .hexo-image-area .hexo-3 {
		right: 10%
	}
	.hexo-design-area .hexo-image-area .hexo-1 {
		bottom: 0px;
		left: 35%;
		width: 150px
	}
}

@media all and (max-width: 991px) {
	.cripto-roadmap-content {
		max-width: 725px
	}
	.cripto-roadmap-content li:nth-child(2) {
		margin-right: 0px
	}
	.cripto-roadmap-content li:nth-child(4) {
		margin-top: -80px;
		margin-left: 0px
	}
	.cripto-roadmap-content li:nth-child(5) {
		margin-top: -80px;
		margin-left: 120px
	}
	.cripto-roadmap-content li:nth-child(6) {
		margin-left: 210px
	}
	.hexo-design-area .hexo-image-area .hexo-1 {
		left: 10%;
		width: 100px
	}
	.hexo-design-area .hexo-image-area .hexo-2 {
		left: 40%
	}
	.hexo-design-area .hexo-image-area .hexo-3 {
		top: unset;
		bottom: 0
	}
	.cripto-roadmap-wrapper .sec-title h1 {
		padding: 20px 0px
	}
	.cripto-roadmap-wrapper .sec-title h1:before {
		font-size: 90px
	}
}

@media all and (max-width: 767px) {
	.cripto-roadmap-content {
		max-width: 100%;
		justify-content: space-between
	}
	.cripto-roadmap-content li {
		width: calc(100% / 2 - 25px)
	}
	.cripto-roadmap-content li:nth-child(2) {
		margin-left: 0px
	}
	.cripto-roadmap-content li:nth-child(3) {
		margin-top: 70px;
		margin-left: 0px
	}
	.cripto-roadmap-content li:nth-child(4) {
		margin-top: 70px;
		margin-left: 0px
	}
	.cripto-roadmap-content li:nth-child(5) {
		margin-top: 70px;
		margin-left: 0px
	}
	.cripto-roadmap-content li:nth-child(6) {
		margin-left: 0px;
		margin-top: 70px
	}
	.hexo-design-area .hexo-image-area .hexo-2,
	.hexo-design-area .hexo-image-area .hexo-1,
	.hexo-design-area .hexo-image-area .hexo-3 {
		left: 50%;
		transform: translateX(-50%)
	}
	.hexo-design-area .hexo-image-area .hexo-1 {
		width: 100px;
		top: 250px
	}
	.hexo-design-area .hexo-image-area .hexo-3 {
		top: unset;
		bottom: 250px
	}
}

@media all and (max-width: 575px) {
	.cripto-roadmap-wrapper .sec-title h1 {
		padding: 5px 0px
	}
	.cripto-roadmap-wrapper .sec-title h1:before {
		font-size: 60px
	}
	.cripto-roadmap-area {
		padding: 60px 0px 60px;
		background-color: #0d005f
	}
	.cripto-roadmap-content {
		max-width: 350px;
		justify-content: space-between
	}
	.cripto-roadmap-content li {
		max-width: 350px;
		width: 100%
	}
	.cripto-roadmap-content li:nth-child(2) {
		margin-left: 0px;
		margin-top: 70px
	}
	.hexo-image-area {
		display: none
	}
}

.cripto-ico-area {
	margin-top: -200px;
	position: relative;
	padding-bottom: 100px;
	z-index: 1
}

.cripto-ico-area:after {
	position: absolute;
	content: "";
	bottom: 0;
	left: 0;
	height: calc(100% - 200px);
	width: 100%;
	background-color: var(--copyright-bg);
	z-index: -1
}

.cripto-ico-area .cripto-ico-shape {
	position: absolute;
	top: 200px;
	left: 0
}

.cripto-ico-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end
}

.cripto-ico-content {
	flex: 1;
	font-family: var(--ff-secondary);
	margin: 120px 0px 100px 100px;
	background-color: var(--copyright-bg);
	margin-right: -50px;
	position: relative;
	z-index: 1
}

.cripto-ico-content h2 {
	font-size: 30px;
	line-height: 1.4;
	margin-bottom: 10px;
	color: var(--theme-blue-dark)
}

.cripto-ico-content p {
	font-size: 16px;
	line-height: 1.5
}

.cripto-ico-content .btn {
	border-radius: 5px
}

.cripto-ico-image {
	max-width: 60%;
	position: relative;
	z-index: 2
}

.cripto-ico-image .video-play {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 50px;
	width: 50px;
	z-index: 1
}

.cripto-ico-image .video-play:after,
.cripto-ico-image .video-play:before {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	border-radius: 50%;
	top: 0;
	left: 0;
	background: var(--white);
	z-index: -1
}

.cripto-ico-image .video-play:after {
	animation: zooming1 1s infinite .3s linear
}

.cripto-ico-image .video-play:before {
	animation: zooming2 1s infinite 1s linear
}

@keyframes zooming1 {
	0% {
		transform: scale(0.8);
		opacity: 0.3
	}
	100% {
		transform: scale(1.5);
		opacity: 0.1
	}
}

@keyframes zooming2 {
	0% {
		transform: scale(0.8);
		opacity: 0.3
	}
	100% {
		transform: scale(1.8);
		opacity: 0.1
	}
}

@media all and (max-width: 991px) {
	.cripto-ico-shape {
		top: 100px
	}
}

@media all and (max-width: 991px) {
	.cripto-ico-content {
		margin: 120px 0px 50px 100px
	}
	.cripto-ico-image {
		max-width: 50%
	}
}

@media all and (max-width: 767px) {
	.cripto-ico-wrapper {
		flex-direction: column
	}
	.cripto-ico-area {
		margin-top: -50px
	}
	.cripto-ico-content {
		margin: 0 auto;
		background-color: transparent;
		max-width: 500px
	}
	.cripto-ico-area .cripto-ico-shape {
		width: 270px
	}
	.cripto-ico-image {
		max-width: 500px;
		margin-top: 35px
	}
	.cripto-ico-area:after {
		height: 100%
	}
}

@media all and (max-width: 575px) {
	.cripto-ico-area {
		margin-top: 0px;
		padding: 80px 0px 50px !important
	}
	.cripto-ico-image {
		margin: 0 auto;
		margin-top: 35px
	}
	.cripto-ico-content {
		text-align: center
	}
	.cripto-ico-content h2 {
		font-size: 26px
	}
}

.cripto-member-area {
	background-color: var(--copyright-bg);
	padding-bottom: 70px
}

.cripto-member-area .sec-title {
	max-width: 760px;
	margin: 0 auto
}

.cripto-member-area .sec-title p {
	font-size: 16px;
	line-height: 1.5
}

.cripto-member-area .sec-title h1 {
	font-size: 30px;
	line-height: 1.4;
	padding: 40px 0px;
	font-family: var(--ff-secondary)
}

.cripto-member-area .sec-title h1 span {
	color: var(--theme-blue-light)
}

.cripto-member-area .sec-title h1::before {
	font-size: 130px;
	text-transform: uppercase
}

.cripto-member-content {
	padding-top: 50px;
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-gap: 30px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin: 0 auto
}

.cmc-single-content {
	position: relative;
	border-radius: 53% 45% 34% 66% / 30% 52% 48% 71%;
	overflow: hidden
}

.cmc-single-content .cmcs-content-overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transition: 0.5s ease-in;
	padding: 25px 50px
}

.cmc-single-content .cmcs-content-overlay ul {
	display: flex;
	transform: translateY(50px);
	opacity: 0;
	visibility: hidden;
	transition: .4s ease-in-out
}

.cmc-single-content .cmcs-content-overlay ul li a {
	height: 35px;
	width: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: 1px solid var(--white);
	font-size: 17px;
	color: var(--white);
	margin: 10px
}

.cmc-single-content .cmcs-content-overlay ul li a:hover {
	border-color: var(--theme-blue-light);
	background-color: var(--theme-blue-light)
}

.cmc-single-content .cmcs-content-overlay h3 {
	font-size: 20px;
	color: var(--white);
	margin-bottom: 0px;
	transform: translateY(50px);
	opacity: 0;
	visibility: hidden;
	transition: .4s ease-in-out;
	transition-delay: .2s
}

.cmc-single-content .cmcs-content-overlay p {
	font-size: 16px;
	color: var(--white);
	margin-bottom: 0px;
	line-height: 1.5;
	transform: translateY(50px);
	opacity: 0;
	visibility: hidden;
	transition: .4s ease-in-out;
	transition-delay: .4s
}

.cmc-single-content .cmcs-content-overlay:hover {
	background: rgba(93, 68, 190, 0.75)
}

.cmc-single-content .cmcs-content-overlay:hover ul,
.cmc-single-content .cmcs-content-overlay:hover h3,
.cmc-single-content .cmcs-content-overlay:hover p {
	transform: translateY(0px);
	opacity: 1;
	visibility: visible
}

.cmc-single-content .cmcs-content-img img {
	width: 100%;
	transform: scale(1.1)
}

.cripto-load-more {
	margin-top: 70px;
	display: flex;
	justify-content: center
}

.cripto-load-more .btn {
	border-radius: 5px
}

@media all and (max-width: 1199px) {
	.cmc-single-content .cmcs-content-overlay ul {
		flex-wrap: wrap;
		margin-bottom: 10px
	}
	.cmc-single-content .cmcs-content-overlay ul li a {
		height: 30px;
		width: 30px;
		font-size: 15px;
		margin: 5px
	}
}

@media all and (max-width: 991px) {
	.cripto-member-content {
		grid-template-columns: 1fr 1fr;
		max-width: 560px
	}
	.cripto-load-more {
		margin-top: 50px
	}
}

@media all and (max-width: 575px) {
	.cripto-member-content {
		grid-template-columns: 1fr;
		max-width: 290px
	}
	.cripto-member-area .sec-title h1 {
		padding: 20px 0px;
		margin-bottom: 15px
	}
	.cripto-member-area .sec-title h1::before {
		font-size: 100px;
		text-transform: uppercase
	}
}

.cripto-faq-area {
	padding: 80px 0px
}

.cripto-faq-area .sec-title {
	max-width: 760px;
	margin: 0 auto;
	margin-bottom: 40px
}

.cripto-faq-area .sec-title p {
	font-size: 16px;
	font-family: var(--ff-secondary);
	line-height: 1.5
}

.cripto-faq-area .sec-title h1 {
	font-size: 30px;
	font-family: var(--ff-secondary);
	padding: 50px 0px
}

.cripto-faq-area .sec-title h1 span {
	color: var(--theme-blue-light)
}

.cripto-faq-area .sec-title h1:before {
	font-size: 150px;
	font-family: var(--ff-secondary);
	text-transform: uppercase
}

.single-accordion {
	padding: 30px 50px;
	border-radius: 10px;
	background-color: var(--light-gray2);
	margin-bottom: 30px;
	transition: .4s ease-in-out;
	box-shadow: 0px 0px 0px rgba(0, 0, 0, 0)
}

.single-accordion:last-child {
	margin-bottom: 0px
}

.single-accordion .accordion-head {
	position: relative
}

.single-accordion .accordion-head h6 {
	margin-bottom: 0px;
	font-size: 18px;
	font-weight: 500;
	color: var(--black2)
}

.single-accordion .accordion-head:after,
.single-accordion .accordion-head:before {
	position: absolute;
	content: "";
	height: 25px;
	width: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 0px;
	top: 0px;
	transition: .4s ease-in
}

.single-accordion .accordion-head:after {
	background-color: #DADADA
}

.single-accordion .accordion-head:before {
	content: "\ea99";
	font-family: IcoFont;
	font-size: 16px;
	color: var(--black);
	transform: rotateX(0deg);
	z-index: 1
}

.single-accordion .accordion-body {
	display: none
}

.single-accordion.active {
	background: #FFFFFF;
	box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.07)
}

.single-accordion.active .accordion-head:after {
	background-color: var(--theme-blue-light)
}

.single-accordion.active .accordion-head:before {
	transform: rotateX(180deg);
	color: var(--white)
}

.single-accordion .accordion-body {
	padding: 20px 0px 0px
}

.single-accordion .accordion-body p {
	font-family: var(--ff-secondary);
	font-size: 16px;
	margin-bottom: 15px
}

.single-accordion .accordion-body ul li {
	margin-bottom: 15px;
	padding-left: 15px;
	position: relative
}

.single-accordion .accordion-body ul li:after {
	position: absolute;
	content: "";
	height: 6px;
	width: 6px;
	border-radius: 50%;
	background-color: #555555;
	top: 8px;
	left: 0
}

.single-accordion .accordion-body ul li:last-child {
	margin-bottom: 0px
}

@media all and (max-width: 991px) {
	.cripto-faq-wrapper {
		max-width: 600px;
		margin: 0 auto
	}
	.mt-md-30 {
		margin-top: 30px
	}
}

@media all and (max-width: 575px) {
	.cripto-member-area {
		padding-bottom: 50px
	}
	.cripto-faq-area {
		padding: 50px 0px
	}
	.cripto-faq-area .sec-title h1 {
		font-size: 26px;
		padding: 30px 0px;
		margin-block-end: 10px
	}
	.cripto-faq-area .sec-title h1:before {
		font-size: 100px
	}
	.single-accordion {
		padding: 15px 35px 15px 25px;
		margin-bottom: 20px
	}
	.single-accordion .accordion-head:before,
	.single-accordion .accordion-head:after {
		right: -25px
	}
}

.cripto-contact-area {
	padding: 90px 0px;
	background-color: var(--light-gray2)
}

.cripto-contact-area .sec-title {
	max-width: 800px;
	margin: 0 auto;
	margin-bottom: 50px
}

.cripto-contact-area .sec-title h1 {
	font-size: 30px;
	font-family: var(--ff-secondary);
	padding: 45px 0px;
	margin-bottom: 15px
}

.cripto-contact-area .sec-title h1 span {
	color: var(--theme-blue-light)
}

.cripto-contact-area .sec-title h1::before {
	font-size: 150px;
	text-transform: uppercase
}

.cripto-contact-area .sec-title p {
	font-size: 16px;
	font-family: var(--ff-secondary);
	line-height: 1.5;
	margin-bottom: 0px;
	max-width: 700px;
	margin: 0 auto
}

.cripto-contact-wrapper {
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	align-items: center;
	grid-gap: 0px 30px;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 0 auto
}

.cripto-contact-wrapper .btn {
	border-radius: 5px;
	display: inline-flex
}

.cripto-contact-form {
	grid-column-start: 1;
	grid-column-end: span 2;
	margin-right: 100px
}

.ccf-input-wrapper {
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-gap: 0px 30px;
	grid-template-columns: 1fr 1fr;
	margin: 0 auto
}

.ccf-heading {
	max-width: 480px;
	margin-bottom: 30px
}

.ccf-heading h3 {
	font-size: 18px;
	color: var(--heading);
	font-weight: 600;
	margin-bottom: 10px;
	font-family: var(--ff-secondary)
}

.ccf-heading p {
	line-height: 1.5;
	margin-bottom: 0px;
	font-size: 16px;
	font-family: var(--ff-secondary)
}

.ccf-input-group {
	margin-bottom: 20px
}

.ccf-input-group input,
.ccf-input-group textarea {
	border-radius: 10px;
	background-color: var(--white);
	border: 1px solid #EEEEEE;
	padding: 10px 30px
}

.ccf-input-group input:focus,
.ccf-input-group textarea:focus {
	border-color: var(--theme-blue-light)
}

.ccf-input-group textarea {
	width: 100%;
	height: 125px;
	padding: 15px 30px
}

.ccf-input-group input {
	height: 50px
}

.cripto-address ul {
	position: relative;
	padding-left: 25px;
	margin-bottom: 20px
}

.cripto-address ul:after {
	position: absolute;
	content: "";
	height: 100%;
	width: 2px;
	background-color: var(--theme-blue-light);
	top: 0;
	left: 0
}

.cripto-address ul li {
	font-family: var(--ff-secondary);
	font-size: 16px;
	font-weight: 500;
	color: #666;
	margin-bottom: 15px
}

.cripto-address ul li:last-child {
	margin-bottom: 0px
}

.cripto-address ul li span {
	display: inline-block;
	padding-right: 20px;
	color: var(--theme-blue-light);
	font-size: 16px;
	font-weight: normal
}

@media all and (max-width: 1350px) {
	.cripto-contact-form {
		margin-right: 30px
	}
	.cripto-contact-area {
		padding: 90px 0px 50px
	}
}

@media all and (max-width: 991px) {
	.cripto-contact-area .sec-title h1 {
		padding: 15px 0px
	}
	.cripto-contact-area .sec-title h1::before {
		font-size: 80px
	}
	.cripto-contact-wrapper {
		grid-template-columns: 1fr 1fr;
		align-items: flex-start
	}
	.cripto-address {
		order: 3
	}
	.cripto-contact-form {
		margin-right: 0px
	}
}

@media all and (max-width: 767px) {
	.cripto-contact-wrapper {
		grid-template-columns: 1fr
	}
	.cripto-contact-form {
		grid-column-start: unset;
		grid-column-end: unset
	}
	.cripto-address ul {
		margin-top: 30px;
		margin-bottom: 0px
	}
	.cripto-contact-area {
		padding: 50px 0px
	}
}

@media all and (max-width: 575px) {
	.cripto-contact-area .sec-title h1 {
		padding: 5px 0px
	}
	.cripto-contact-area .sec-title h1::before {
		font-size: 55px
	}
	.ccf-input-wrapper {
		grid-template-columns: 1fr
	}
}

.cripto-footer-area {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: var(--copyright-bg);
	overflow: hidden
}

.cripto-footer-wrapper {
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-gap: 100px;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 0 auto
}

.cripto-ftr-menu-wrapper {
	display: flex
}

.mr-100 {
	margin-right: 100px
}

.cripto-footer-wrapper {
	padding: 190px 0px 100px
}

.cripto-ftr-menu h3 {
	font-size: 20px;
	color: var(--white);
	font-family: var(--ff-secondary);
	font-weight: 600;
	margin-bottom: 20px
}

.cripto-ftr-menu ul li {
	padding-bottom: 10px
}

.cripto-ftr-menu ul li:last-child {
	padding-bottom: 0px
}

.cripto-ftr-menu ul li a {
	font-display: 16px;
	color: var(--white);
	font-family: var(--ff-secondary);
	transition: .4s ease-in-out;
	font-weight: 400;
	font-family: var(--ff-secondary)
}

.cripto-ftr-menu ul li a:hover {
	background: #FF002E;
	background: -webkit-linear-gradient(to left, #FF002E 0%, #FF7A00 100%);
	background: -moz-linear-gradient(to left, #FF002E 0%, #FF7A00 100%);
	background: linear-gradient(to left, #FF002E 0%, #FF7A00 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.cripto-ftr-content {
	text-align: center
}

.cripto-ftr-content p {
	margin-top: 20px;
	line-height: 1.5;
	color: var(--white)
}

.cripto-ftr-content .social-icons {
	margin-bottom: 25px
}

.cripto-ftr-content .social-icons a {
	color: var(--white);
	border: 1px solid var(--white);
	transition: .4s ease-in
}

.cripto-ftr-content .social-icons a:before {
	background: var(--white)
}

.cripto-ftr-content .social-icons a:hover {
	color: var(--theme-blue-dark);
	border-color: transparent
}

.cripto-ftr-content a {
	color: var(--white)
}

.cripto-ftr-subscribe h3 {
	font-size: 20px;
	font-weight: 600;
	font-family: var(--ff-secondary);
	color: var(--white);
	margin-bottom: 5px
}

.cripto-ftr-subscribe p {
	font-family: var(--ff-secondary);
	color: var(--white);
	font-size: 16px
}

.cripto-subscribe {
	border-radius: 50px;
	background-color: var(--white);
	height: 60px;
	position: relative;
	width: 100%;
	overflow: hidden;
	display: flex
}

.cripto-subscribe input[type=email] {
	height: 100%;
	width: 70%;
	border: none;
	background-color: #fff
}

.cripto-subscribe input[type=submit] {
	position: absolute;
	height: calc(100% - 10px);
	top: 5px;
	right: 8px;
	background: linear-gradient(90deg, #FF002E 0%, #FF7A00 100%);
	border: none;
	color: var(--white);
	border-radius: 50px;
	padding: 10px 25px;
	line-height: 1
}

.cripto-sub-footer {
	background-color: var(--theme-blue-dark);
	width: 100%
}

.cripto-sub-footer {
	padding: 20px 0px
}

.cripto-sub-footer p {
	text-align: center;
	color: var(--white);
	margin-bottom: 0px;
	font-family: var(--ff-secondary)
}

@media all and (max-width: 1350px) {
	.cripto-footer-wrapper {
		padding: 150px 0px 50px;
		display: flex;
		grid-gap: 0px;
		flex-wrap: wrap
	}
	.cripto-ftr-menu-wrapper {
		order: 2;
		width: 50%;
		justify-content: center
	}
	.cripto-ftr-content {
		order: 1;
		width: 80%;
		margin: 0 auto;
		margin-bottom: 50px
	}
	.cripto-ftr-content p {
		max-width: 500px;
		margin: 0 auto;
		margin-bottom: 30px
	}
	.cripto-ftr-subscribe {
		width: 450px;
		order: 3;
		margin: 0 auto
	}
}

@media all and (max-width: 991px) {
	.cripto-ftr-subscribe {
		width: calc(50% - 50px);
		margin: 0 auto
	}
	.cripto-footer-area {
		background-position: left
	}
	.cripto-footer-wrapper {
		padding: 100px 0px 50px
	}
}

@media all and (max-width: 767px) {
	.cripto-footer-wrapper {
		flex-direction: column
	}
	.cripto-ftr-menu-wrapper {
		margin: 0 auto
	}
	.cripto-ftr-subscribe {
		width: 450px;
		margin-top: 30px
	}
	.cripto-footer-area {
		background-position: inherit
	}
}

@media all and (max-width: 575px) {
	.cripto-sub-footer p {
		text-align: center;
		font-size: 15px
	}
	.cripto-ftr-menu-wrapper {
		width: 100%
	}
	.cripto-ftr-content {
		width: 100%;
		max-width: 475px
	}
	.cripto-ftr-subscribe {
		width: 100%;
		max-width: 450px
	}
	.cripto-footer-wrapper {
		padding: 80px 0px 50px
	}
	.cripto-address ul {
		padding-left: 15px
	}
	.cripto-address ul li {
		font-size: 15px
	}
	.cripto-address ul li span {
		padding-right: 10px;
		font-size: 12px
	}
	.ccf-heading p {
		font-size: 15px
	}
	.cripto-contact-area .sec-title p {
		font-size: 15px
	}
}

#dash-animi {
	stroke-dasharray: 5000;
	stroke-dashoffset: 0;
	-webkit-animation: dash 2s infinite alternate;
	-o-animation: dash 2s infinite alternate;
	-moz-animation: dash 2s infinite alternate;
	animation: dash 5s infinite alternate
}

#banner-animi {
	fill: none;
	stroke-width: 1px;
	stroke-miterlimit: 1px;
	stroke: #0a1491
}

@keyframes dash {
	from {
		stroke-dashoffset: 5000
	}
	to {
		stroke-dashoffset: 0
	}
}

#dash-animi2 {
	stroke-dasharray: 10000;
	stroke-dashoffset: 0;
	-webkit-animation: dash2 5s infinite alternate;
	-o-animation: dash2 5s infinite alternate;
	-moz-animation: dash2 5s infinite alternate;
	animation: dash2 5s infinite alternate
}

#banner-animi2 {
	fill: none;
	stroke-width: 1px;
	stroke-miterlimit: 1px;
	stroke: rgba(255, 255, 255, 0.7)
}

@keyframes dash2 {
	from {
		stroke-dashoffset: 10000
	}
	to {
		stroke-dashoffset: 0
	}
}

.cripto-about-img,
.cripto-works-img,
.cripto-ico-image {
	animation: moveX 1s infinite alternate
}

@keyframes moveX {
	0% {
		transform: translateY(0px)
	}
	100% {
		transform: translateY(-15px)
	}
}

.landing-header-area {
	background-color: rgba(255, 255, 255, 0.1);
	padding: 0px 30px;
	border-radius: 150px;
	border: 1px solid #00FFC2
}

.landing-header-area .h-menu li a {
	color: var(--white);
	position: relative
}

.landing-header-area .h-menu li a:after {
	position: absolute;
	content: "";
	bottom: -10px;
	width: 30px;
	left: 50%;
	transform: translateX(-50%);
	height: 4px;
	border-radius: 50px;
	background: linear-gradient(90deg, #00B2FF 0%, #00FFC2 100%);
	opacity: 0;
	visibility: visible;
	transition: .4s ease-in
}

.landing-header-area .h-menu li a:hover,
.landing-header-area .h-menu li a.active {
	color: var(--white)
}

.landing-header-area .h-menu li a:hover:after,
.landing-header-area .h-menu li a.active:after {
	bottom: -2px;
	opacity: 1;
	visibility: visible
}

.landing-header-area .short-code a {
	border-radius: 50px;
	color: var(--theme-blue);
	font-size: 16px;
	line-height: 1.4
}

.bitcoin-banner-sec {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center
}

.bitcoin-banner-wrap {
	padding-top: 30px
}

.bitcoin-banner-wrap.sticky {
	position: fixed;
	top: 0;
	left: 50%;
	z-index: 99;
	width: 100%;
	transform: translateX(-50%);
	padding: 0px 100px;
	max-width: 1700px
}

.bitcoin-banner-wrap.sticky .landing-header-area {
	background: var(--bit-gradient);
	border: none
}

.ico-area {
	padding: 130px 0px;
	position: relative;
	z-index: 1;
	overflow: hidden
}

.ico-content {
	max-width: 700px
}

.ico-content h1 {
	font-size: 75px;
	font-weight: 300;
	font-family: var(--ff-secondary);
	color: var(--white);
	margin-bottom: 15px
}

.ico-content h1 span {
	display: inline-block;
	margin: 0px 5px;
	font-weight: 600
}

.ico-content p {
	font-weight: 600;
	font-size: 30px;
	line-height: 1.5px;
	font-family: var(--ff-secondary);
	color: var(--white)
}

.ico-content p:nth-child(1) {
	margin-bottom: 30px
}

.ico-content h5 {
	font-weight: 600;
	font-size: 48px;
	font-family: var(--ff-secondary);
	color: var(--white);
	margin-bottom: 35px;
	padding-top: 10px
}

.gradient-cripto-counter {
	margin: 0px;
	padding: 50px 0px;
	max-width: 600px
}

.gradient-cripto-counter .countdown-clock {
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr 1fr 1fr
}

.gradient-cripto-counter .cripto-item .ci-inner {
	height: 150px;
	width: 150px;
	border-style: solid;
	border-width: 1px;
	border-image: radial-gradient(#00B2FF, #00FFC2) 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column
}

.gradient-cripto-counter .cripto-item .ci-inner div {
	font-size: 64px;
	line-height: 1;
	width: auto;
	height: auto;
	background-color: transparent;
	background: var(--success-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.gradient-cripto-counter .cripto-item .ci-inner h4 {
	font-size: 20px;
	margin-bottom: 0px;
	font-family: var(--ff-secondary);
	font-weight: 300;
	color: var(--white)
}

.ico-animation-img-wrapper {
	width: 60%;
	position: absolute;
	right: -95px;
	bottom: -50px;
	z-index: -1;
	height: 100%
}

.ico-animation-img-wrapper .ico-ani2 {
	position: absolute;
	top: calc(50% - 280px);
	left: calc(50% - 180px);
	z-index: 9;
	animation: icomoving 1s infinite .5s alternate
}

.ico-animation-img-wrapper .ico-ani3 {
	position: absolute;
	top: calc(50% - 230px);
	left: calc(50% - 190px);
	height: 300px;
	animation: icomoving 1s infinite alternate
}

.ico-animation-img-wrapper .ico-ani1 {
	position: absolute;
	height: 100%;
	width: 100%;
	animation: icoscale 1s infinite alternate
}

@keyframes icomoving {
	0% {
		transform: translate(0px, 0px)
	}
	100% {
		transform: translate(20px, 20px)
	}
}

@keyframes icoscale {
	0% {
		transform: scale(1)
	}
	100% {
		transform: scale(0.97)
	}
}

@media all and (max-width: 1199px) {
	.landing-header-area {
		background: var(--bit-gradient);
		border: none
	}
	.landing-header-area .menu-trigger span {
		background: var(--success-gradient)
	}
	.landing-header-area .menu-close i {
		background: var(--success-gradient);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent
	}
	.landing-header-area .mobile-menu-wrap {
		background: var(--theme-blue2)
	}
	.landing-header-area .h-menu li a:hover:after,
	.landing-header-area .h-menu li a.active:after {
		display: none
	}
	.ico-content h1 {
		font-size: 50px
	}
	.ico-content p {
		font-weight: 500;
		font-size: 20px
	}
	.gradient-cripto-counter {
		padding: 30px 0px;
		max-width: 470px
	}
	.gradient-cripto-counter .cripto-item .ci-inner {
		height: 100px;
		width: 100px
	}
	.gradient-cripto-counter .cripto-item .ci-inner div {
		font-size: 40px
	}
	.gradient-cripto-counter .cripto-item .ci-inner h4 {
		font-size: 15px
	}
	.ico-content h5 {
		font-size: 35px;
		margin-bottom: 25px
	}
	.ico-animation-img-wrapper {
		width: 75%
	}
	.ico-animation-img-wrapper .ico-ani3 {
		top: calc(50% - 160px);
		left: calc(50% - 115px);
		height: 200px
	}
	.ico-animation-img-wrapper .ico-ani2 {
		top: calc(50% - 210px);
		left: calc(50% - 130px);
		width: 120px
	}
}

@media all and (max-width: 991px) {
	.ico-animation-img-wrapper {
		right: -200px;
		bottom: -125px;
		width: 100%
	}
	.ico-animation-img-wrapper .ico-ani3 {
		top: calc(50% - 120px);
		left: calc(50% - 100px);
		height: 160px
	}
	.ico-animation-img-wrapper .ico-ani2 {
		top: calc(50% - 180px);
		left: calc(50% - 130px);
		width: 100px
	}
}

@media all and (max-width: 767px) {
	.ico-animation-img-wrapper {
		right: -100px;
		bottom: -225px
	}
	.ico-area {
		padding: 50px 0px 250px
	}
}

@media all and (max-width: 575px) {
	.ico-animation-img-wrapper {
		display: none
	}
	.ico-area {
		padding: 50px 0px
	}
	.gradient-cripto-counter .countdown-clock {
		width: 100%;
		justify-content: flex-start
	}
	.gradient-cripto-counter .cripto-item .ci-inner {
		height: 80px;
		width: 80px
	}
	.gradient-cripto-counter .cripto-item .ci-inner div {
		font-size: 25px;
		margin-bottom: 10px
	}
	.gradient-cripto-counter .cripto-item .ci-inner h4 {
		font-size: 14px
	}
	.ico-content h1 {
		font-size: 30px
	}
	.ico-content p {
		font-weight: 500;
		font-size: 16px
	}
}

@media all and (max-width: 415px) {
	.gradient-cripto-counter .countdown-clock {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap
	}
}

.bitcoin-about-area {
	padding: 100px 0px 50px;
	background: var(--bit-gradient);
	position: relative;
	overflow: hidden;
	z-index: 1
}

.bitcoin-about-area .bit-about-overlay {
	position: absolute;
	top: 20px;
	left: -45px;
	height: 100%;
	width: 100%;
	pointer-events: none;
	z-index: -1
}

.bitcoin-about-area .bit-about-overlay img,
.bitcoin-about-area .bit-about-overlay svg {
	width: 100%
}

.bitcoin-about-wrapper {
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	align-items: center;
	grid-gap: 100px;
	grid-template-columns: 1fr 1fr;
	margin: 0 auto
}

.bit-sub {
	font-weight: bold;
	font-size: 24px;
	line-height: 1.4;
	margin-bottom: 2px;
	position: relative;
	padding-right: 25px;
	display: inline-block;
	background: var(--success-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.bit-sub:after {
	position: absolute;
	content: "";
	top: 50%;
	right: 0;
	height: 3px;
	width: 20px;
	background: var(--success-gradient);
	transform: translateY(-50%)
}

.bit-title {
	font-weight: 300;
	font-size: 36px;
	line-height: 1.4;
	color: var(--white);
	margin-bottom: 15px
}

.bitcoin-about-content {
	width: calc(100% - 80px)
}

.bitcoin-about-content>h3 {
	font-weight: 600;
	font-size: 24px;
	line-height: 1.4;
	color: var(--white);
	margin-bottom: 15px
}

.bitcoin-about-content p {
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
	color: var(--white);
	margin-bottom: 10px
}

.bitcoin-about-content ul {
	padding-left: 20px;
	max-width: 430px
}

.bitcoin-about-content ul li {
	list-style: disc;
	font-weight: normal;
	font-size: 16px;
	line-height: 160%;
	color: #FFFFFF;
	margin-bottom: 15px
}

.bitcoin-about-content ul li:last-child {
	margin-bottom: 0px
}

.relative {
	position: relative
}

.bit-data-watermark {
	position: absolute;
	top: 50%;
	transform: translateY(-50%) rotate(-90deg);
	right: calc(0px - 280px);
	pointer-events: none
}

.bit-data-watermark h2 {
	font-weight: bold;
	font-size: 150px;
	color: var(--white-water-color);
	line-height: 0.9;
	margin-bottom: 0px;
	text-transform: uppercase
}

.moving-y {
	animation: moving-y infinite 1s alternate
}

.delay-0 {
	animation-delay: 1.3s
}

.delay-1 {
	animation-delay: 1.5s
}

.delay-2 {
	animation-delay: 1.7s
}

.delay-3 {
	animation-delay: 1.9s
}

.delay-4 {
	animation-delay: 2.1s
}

.delay-5 {
	animation-delay: 2.3s
}

.delay-6 {
	animation-delay: 2.5s
}

.delay-7 {
	animation-delay: 2.7s
}

.delay-8 {
	animation-delay: 2.9s
}

.delay-9 {
	animation-delay: 3.1s
}

@keyframes moving-y {
	0% {
		transform: translateY(-15px)
	}
	100% {
		transform: translateY(15px)
	}
}

#dash-animi3 {
	stroke-dasharray: 900;
	stroke-dashoffset: 0;
	-webkit-animation: dash3 5s infinite alternate;
	-o-animation: dash3 5s infinite alternate;
	-moz-animation: dash3 5s infinite alternate;
	animation: dash3 5s infinite alternate
}

@keyframes dash3 {
	from {
		stroke-dashoffset: 1500
	}
	to {
		stroke-dashoffset: 0
	}
}

@media all and (max-width: 1550px) {
	.bit-data-watermark {
		right: calc(0px - 130px)
	}
	.bit-data-watermark h2 {
		font-size: 90px
	}
}

@media all and (max-width: 1350px) {
	.bitcoin-about-wrapper {
		grid-gap: 50px
	}
}

@media all and (max-width: 991px) {
	.bitcoin-about-wrapper {
		grid-template-columns: 1fr
	}
	.bitcoin-about-img {
		order: 2
	}
	.bit-data-watermark {
		right: unset;
		top: -10px;
		transform: translate(0%) rotate(0deg);
		left: 0
	}
	.bitcoin-about-area .bit-sub {
		padding: 15px 25px 15px 0px
	}
	.bitcoin-about-img {
		max-width: 500px;
		margin: 0 auto
	}
	.bitcoin-about-img svg {
		height: auto
	}
}

@media all and (max-width: 575px) {
	.bit-title {
		font-size: 30px
	}
	.bitcoin-about-content>h3 {
		font-size: 20px
	}
}

.cripto-batch-area {
	background-color: var(--theme-blue2);
	padding: 50px 0px
}

.cripto-batch-wrapper {
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-gap: 30px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin: 0 auto
}

.cripto-single-batch {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	border: 1px solid #5D7DEF;
	border-radius: 10px;
	padding: 30px
}

.cripto-single-batch h3 {
	background: var(--success-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 600;
	font-size: 30px;
	line-height: 1.5;
	margin-bottom: 5px
}

.cripto-single-batch p {
	font-weight: 400;
	font-size: 20px;
	line-height: 1.5;
	text-align: center;
	color: var(--white);
	margin-bottom: 0px
}

.cripto-services-area {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 120px 0px
}

.cripto-services-heading {
	max-width: 690px;
	margin: 0 auto 50px
}

.cripto-services-heading h2 {
	margin-bottom: 10px
}

.cripto-services-heading p {
	color: var(--white);
	margin-bottom: 0px
}

.cripto-services-content {
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-gap: 30px;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 0 auto
}

.cripto-services-single {
	padding: 30px 40px;
	border-radius: 10px;
	border: 1px solid var(--theme-sky);
	text-align: center
}

.cs-single-img {
	width: 50px;
	height: 50px;
	margin: 0 auto 15px;
	display: flex;
	justify-content: center;
	align-items: center
}

.cs-single-img img {
	max-width: 100%;
	max-height: 100%
}

.cs-single-sontent h3 {
	font-weight: 600;
	font-size: 20px;
	line-height: 1.4;
	color: var(--white);
	margin-bottom: 10px
}

.cs-single-sontent p {
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 0px;
	color: var(--white)
}

.bit-data-watermark.cripto-services-water {
	right: calc(100% - 175px)
}

@media all and (max-width: 991px) {
	.cripto-batch-wrapper {
		grid-template-columns: 1fr 1fr;
		grid-gap: 15px
	}
}

@media all and (max-width: 450px) {
	.cripto-batch-wrapper {
		grid-template-columns: 1fr
	}
}

@media all and (max-width: 1750px) {
	.cripto-services-area .bit-data-watermark {
		right: unset;
		top: -10px;
		left: 50%;
		transform: translate(-50%, 0%) rotate(0deg)
	}
	.cripto-services-area .bit-data-watermark h2 {
		font-size: 90px
	}
	.cripto-services-area .bit-sub {
		font-size: 24px;
		padding: 15px 25px 15px 0px
	}
}

@media all and (max-width: 1199px) {
	.cripto-services-area {
		padding: 80px 0px
	}
}

@media all and (max-width: 991px) {
	.cripto-services-content {
		grid-template-columns: 1fr 1fr
	}
}

@media all and (max-width: 767px) {
	.cripto-services-area .bit-data-watermark h2 {
		font-size: 65px
	}
	.cripto-services-area .bit-sub {
		font-size: 24px;
		padding: 5px 25px 5px 0px
	}
	.cripto-services-area .bit-title {
		font-size: 25px;
		margin-bottom: 10px
	}
	.cripto-services-heading p {
		font-size: 16px
	}
	.cripto-services-single {
		padding: 25px 15px
	}
	.cripto-services-single .cs-single-img {
		width: 35px;
		height: 40px;
		margin: 0 auto 10px
	}
}

@media all and (max-width: 575px) {
	.cripto-services-content {
		grid-template-columns: 1fr
	}
	.cripto-services-single {
		padding: 25px 15px;
		max-width: 350px;
		margin: 0 auto
	}
	.cripto-services-area {
		padding: 50px 0px
	}
	.bitcoin-about-area {
		padding: 50px 0px
	}
	.cripto-join-area {
		margin: 50px auto 0
	}
}

.cripto-price-table {
	padding: 110px 0px;
	background: var(--bit-gradient);
	position: relative;
	z-index: 1
}

.cripto-price-heading {
	max-width: 650px;
	margin: 0 auto 50px
}

.cripto-price-heading h2 {
	margin-bottom: 10px
}

.cripto-price-heading p {
	color: var(--white);
	line-height: 1.5;
	margin-bottom: 0px
}

.cripto-price-wrapper {
	display: grid;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-gap: 30px;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 0 auto
}

.cripto-single-table {
	padding: 50px 70px;
	background: var(--theme-blue3);
	border-radius: 10px;
	border: 1px solid var(--theme-blue3);
	transition: .4s ease-in;
	position: relative
}

.cripto-single-table .btn {
	margin-top: 25px
}

.cripto-single-table .cst-price {
	position: absolute;
	top: 50px;
	right: 0px;
	padding: 10px 35px;
	border-radius: 50px 0px 0px 50px;
	background: var(--bit-gradient2);
	font-weight: 600;
	font-size: 36px;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--white);
	transition: .4s ease-in;
	overflow: hidden;
	z-index: 1
}

.cripto-single-table .cst-price:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: var(--success-gradient);
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	transform: translateX(50px);
	transition: .4s ease-in
}

.cripto-single-table .cst-price span {
	font-size: 25px;
	display: block;
	padding: 0px 2px
}

.cripto-single-table.active .btn {
	background: var(--success-gradient);
	border-color: var(--theme-blue3);
	color: var(--theme-blue3)
}

.cripto-single-table.active .btn:hover {
	border-color: var(--theme-blue3)
}

.cripto-single-table.active,
.cripto-single-table:hover {
	border: 1px solid var(--theme-sky)
}

.cripto-single-table.active .cst-price,
.cripto-single-table:hover .cst-price {
	color: var(--theme-blue3)
}

.cripto-single-table.active .cst-price:after,
.cripto-single-table:hover .cst-price:after {
	opacity: 1;
	visibility: visible;
	transform: translateX(0px)
}

.cripto-single-table:hover {
	transform: translateY(-10px) !important
}

.cripto-single-table:hover .btn {
	border-color: transparent
}

.cripto-single-table:hover .btn:after {
	opacity: 1;
	visibility: visible;
	transform: translateY(0px)
}

.cst-heading {
	position: relative
}

.cst-heading h3 {
	display: inline-block;
	font-weight: 600;
	font-size: 30px;
	background: var(--success-gradient2);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin-bottom: 5px
}

.cst-heading p {
	font-weight: 600;
	font-size: 18px;
	line-height: 1.5;
	padding-bottom: 10px;
	position: relative;
	margin-bottom: 30px;
	color: var(--white)
}

.cst-heading p:after {
	position: absolute;
	content: "";
	bottom: 0;
	left: 0;
	height: 3px;
	width: 20px;
	background: var(--success-gradient);
	transform: translateY(-50%)
}

.cst-list li {
	font-size: 16px;
	padding: 15px 0px;
	border-bottom: 1px solid #0C2D9E;
	color: var(--white)
}

.cst-list li.active {
	background: var(--success-gradient2);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.cst-list li:last-child {
	border-bottom: none
}

.cripto-price-table .bit-data-watermark {
	right: calc(0px - 350px)
}

.cripto-join-area {
	max-width: 920px;
	margin: 70px auto 0;
	padding: 20px 30px;
	background: var(--theme-blue3);
	border: 1px solid #5D7DEF;
	box-sizing: border-box;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap
}

.cripto-join-area .btn {
	border-radius: 4px
}

.cripto-join-content {
	flex: 1
}

.cripto-join-content h3 {
	font-weight: 600;
	font-size: 30px;
	margin-bottom: 5px;
	line-height: 1;
	color: var(--white);
	font-family: var(--ff-primary)
}

.cripto-join-content p {
	font-family: var(--ff-primary);
	margin-bottom: 0px;
	font-size: 16px;
	color: var(--white);
	font-weight: 400
}

.cripto-price-shape {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: -1
}

@media all and (max-width: 1350px) {
	.cripto-single-table .cst-price {
		padding: 10px 25px;
		font-size: 25px;
		top: 100px
	}
	.cripto-single-table {
		padding: 50px 40px
	}
	.cripto-price-table {
		padding: 80px 0px
	}
}

@media all and (max-width: 1750px) {
	.cripto-price-table .bit-data-watermark {
		right: unset;
		top: -10px;
		left: 50%;
		transform: translate(-50%, 0%) rotate(0deg)
	}
	.cripto-price-table .bit-data-watermark h2 {
		font-size: 90px
	}
	.cripto-price-table .bit-sub {
		font-size: 24px;
		padding: 15px 25px 15px 0px
	}
}

@media all and (max-width: 1500px) {
	.bitcoin-banner-wrap {
		padding-top: 30px
	}
	.bitcoin-banner-wrap.sticky {
		padding: 0px 15px
	}
}

@media all and (max-width: 1199px) {
	.cripto-single-table {
		padding: 25px 20px
	}
	.cst-heading h3 {
		font-size: 27px
	}
	.cst-heading p {
		font-weight: 500;
		font-size: 16px
	}
	.cripto-single-table .cst-price {
		top: 60px
	}
}

@media all and (max-width: 991px) {
	.cripto-price-wrapper {
		grid-template-columns: 1fr 1fr
	}
}

@media all and (max-width: 767px) {
	.cripto-price-wrapper {
		grid-template-columns: 1fr;
		max-width: 360px
	}
	.cripto-join-area {
		align-items: flex-start;
		flex-direction: column
	}
	.cripto-join-content {
		margin-bottom: 20px
	}
}

@media all and (max-width: 575px) {
	.cripto-price-table {
		padding: 50px 0px
	}
	.cripto-price-table .bit-data-watermark h2 {
		font-size: 70px
	}
	.cripto-price-table .bit-sub {
		font-size: 24px;
		padding: 5px 25px 5px 0px
	}
	.cripto-price-table .bit-title {
		font-size: 25px
	}
	.cripto-join-content h3 {
		font-size: 25px;
		margin-bottom: 5px
	}
}

.cripto-journey-area {
	padding: 110px 0px 0px;
	background: var(--bit-gradient)
}

.cripto-journey-heading {
	max-width: 690px;
	margin: 0 auto 50px
}

.cripto-journey-heading h3 {
	margin-bottom: 0px
}

.cripto-journey-heading p {
	font-size: 16px;
	color: var(--white);
	line-height: 1.5;
	margin-bottom: 0px
}

.cripto-journey-wrapper {
	position: relative;
	z-index: 1
}

.cripto-journey-wrapper:after {
	position: absolute;
	content: "";
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	height: 2px;
	width: 100%;
	background-color: var(--white);
	z-index: -1
}

.cripto-journey-wrapper ul {
	display: flex;
	justify-content: space-between;
	position: relative
}

.cripto-journey-wrapper ul:after,
.cripto-journey-wrapper ul:before {
	position: absolute;
	content: "";
	top: 50%;
	transform: translateY(-50%);
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background: var(--success-gradient);
	z-index: 1
}

.cripto-journey-wrapper ul:after {
	left: 0
}

.cripto-journey-wrapper ul:before {
	right: 0
}

.cripto-journey-wrapper ul li {
	position: relative;
	text-align: center
}

.cripto-journey-wrapper ul li span {
	display: block;
	font-weight: 500;
	font-size: 15px;
	line-height: 1.5;
	margin-bottom: 5px;
	color: var(--white)
}

.cripto-journey-wrapper ul li span:after {
	position: absolute;
	content: "";
	height: 60px;
	width: 1px;
	border: 1px dashed var(--white);
	left: 50%;
	transform: translateY(-50%)
}

.cripto-journey-wrapper ul li h3 {
	font-weight: 600;
	font-size: 20px;
	color: var(--white);
	margin-bottom: 0px
}

.cripto-journey-wrapper ul li:after {
	position: absolute;
	content: "";
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	height: 35px;
	width: 35px;
	border-radius: 50%;
	background: var(--success-gradient);
	z-index: 1
}

.cripto-journey-wrapper ul li:before {
	position: absolute;
	content: "";
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background: var(--theme-blue3);
	z-index: 2
}

.cripto-journey-wrapper ul li:nth-child(odd) {
	padding-top: 200px
}

.cripto-journey-wrapper ul li:nth-child(odd) span:after {
	bottom: 35px
}

.cripto-journey-wrapper ul li:nth-child(even) span:after {
	bottom: 100px
}

@media all and (max-width: 1750px) {
	.cripto-journey-area .cripto-journey-water.bit-data-watermark {
		right: unset;
		top: 100px;
		left: 50%;
		transform: translate(-50%, 0%) rotate(0deg)
	}
	.cripto-journey-area .cripto-journey-water.bit-data-watermark h2 {
		font-size: 90px
	}
	.cripto-journey-area .bit-sub {
		font-size: 24px;
		padding: 15px 25px 15px 0px
	}
}

@media all and (max-width: 991px) {
	.cripto-journey-wrapper ul {
		padding-top: 50px;
		flex-direction: column
	}
	.cripto-journey-wrapper ul:before,
	.cripto-journey-wrapper ul:after {
		top: 0;
		transform: translate(-50%, 0%);
		left: 50%
	}
	.cripto-journey-wrapper ul::before {
		right: unset
	}
	.cripto-journey-wrapper ul::after {
		bottom: 0 !important;
		top: unset
	}
	.cripto-journey-wrapper ul li {
		margin-bottom: 50px
	}
	.cripto-journey-wrapper ul li:nth-child(odd) {
		padding-top: 0px;
		padding-right: 380px
	}
	.cripto-journey-wrapper ul li:nth-child(odd) span:after {
		left: calc(50% - 50px)
	}
	.cripto-journey-wrapper ul li:nth-child(even) {
		padding-top: 0px;
		padding-left: 380px
	}
	.cripto-journey-wrapper ul li:nth-child(even) span:after {
		left: calc(50% + 50px)
	}
	.cripto-journey-wrapper ul li span:after {
		height: 1px;
		width: 50px;
		left: 50%;
		transform: translate(-50%, -50%);
		top: 50%
	}
	.cripto-journey-wrapper:after {
		top: 50%;
		transform: translate(0%, -50%);
		left: 50%;
		height: 100%;
		width: 2px
	}
	.cripto-journey-area {
		padding: 80px 0px 0px
	}
	.cripto-journey-area .cripto-journey-water.bit-data-watermark {
		top: 70px
	}
	.cripto-journey-area .bit-title {
		font-size: 30px;
		margin-bottom: 10px
	}
}

@media all and (max-width: 767px) {
	.cripto-journey-wrapper ul li:before {
		height: 15px;
		width: 15px
	}
	.cripto-journey-wrapper ul li:after {
		height: 25px;
		width: 25px
	}
	.cripto-journey-wrapper ul li:nth-child(odd) {
		padding-right: 300px
	}
	.cripto-journey-wrapper ul li:nth-child(odd) span:after {
		left: calc(50% - 30px)
	}
	.cripto-journey-wrapper ul li:nth-child(even) {
		padding-left: 300px
	}
	.cripto-journey-wrapper ul li:nth-child(even) span:after {
		left: calc(50% + 30px)
	}
	.cripto-journey-wrapper ul li span:after {
		width: 25px
	}
	.blockchain-area {
		padding: 50px 15px 70px !important
	}
	.block-heading {
		max-width: 550px;
		margin: 0 auto 30px
	}
}

@media all and (max-width: 575px) {
	.cripto-journey-wrapper {
		padding-left: 15px
	}
	.cripto-journey-wrapper:after {
		top: 0;
		transform: translate(0%, 0%);
		left: 14px
	}
	.cripto-journey-wrapper ul {
		justify-content: flex-start;
		align-items: flex-start
	}
	.cripto-journey-wrapper ul:before,
	.cripto-journey-wrapper ul:after {
		transform: translate(-50%, 0%);
		left: 0
	}
	.cripto-journey-wrapper ul li {
		padding-left: 50px !important
	}
	.cripto-journey-wrapper ul li:before,
	.cripto-journey-wrapper ul li:after {
		left: 0;
		top: 50%;
		transform: translate(-50%, -50%)
	}
	.cripto-journey-wrapper ul li:nth-child(odd) {
		padding-right: 0px
	}
	.cripto-journey-wrapper ul li:nth-child(odd) span:after {
		left: 0;
		transform: translate(0%, -50%)
	}
	.cripto-journey-wrapper ul li:nth-child(even) {
		padding-left: 0px
	}
	.cripto-journey-wrapper ul li:nth-child(even) span:after {
		left: 0;
		transform: translate(0%, -50%)
	}
	.cripto-journey-wrapper ul li span {
		text-align: left
	}
	.cripto-journey-wrapper ul li h3 {
		text-align: left
	}
	.cripto-journey-area {
		padding: 50px 0px
	}
	.cripto-journey-area .cripto-journey-water.bit-data-watermark {
		top: 50px
	}
	.cripto-journey-area .cripto-journey-water.bit-data-watermark h2 {
		font-size: 50px
	}
	.cripto-journey-area .bit-sub {
		padding: 5px 25px 5px 0px
	}
}

.blockchain-area {
	padding: 70px 0px 110px;
	position: relative;
	z-index: 1
}

.blockchain-area .blockchain-overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
	pointer-events: none
}

.blockchain-area .blockchain-overlay img {
	height: 100%;
	object-fit: contain
}

.blockchain-wrapper {
	max-width: 720px;
	margin: 0 auto
}

.block-heading {
	text-align: center;
	margin-bottom: 30px
}

.block-heading h3 {
	font-weight: 600;
	font-size: 24px;
	line-height: 1.4;
	margin-bottom: 5px;
	background: var(--success-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.block-heading p {
	color: var(--white);
	margin-bottom: 0px;
	line-height: 1.5
}

.blockchain-video {
	position: relative
}

.blockchain-video img {
	width: 100%
}

.blockchain-video .blockchain-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%)
}

.cripto-team-member {
	padding: 110px 0px;
	background: var(--bit-gradient);
	position: relative;
	z-index: 1
}

.bit-data-watermark.cripto-journey-water {
	right: unset;
	left: -200px
}

.cripto-team-heading {
	max-width: 690px;
	margin: 0 auto 50px
}

.cripto-team-heading h2 {
	margin-bottom: 5px
}

.cripto-team-heading p {
	color: var(--white);
	line-height: 1.5;
	margin-bottom: 0px
}

.cripto-team-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap
}

.one-third {
	width: calc(100% / 3 - 20px)
}

.cripto-team-single {
	margin: 10px;
	position: relative;
	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
	transform: translateY(0px);
	transition: .4s ease-in-out
}

.cripto-team-single:nth-child(4),
.cripto-team-single:nth-child(5) {
	margin-top: -200px
}

.cripto-team-single img {
	width: 100%;
	height: 100%;
	object-fit: cover
}

.cripto-team-single .ct-single-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--bit-gradient-rgba);
	clip-path: polygon(50% 0%, 50% 0, 0 50%, 0% 50%);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transition: .4s ease-in;
	opacity: 0
}

.cripto-team-single .ct-single-overlay:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	clip-path: polygon(50% 0, 0 50%, 50% 100%, 50% .5%, 99.5% 50%, 50% 99.5%, .5% 50%, 50% .5%, 50% 100%, 100% 50%);
	background: var(--success-gradient)
}

.cripto-team-single .ct-single-overlay h4 {
	background: var(--success-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 26px;
	margin-bottom: 5px;
	line-height: 1;
	transition-delay: .3s !important
}

.cripto-team-single .ct-single-overlay p {
	background: var(--success-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin-bottom: 15px;
	font-size: 18px;
	transition-delay: .5s !important
}

.cripto-team-single .ct-single-overlay ul {
	display: flex;
	align-items: center;
	transition-delay: .7s !important
}

.cripto-team-single .ct-single-overlay ul li {
	margin: 0px 7px
}

.cripto-team-single .ct-single-overlay ul li a {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 35px;
	width: 35px;
	border-radius: 50%;
	border: 1px solid var(--theme-sky);
	color: var(--white);
	position: relative;
	z-index: 1;
	overflow: hidden;
	transition: .4s ease-in
}

.cripto-team-single .ct-single-overlay ul li a i {
	font-size: 15px
}

.cripto-team-single .ct-single-overlay ul li a:after {
	position: absolute;
	content: "";
	height: 0%;
	width: 100%;
	background: var(--success-gradient);
	z-index: -1;
	overflow: hidden;
	top: 0;
	left: 0;
	transition: .4s
}

.cripto-team-single .ct-single-overlay ul li a:hover {
	border-color: transparent
}

.cripto-team-single .ct-single-overlay ul li a:hover:after {
	height: 100%;
	top: auto;
	bottom: 0
}

.cripto-team-single .ct-single-overlay h4,
.cripto-team-single .ct-single-overlay p,
.cripto-team-single .ct-single-overlay ul {
	opacity: 0;
	transition: .4s ease-in-out;
	transform: translateY(50px)
}

.cripto-team-single:hover {
	transform: translateY(-10px)
}

.cripto-team-single:hover .ct-single-overlay {
	opacity: 1;
	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)
}

.cripto-team-single:hover .ct-single-overlay h4,
.cripto-team-single:hover .ct-single-overlay p,
.cripto-team-single:hover .ct-single-overlay ul {
	opacity: 1;
	transform: translateY(0px)
}

.cripto-line-mark {
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: -1
}

.cripto-faq-area {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 110px 0px
}

.cripto-faq-heading {
	max-width: 690px;
	margin: 0 auto 50px
}

.cripto-faq-heading p {
	color: var(--white);
	line-height: 1.5;
	margin-bottom: 0px
}

.cripto-single-accordion {
	max-width: 720px;
	margin: 0 auto 10px;
	border-radius: 10px;
	border: 1px solid var(--theme-sky);
	background-color: transparent;
	transition: .4s ease-in
}

.cripto-single-accordion:last-child {
	margin-bottom: 0px
}

.cripto-single-accordion.active {
	background: var(--white-water-color)
}

.cripto-single-accordion.active .cripto-accordion-head {
	padding-top: 45px
}

.cripto-single-accordion.active .cripto-accordion-head::after {
	content: "\ef9a";
	top: 45px
}

.cripto-single-accordion.active .cripto-accordion-head h6 {
	background: var(--success-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.cripto-accordion-head {
	padding: 25px 50px;
	transition: .4s ease-in-out;
	position: relative;
	cursor: pointer
}

.cripto-accordion-head h6 {
	margin-bottom: 0px;
	line-height: 1.5;
	color: var(--white);
	font-weight: 500;
	font-size: 18px;
	font-family: var(--ff-secondary);
	transition: .4s ease-in-out
}

.cripto-accordion-head:after {
	position: absolute;
	content: "\efc2";
	font-family: IcoFont;
	top: 25px;
	right: 25px;
	font-size: 18px;
	background: var(--success-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	transition: .4s ease-in-out
}

.cripto-accordion-body {
	padding: 0px 50px 45px;
	border-radius: 10px;
	display: none
}

.cripto-accordion-body p {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	color: var(--white);
	margin-bottom: 25px;
	font-family: var(--ff-secondary);
	max-width: 550px
}

.cripto-accordion-body ul {
	list-style: disc;
	padding-left: 20px
}

.cripto-accordion-body ul li {
	font-family: var(--ff-secondary);
	font-size: 16px;
	font-weight: 400;
	color: var(--white);
	margin-bottom: 5px
}

.cripto-accordion-body ul li:last-child {
	margin-bottom: 0px
}

.bit-data-watermark.cripto-faq-water {
	right: unset;
	left: -210px
}

@media all and (max-width: 1750px) {
	.cripto-team-member .bit-data-watermark {
		right: unset;
		top: -10px;
		left: 50%;
		transform: translate(-50%, 0%) rotate(0deg)
	}
	.cripto-team-member .bit-data-watermark h2 {
		font-size: 90px
	}
	.cripto-team-member .bit-sub {
		font-size: 24px;
		padding: 15px 25px 15px 0px
	}
}

@media all and (max-width: 1399px) {
	.cripto-team-single:nth-child(4),
	.cripto-team-single:nth-child(5) {
		margin-top: -175px
	}
}

@media all and (max-width: 1199px) {
	.cripto-team-single:nth-child(4),
	.cripto-team-single:nth-child(5) {
		margin-top: -150px !important
	}
	.cripto-team-single .ct-single-overlay h4 {
		font-size: 22px;
		margin-bottom: 0px
	}
	.cripto-team-single .ct-single-overlay ul li a {
		height: 30px;
		width: 30px
	}
	.cripto-team-single .ct-single-overlay ul li a i {
		font-size: 12px
	}
	.bitcoin-banner-wrap.sticky {
		position: relative;
		top: unset;
		left: auto
	}
}

@media all and (max-width: 991px) {
	.cripto-team-single.one-third {
		width: calc(100% / 2 - 20px)
	}
	.cripto-team-single.one-third:nth-child(3) {
		margin: -145px 100px 0px
	}
	.cripto-team-single.one-third:nth-child(4),
	.cripto-team-single.one-third:nth-child(5) {
		margin-top: -175px
	}
	.cripto-team-member {
		padding: 80px 0px;
		overflow: hidden
	}
	.cripto-team-member .cripto-team-heading {
		max-width: 550px
	}
	.cripto-line-mark {
		top: unset;
		left: -235px;
		bottom: 0;
		transform: rotate(180deg)
	}
}

@media all and (max-width: 767px) {
	.cripto-team-single.one-third:nth-child(3) {
		margin: -125px 100px 0px
	}
	.cripto-team-single.one-third:nth-child(4),
	.cripto-team-single.one-third:nth-child(5) {
		margin-top: -115px !important
	}
	.cripto-team-single .ct-single-overlay h4 {
		font-size: 18px
	}
	.cripto-team-single .ct-single-overlay p {
		margin-bottom: 5px;
		font-size: 16px
	}
	.cripto-team-single .ct-single-overlay ul li {
		margin: 0px 5px
	}
	.cripto-team-heading .bit-title {
		font-size: 30px;
		margin-bottom: 10px
	}
}

@media all and (max-width: 575px) {
	.cripto-team-wrapper {
		flex-direction: column
	}
	.cripto-team-single.one-third {
		width: 100%;
		max-width: 350px
	}
	.cripto-team-single.one-third:nth-child(3) {
		margin: 10px
	}
	.cripto-team-single.one-third:nth-child(4),
	.cripto-team-single.one-third:nth-child(5) {
		margin-top: unset !important
	}
	.cripto-team-member {
		padding: 50px 0px
	}
	.cripto-team-member .bit-sub {
		font-size: 24px;
		padding: 5px 25px 5px 0px
	}
	.cripto-team-member .bit-data-watermark h2 {
		font-size: 70px
	}
	.cripto-team-single,
	.one-third {
		width: 100%;
		max-width: 350px
	}
}

.cripto-contacts-area {
	padding: 110px 0px;
	background: var(--bit-gradient);
	overflow: hidden
}

.cripto-contacts-area .bit-data-watermark {
	right: calc(0px - 300px)
}

.cripto-contacts-from,
.cripto-contacts,
.cripto-contacts-map {
	max-width: 960px;
	margin: 0 auto
}

.cripto-contacts-heading {
	max-width: 650px;
	margin: 0 auto 50px
}

.cripto-contacts-heading p {
	color: var(--white);
	line-height: 1.5;
	margin-bottom: 0px
}

.cripto-contacts {
	padding: 30px 50px;
	border-radius: 5px;
	background: var(--white-water-color);
	margin-bottom: 30px
}

.cripto-contacts ul {
	display: flex;
	align-items: center;
	justify-content: space-between
}

.cripto-contacts ul li {
	display: flex;
	align-items: center;
	margin-left: 25px;
	font-family: var(--ff-secondary);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.5;
	color: var(--white)
}

.cripto-contacts ul li a {
	color: var(--white)
}

.cripto-contacts ul li:first-child {
	margin-left: 0px
}

.cripto-contacts ul li i {
	font-size: 25px;
	display: block;
	margin-right: 20px;
	background: var(--success-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.cripto-contacts-from {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap
}

.cripto-contacts-from .btn {
	border-radius: 5px;
	margin-top: 20px
}

.cripto-contact-group,
.input-text-group {
	width: calc(100% / 2 - 10px)
}

.cripto-contact-group {
	margin-right: 20px
}

.cripto-contact-group .input-group input {
	width: 100%;
	background: var(--white-water-color);
	border: 1px solid var(--title-border);
	box-sizing: border-box;
	border-radius: 5px;
	padding: 15px 30px;
	color: var(--white)
}

.cripto-contact-group .input-group input::placeholder {
	color: var(--white)
}

.cripto-contact-group .input-group input:focus {
	border-color: var(--theme-sky)
}

.cripto-contact-group .input-group.mb-20 {
	margin-bottom: 20px
}

.input-text-group textarea {
	width: 100%;
	background: var(--white-water-color);
	border: 1px solid var(--title-border);
	border-radius: 5px;
	padding: 15px 30px;
	color: var(--white);
	height: 125px
}

.input-text-group textarea::placeholder {
	color: var(--white)
}

.input-text-group textarea:focus {
	border-color: var(--theme-sky)
}

.cripto-contacts-from {
	margin-bottom: 70px
}

.cripto-contacts-map {
	overflow: hidden
}

.cripto-contacts-map #gmap_canvas {
	border-radius: 10px
}

.cripto-contacts-area {
	position: relative;
	z-index: 1
}

.cripto-contacts-area .cripto-contacts-shape {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1
}

@media all and (max-width: 1750px) {
	.cripto-faq-area .bit-data-watermark,
	.cripto-contacts-area .bit-data-watermark {
		right: unset;
		top: -10px;
		left: 50%;
		transform: translate(-50%, 0%) rotate(0deg)
	}
	.cripto-faq-area .bit-data-watermark h2,
	.cripto-contacts-area .bit-data-watermark h2 {
		font-size: 90px
	}
	.cripto-faq-area .bit-sub,
	.cripto-contacts-area .bit-sub {
		font-size: 24px;
		padding: 15px 25px 15px 0px
	}
}

@media all and (max-width: 991px) {
	.cripto-faq-area,
	.cripto-contacts-area {
		padding: 80px 0px
	}
	.cripto-faq-area .bit-title,
	.cripto-contacts-area .bit-title {
		font-size: 30px;
		margin-bottom: 10px
	}
	.cripto-single-accordion.active .cripto-accordion-head {
		padding-top: 25px
	}
	.cripto-single-accordion.active .cripto-accordion-head::after {
		top: 25px
	}
	.cripto-accordion-head {
		padding: 20px 50px 20px 25px
	}
	.cripto-accordion-body {
		padding: 0px 25px 25px
	}
	.cripto-contacts {
		padding: 30px
	}
	.cripto-contacts ul {
		flex-wrap: wrap
	}
	.cripto-contacts ul li {
		padding-bottom: 15px
	}
	.cripto-contacts ul li:last-child {
		margin-left: 0px;
		padding-bottom: 0px
	}
	.cripto-contacts-from {
		margin-bottom: 30px !important
	}
	.cripto-contacts-from .btn {
		flex: unset;
		margin-top: 20px
	}
	.input-text-group {
		width: calc(100% / 2 - 20px);
		margin-right: 0px
	}
	.cripto-contact-group {
		width: calc(100% / 2 - 20px);
		margin-right: 20px
	}
}

@media all and (max-width: 767px) {
	.cripto-contacts ul {
		align-items: flex-start;
		flex-direction: column
	}
	.cripto-contacts ul li {
		margin-left: 0px
	}
	.input-text-group {
		width: 100%;
		margin-right: 0px
	}
	.cripto-contact-group {
		width: 100%;
		margin-right: 0px;
		margin-bottom: 20px
	}
}

@media all and (max-width: 575px) {
	.cripto-accordion-head h6,
	.cripto-contacts-area h6 {
		font-size: 16px
	}
	.cripto-contacts-area .bit-sub {
		padding: 5px 25px 5px 0px
	}
	.cripto-contacts-area .bit-data-watermark h2 {
		font-size: 70px
	}
	.cripto-faq-area,
	.cripto-contacts-area {
		padding: 50px 0px
	}
	.cripto-contacts-map #gmap_canvas {
		height: 350px
	}
	.cripto-contacts-shape {
		display: none
	}
}


/*# sourceMappingURL=style.css.map */